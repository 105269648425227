import React, {useEffect, useState} from 'react';
import {TagInputProps} from './TagInput.props';
import './TagInput.styles.css';
import {Box, Checkbox, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import {Clear} from "@mui/icons-material";
import validator from 'validator';

const Tags:any = ({ data, handleDelete }:any) => {
    return (
        <Box
            sx={{
                background: "#E7EBF0",
                height: "100%",
                display: "flex",
                padding: "6px 12px 6px 16px",
                margin: "0 0.5rem 0 0",
                justifyContent: "center",
                alignContent: "center",
                borderRadius: "24px",
                color: "#011111",
            }}
        >
            <Stack direction='row' gap={1}>
                <Typography>{data}</Typography>
                <Clear
                    className={'clear-tags'}
                    sx={{ cursor: "pointer", width: "17px"}}
                    onClick={/* istanbul ignore next */() => {
                        /* istanbul ignore next */
                        handleDelete(data);
                    }}
                />
            </Stack>
        </Box>
    );
};

export const TagInput: React.FC<TagInputProps> = ({
    handleChange,
    tagRef,
    type,
    paramSendMail,
    setParamSendMail,
    checkboxInput,
    validateToField,
    setValidateToField,
}) => {
    const [ tags, setTags] = useState<any>(()=>{
        /* istanbul ignore next */
        if(paramSendMail && paramSendMail['to'] && paramSendMail['to'].length > 0 && type === 'to') {
            /* istanbul ignore next */
            return paramSendMail['to'];
        } else if (paramSendMail && paramSendMail['cc'] && paramSendMail['cc'].length > 0 && type === 'cc') {
            return paramSendMail['cc'];
        }
        else {
            return [];
        }
    });
    const [checked, setChecked] = useState(() =>{
        if(paramSendMail && paramSendMail['is_include_reporter']) {
            return true;
        }
        return false;
    });
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [focus, setFocus] = useState(false);
    const handleDelete = (value:any) => {
        setFocus(true)
        const newtags = tags.filter((val:any) => val !== value);
        setTags(newtags);
        if(value === paramSendMail.reportedBy){
            setChecked(!checked)
        }
        let item:any;
        if (type === 'to' || type === 'cc'){
            item = [...newtags]
        }
        setParamSendMail({
            ...paramSendMail,
            [type]: item,
        })
    };

    const addTag = (lowercaseEmail:any) => {
        tags.push(lowercaseEmail);

        setTags([...tags]);

        setInvalidEmail(false);
        let item:any;
        if (type === 'to' || type === 'cc'){
            item = [...tags]
        }
        setParamSendMail({
            ...paramSendMail,
            [type]: item,
        })
    }

    const handleOnSubmitTag = (e:any) => {
        e.preventDefault();
        const lowercaseEmail = tagRef.current.value.toLowerCase().trim()
        /* istanbul ignore next */
        const newTags = tags.filter((val:any) => val === lowercaseEmail);
        if (validator.isEmail(lowercaseEmail)){
            /* istanbul ignore next */
            if(newTags.length === 0){
                addTag(lowercaseEmail);
                tagRef.current.value = "";
            } /* istanbul ignore next */ else {
                /* istanbul ignore next */
                setInvalidEmail(false);
                /* istanbul ignore next */
                tagRef.current.value = "";
            }
        } else {
            tagRef.current.value = tagRef.current.value;
            if(tagRef.current.value) {
                setInvalidEmail(true);
            } else {
                setInvalidEmail(false);
            }
            if(tags.length < 1 && setValidateToField){
                setValidateToField(true);
            }
        }
    };

    // const handleOnChange = (e:any, type:any) => {
    //     let item:any = e.target.value;
    //     if (type === 'to' || type === 'cc'){
    //         item = [...tags, e.target.value]
    //     }
    //     setParamSendMail(() => {
    //         return  ({
    //             ...paramSendMail,
    //             [type]: item,
    //         })
    //     })
    // }

    const handleCheckNull = () => {
        if (type === 'to'){
            /* istanbul ignore next */
            if(focus && tags.length < 1){
                if (setValidateToField){
                    setValidateToField(true);
                }
            } else {
                if (setValidateToField){
                    setValidateToField(false);
                }
            }
        }
    }

    useEffect(()=>{
        handleCheckNull();
        const newTags = tags.filter((val:any) => val === paramSendMail['reportedBy']);

       if(newTags.length === 0){
           setChecked(false)
       }else{
           setChecked(true);
       }

    }, [tags])

    const handleAddTagReporter = (e:any) => {
        const status = e.target.checked
        if(status){
            setTags([...tags, paramSendMail.reportedBy]);
            setParamSendMail({
                ...paramSendMail,
                [type]: [...tags, paramSendMail.reportedBy],
            })
            setChecked(true)
        } else {
            handleDelete(paramSendMail.reportedBy)
            setChecked(!checked)
        }
    }
    
    return (
        <>
            <Box sx={{flexGrow: 1}} id={'outline-tag-textfield'}>
                <form data-testid={'tag-form'} id='tag-form' onSubmit={handleOnSubmitTag}>
                    <TextField
                        inputRef={tagRef}
                        fullWidth
                        variant='outlined'
                         size='small'
                        placeholder="Input email and press Enter"
                        onBlur={(e) => {handleOnSubmitTag(e);setFocus(false)}}
                        InputProps={{
                            startAdornment: (
                                <Box sx={{margin: "0 0.2rem 0 0", display: "flex", flexWrap: "wrap"}} className={'tag-container'}>
                                    {tags.map((data: any, index: any) => {
                                        return (
                                            <Tags data={data} handleDelete={handleDelete} key={index}/>
                                        );
                                    })}
                                </Box>
                            ),
                        }}
                        error={invalidEmail || validateToField}
                        helperText={validateToField?'This field is required.': "Invalid email address"}
                    />
                </form>
            </Box>
            {checkboxInput ? <FormControlLabel sx={{marginTop: '8px'}} control={<Checkbox checked={checked} onChange={(e:any) => handleAddTagReporter(e)}/>} label="Include original reporter"/> : null}
        </>
    );
};
