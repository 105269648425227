import React from 'react'
import {StackedColumnChartProps} from './StackedColumnChart.props'
import './StackedColumnChart.styles.css';
import ReactApexChart from "react-apexcharts";
import {NoDataCard} from "../NoDataCard";
import Box from "@mui/material/Box";

export const StackedColumnChart: React.FC<StackedColumnChartProps> = ({
  data
}) => {
  const options = {
    colors : ['#0E4F83', '#61BBD8', '#BDE541'],
    fill: {
      colors: ['#0E4F83', '#61BBD8', '#BDE541'],
      opacity: 1,
    },
    chart: {
      toolbar: {
        show: false,
      },
      stacked: true,
      stackType: '100%'
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
      }
    },
    tooltip: {
      enabled: true,
      y: {
          formatter: /* istanbul ignore next */function (val:any) {
            return val
          }
        }
    },
    dataLabels: {
      enabled: false
    },
      legend: {
        show: true,
        fontSize: '11px',
        fontFamily: 'Montserrat, Helvetica, Arial',
        fontWeight: 400,
        color: '#808888',
        markers: {
          width: 14,
          height: 14,
          radius: 14,
        } ,
        onItemClick: {
          toggleDataSeries: false
        },
      },
      grid: {
        show: false
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        categories: data.months,
        labels: {
          style: {
            colors: ['#808888','#808888','#808888','#808888','#808888','#808888'],
            fontSize: '10px',
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontWeight: 400,
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          align: 'right',
          style: {
            colors: ['#808888'],
            fontSize: '10px',
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontWeight: 400,
          },
          offsetX: -10,
          formatter: /* istanbul ignore next */(value: any) => {  return (parseInt(value)/10)%2==0? value + '%': '' },
        },
      },
    };

  const series = [{
      name: 'Threat',
      data: data.Threat
    }, {
      name: 'Unknown',
      data: data.Unknown
    }, {
      name: 'Clean',
      data: data.Clean
    }];
  let hasData = false
  for (const item of series) {
    for (const sub_item of item.data) {
      if(hasData) { break; }
      if(sub_item > 0){
        hasData = true;
        break;
      }
    }
  }

  return (
    <Box className={'stacked-column-chart'}>
      {/*//@ts-ignores*/
      hasData ? <ReactApexChart
        type="bar"
        // @ts-ignores
        options={options}
        series={series}
        height={'240px'}
        width={'100%'}
      /> :
      <NoDataCard type={'stackedColumn'} height={'327px'} padding={'70px 0 0 0'} ></NoDataCard>}
    </Box>
  );
}
