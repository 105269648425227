import React, {useEffect, useRef, useState} from 'react'
import {BlocklistConnectionCardProps} from './BlocklistConnectionCard.props'
import './BlocklistConnectionCard.styles.css';
import {Box, Button} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import {Modal} from "../../atoms/Modal";
import {getBlockListsSetting} from "../../../api/emailTriage";
import {Toast} from "../../atoms/Toast";
import {Ms365BlockListAuth} from "../../services/Ms365BlockListAuth";
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment-timezone';

export const BlocklistConnectionCard: React.FC<BlocklistConnectionCardProps> = ({
    setLoadingPage,
    isEnabledBlocklist = false
}) => {
    const [toastId, setToastID] = useState(1);
    const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0});
    const [blocklistTenantId, setBlocklistTenantId] = useState<any>(null)
    const [connectedAccountEmail, setConnectedAccountEmail] = useState<any>(null)
    const [connectedAccountName, setConnectedAccountName] = useState<any>(null)
    const [connectedAccountDate, setConnectedAccountDate] = useState<any>(null)
    const [connectedAccountString, setConnectedAccountString] = useState<any>(null)
    const [settingLoaded, setSettingLoaded] = useState(false)
    const [isSkeletonLoading, setIsSkeletonLoading] = useState(true)
    const [isDisableDisconnectButton, setIsDisableConnectButton] = useState(false)
    const [isOpenLogoutMS365Modal, setIsOpenLogoutMS365Modal] = useState(false)
    const [isOpenDisconnectTenantModal, setIsOpenDisconnectTenantModal] = useState(false)
    const [contentModal, setContentModal] = useState<any>({});
    const blockListAuthRef = useRef<any>();

    const userData = localStorage.getItem('userData');
    const parseUserData = userData ? JSON.parse(userData) : {}
    const tz_name = Object.keys(parseUserData).length > 0 ? parseUserData.tz_name : 'Etc/UTC'

    const blockEventStyle: React.CSSProperties = {
        opacity: 0.4,
        zIndex: 100,
        pointerEvents: 'none'
    };

    const generateConfirmContentModal = (element: any, title: string, textAgree: string, variant: string, params?: any) => {
        const content:any = {
            title: title,
            content: element,
            params: params,
            textAgree: textAgree,
            variant: variant
        }
        setContentModal(content)
    }

    const logoutMS365Popup = () => {
        const html = <div>
            <span>Once logged out, all credentials from you Microsoft 365 account <strong>{connectedAccountEmail}</strong> will be cleared from Phish Focus.</span>
            <br/>
            <br/>
            <span>Are you sure you would like to log out of your Microsoft 365 account on Phish Focus?</span>
        </div>

        const title = 'Log out of Microsoft 365 account on Phish Focus'
        const textAgree = 'Log Out'
        const variant = 'warning'
        generateConfirmContentModal(html, title, textAgree, variant)
        setIsOpenLogoutMS365Modal(true)
    }

    const disconnectTenantPopup = () => {
        const html = <><span>When you disconnect, your Microsoft 365 account will also be logged out. Are you sure you want to disconnect your organization's tenant from the blocklist functionality?</span>
            <div style={{display: 'flex', marginTop: "16px"}} >
                <div style={{width: '4px', backgroundColor: "#CCC"}}></div>
                <div style={{marginLeft: '24px', fontWeight: 400}}>
                    <span style={{marginBottom: '8px', display: "block"}}>All created data will be temporarily removed.</span>
                    <span>If you reconnect to this tenant, you can choose to restore all data related to this tenant.</span>
                </div>
            </div>
        </>
        const title = 'Disconnect your organisation tenant'
        const textAgree = 'Disconnect'
        const variant = 'delete'
        generateConfirmContentModal(html, title, textAgree, variant)
        setIsOpenDisconnectTenantModal(true)
    }

    //** BLOCK LIST Function **/
    const onBlockListAccessGained = (accountInfo:any) => {
      setBlocklistTenantId(accountInfo.account.tenantId)
      setConnectedAccountEmail(accountInfo.account.username)
      setConnectedAccountName(accountInfo.account.name)
      setConnectedAccountDate(moment.utc().tz(tz_name).format('DD/MM/YYYY'))
    }

    const handleOpenNewBlockList = () => {
        // Check if user logged in
        if(blockListAuthRef && blockListAuthRef.current){
            blockListAuthRef.current.requestAccessToken().then((accountInfo:string) => {
                blockListAuthRef.current.accessGained(accountInfo)
            }).catch((error:any) => {
                blockListAuthRef.current.openConfirmModal()
            });
        }
    };

    const handleConnectedString = () => {
        if(connectedAccountName && connectedAccountName.length>0) {
            setConnectedAccountString(connectedAccountName + ' (' + connectedAccountEmail + ')')
        } else {
            setConnectedAccountString(connectedAccountEmail)
        }
    }


    useEffect(() => {
      setIsDisableConnectButton(blocklistTenantId && connectedAccountEmail === null)
    }, [blocklistTenantId,connectedAccountEmail])

    useEffect(() => {
        handleConnectedString()
    }, [connectedAccountEmail, connectedAccountName])

    useEffect(() => {
        setIsSkeletonLoading(true)
        getBlockListsSetting({
            service: 'BLOCK_LIST',
            user: 'pre',
        }).then((response)=>{
            setBlocklistTenantId(response.data.tenant)
            setConnectedAccountEmail(response.data.account_email)
            setConnectedAccountName(response.data.account_name)
            setSettingLoaded(true)
            const login_date = moment.utc(moment.unix(response.data.login_at), 'DD/MM/YYYY').tz(tz_name).format('DD/MM/YYYY')
            setConnectedAccountDate(login_date)
            setIsSkeletonLoading(false)
        }).catch((error) => {
            setIsSkeletonLoading(false)
        })
    }, [])


  const noteComponent = (tenant_id: any = null, email: any = null, login_date: any = null) => {
      let content = 'Please connect to your organisation’s Microsoft 365 tenant to grant access for Phish Focus to the block list. Once connected successfully, all block list entries created from Phish Focus will be synced to your organisation’s Microsoft 365 tenant.'
        if (tenant_id && email === null) {
            content = 'Please log in to your Microsoft 365 account to access to all available actions of Block List'
        } else if (tenant_id && email && login_date) {
            content = `Login on ${login_date}. You will be required to log in again to re-authorise after the 24 hours period.`
        }

        return <Box className={'connect-ms365-description'}>
            <Box sx={{width: '4px', backgroundColor: '#467599', borderRadius: '4px 0 0 4px'}} />
            <Box sx={{margin: '8px'}}>
                <ErrorIcon /><Box className={'connect-ms365-description-title'}>{content}</Box>
            </Box>
        </Box>
  }

  const handleDisconnectTenant = () => {
      if(blockListAuthRef && blockListAuthRef.current){
          setToastID(toastId + 1)
          setLoadingPage(true)
          blockListAuthRef.current.logoutTenant({}).then((response:any) => {
              setConnectedAccountEmail(null)
              setBlocklistTenantId(null)
              setConnectedAccountName(null)
              setConnectedAccountDate(null)
              setToastValue({type: 'success', message: `Disconnected tenant successfully!`, isOpen: true, toastId: toastId});
              setLoadingPage(false);
          }).catch((error:any) => {
              setToastValue({type: 'error', message: 'Disconnected tenant unsuccessfully!', isOpen: true, toastId: toastId});
              setLoadingPage(false);
          });
      }
        setIsOpenDisconnectTenantModal(false)
  }

    const handleLogoutMS365Account = () => {
        if(blockListAuthRef && blockListAuthRef.current){
            setToastID(toastId + 1)
            setLoadingPage(true)
            blockListAuthRef.current.logoutAccount({}).then((response:any) => {
                setConnectedAccountEmail(null)
                setConnectedAccountName(null)
                setConnectedAccountDate(null)
                setToastValue({type: 'success', message: `Log out of your MS 365 account successfully!`, isOpen: true, toastId: toastId});
                setLoadingPage(false);
            }).catch((error:any) => {
                setToastValue({type: 'error', message: 'Log out of your MS 365 account unsuccessfully!', isOpen: true, toastId: toastId});
                setLoadingPage(false);
            });
        }
        setIsOpenLogoutMS365Modal(false)
    }

  return (
      <Box sx={isEnabledBlocklist ? null : blockEventStyle}>
          <Box className={"connect-mail-server-card"} sx={{borderTop: "1px solid #E0E0E0", marginTop: 2, paddingTop: 2}}>
            <span className='connect-mail-server-title'>Microsoft 365 Connection</span>
            {
                blocklistTenantId ?
                    <Box className={"connect-mail-server-content-card"}>
                      <Box className={'connected-tenant-card'}>
                        <Box className={'connected-tenant-info'} >
                          <span >Connected organisation tenant ID:</span>
                          <span style={{fontWeight: 600, display: 'block', marginTop: '4px'}}>{blocklistTenantId}</span>
                        </Box>
                        <Button disabled={isDisableDisconnectButton} data-testid={"disconnectedTenantBtn"} className={"disconnected-tenant-btn"} onClick={() => disconnectTenantPopup()} variant="text" size={"large"}>
                          <span>Disconnect Tenant</span>
                        </Button>
                      </Box>
                        {
                            connectedAccountEmail ?
                                <Box className={'connected-ms-account-card'}>
                                    <Box className={'connected-ms-account-info'}>
                                        {noteComponent(blocklistTenantId, connectedAccountEmail, connectedAccountDate)}
                                      <span>Login as:</span>
                                      <span style={{fontWeight: 600, display: 'block', marginTop: '4px'}}>{connectedAccountString}</span>
                                    </Box>
                                    <Button data-testid={"logoutMSAccountBtn"} className={"logout-ms365-account-btn"} onClick={() => logoutMS365Popup()} variant="text" size={"large"}>
                                      <span>Log Out</span>
                                    </Button>
                                </Box> :
                                <Box className={'connected-ms-account-card'}>
                                    {noteComponent(blocklistTenantId, connectedAccountEmail)}
                                    <span>Not logged in to Microsoft 365 account</span>
                                    <Button data-testid={"loginMSAccountBtn"} className={"login-ms365-account-btn"} onClick={() => handleOpenNewBlockList()} variant="text" size={"large"}>
                                        <span>Log In</span>
                                    </Button>
                                </Box>
                        }
                    </Box> :
                        (
                            isSkeletonLoading ? <Skeleton variant="rectangular" sx={{maxWidth: '700px', height: '100px', marginTop: '10px', borderRadius: '4px'}} /> :
                                <Box className={"no-connect-mail-server-content-card"}>
                                  {isEnabledBlocklist && noteComponent(blocklistTenantId, connectedAccountEmail)}
                                  <Button data-testid={"connectMS365Btn"} className={"connect-ms365-btn"} onClick={() => handleOpenNewBlockList()} variant="text" size={"large"}>
                                      <span>Connect to Microsoft 365 tenant</span>
                                  </Button>
                                </Box>
                        )
            }

              <Modal idModal='modal-logout-ms-365'
                     openDialog={isOpenLogoutMS365Modal}
                     variant={contentModal.variant}
                     title={contentModal.title}
                     content={contentModal.content}
                     actionAgree={() => {
                        handleLogoutMS365Account()
                     }}
                     actionCancel={() => {setIsOpenLogoutMS365Modal(false)}}
                     textAgree={contentModal.textAgree}
                     onClose={() => {setIsOpenLogoutMS365Modal(false)}}
              />
              <Modal idModal='modal-disconnect-tenant'
                     openDialog={isOpenDisconnectTenantModal}
                     variant={contentModal.variant}
                     title={contentModal.title}
                     content={contentModal.content}
                     actionAgree={() => {
                         handleDisconnectTenant()
                     }}
                     actionCancel={() => {setIsOpenDisconnectTenantModal(false)}}
                     textAgree={contentModal.textAgree}
                     onClose={() => {setIsOpenDisconnectTenantModal(false)}}
              />
            <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message} toastId={toastValue.toastId} autoHideDuration={2000}/>
            {settingLoaded && <Ms365BlockListAuth
                ref={blockListAuthRef}
                tenant={blocklistTenantId}
                accessGained={onBlockListAccessGained}
                onBeforeGetUser={setLoadingPage}
                onAfterGetUser={setLoadingPage}
                userData={parseUserData}
            />
            }
          </Box>
      </Box>
  );
}
