import React, {useState, useEffect} from 'react';
import { makeCognitoRequest } from '../../api/AWS';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getIdToken} from '../../utils/common';
import {CallbackProps} from './Callback.props';
import './Callback.style.css';
import { Image } from '../../components/atoms/Image';
import { Images } from '../../configs/images';
import jwt_decode from "jwt-decode";

const timeZone = {"American Samoa": "US/Samoa",
  "Midway Island": "Pacific/Midway",
  "Chatham Is.": "Pacific/Chatham",
  "Samoa": "Etc/GMT-11",
  "Pacific/Marquesas": "Pacific/Marquesas",
  "Auckland": "Pacific/Auckland",
  "Fiji": "Pacific/Fiji",
  "Guam": "Pacific/Guam",
  "Port Moresby": "Pacific/Port_Moresby",
  "Hawaii": "US/Hawaii",
  "Alaska": "US/Alaska",
  "Pacific Time (US & Canada)": "US/Pacific",
  "Arizona": "US/Arizona",
  "Central America": "US/Central",
  "Mountain Time (US & Canada)": "US/Mountain",
  "Central Time (US & Canada)": "US/Central",
  "Eastern Time (US & Canada)": "US/Eastern",
  "Indiana (East)": "US/East-Indiana",
  "Tijuana": "America/Tijuana",
  "Chihuahua": "America/Chihuahua",
  "Mazatlan": "America/Mazatlan",
  "Mexico City": "America/Mexico_City",
  "Monterrey": "America/Monterrey",
  "Bogota": "America/Bogota",
  "Lima": "America/Lima",
  "Caracas": "America/Caracas",
  "La Paz": "America/La_Paz",
  "St. Petersburg": "America/Indiana/Petersburg",
  "Santiago": "America/Santiago",
  "Buenos Aires": "America/Buenos_Aires",
  "Montevideo": "America/Montevideo",
  "Saskatchewan": "Canada/Saskatchewan",
  "Atlantic Time (Canada)": "Canada/Atlantic",
  "Newfoundland": "Canada/Newfoundland",
  "Greenland": "Greenland",
  "Azores": "Atlantic/Azores",
  "Cape Verde Is.": "Atlantic/Cape_Verde",
  "Lisbon": "Europe/Lisbon",
  "London": "Europe/London",
  "Belgrade": "Europe/Belgrade",
  "Berlin": 'Europe/Berlin',
  "Bratislava": "Europe/Bratislava",
  "Amsterdam": "Europe/Amsterdam",
  "Brussels": "Europe/Brussels",
  "Budapest": "Europe/Budapest",
  "Copenhagen": "Europe/Copenhagen",
  "Dublin": "Europe/Dublin",
  "Ljubljana": "Europe/Ljubljana",
  "Madrid": "Europe/Madrid",
  "Paris": "Europe/Paris",
  "Prague": "Europe/Prague",
  "Rome": "Europe/Rome",
  "Sarajevo": "Europe/Sarajevo",
  "Skopje": "Europe/Skopje",
  "Stockholm": "Europe/Stockholm",
  "Vienna": "Europe/Vienna",
  "Warsaw": "Europe/Warsaw",
  "Zagreb": "Europe/Zagreb",
  "Athens": "Europe/Athens",
  "Bucharest": "Europe/Bucharest",
  "Helsinki": "Europe/Helsinki",
  "Kaliningrad": "Europe/Kaliningrad",
  "Kyiv": "Europe/Kiev",
  "Riga": "Europe/Riga",
  "Sofia": "Europe/Sofia",
  "Tallinn": "Europe/Tallinn",
  "Vilnius": "Europe/Vilnius",
  "Moscow": "Europe/Moscow",
  "Minsk": "Europe/Minsk",
  "Volgograd": "Europe/Volgograd",
  "Samara": "Europe/Samara",
  "Baghdad": "Asia/Baghdad",
  "Kuwait": "Asia/Kuwait",
  "Riyadh": "Asia/Riyadh",
  "Tbilisi": "Asia/Tbilisi",
  "Yerevan": "Asia/Yerevan",
  "Kabul": "Asia/Kabul",
  "Baku": "Asia/Baku",
  "Ekaterinburg": "Asia/Yekaterinburg",
  "Karachi": "Asia/Karachi",
  "Tashkent": "Asia/Tashkent",
  "Kolkata": "Asia/Kolkata",
  "Kathmandu": "Asia/Kathmandu",
  "Almaty": "Asia/Almaty",
  "Dhaka": "Asia/Dhaka",
  "Urumqi": "Asia/Urumqi",
  "Rangoon": "Asia/Rangoon",
  "Jakarta": "Asia/Jakarta",
  "Krasnoyarsk": "Asia/Krasnoyarsk",
  "Bangkok": "Asia/Bangkok",
  "Novosibirsk": "Asia/Novosibirsk",
  "Chongqing": "Asia/Chongqing",
  "Hong Kong": "Asia/Hong_Kong",
  "Irkutsk": "Asia/Irkutsk",
  "Kuala Lumpur": "Asia/Kuala_Lumpur",
  "Muscat": "Asia/Muscat",
  "Singapore": "Asia/Singapore",
  "Jerusalem": "Asia/Jerusalem",
  "Tehran": "Asia/Tehran",
  "Seoul": "Asia/Seoul",
  "Taipei": "Asia/Taipei",
  "Tokyo": "Asia/Tokyo",
  "Yakutsk": "Asia/Yakutsk",
  "Kamchatka": "Asia/Kamchatka",
  "Ulaan Bataar": "Asia/Ulaanbaatar",
  "Monrovia": "Africa/Monrovia",
  "Vladivostok": "Asia/Vladivostok",
  "Magadan": "Asia/Magadan",
  "Srednekolymsk": "Asia/Srednekolymsk",
  "Casablanca": "Africa/Casablanca",
  "Nairobi": "Africa/Nairobi",
  "Cairo": "Africa/Cairo",
  "Harare": "Africa/Harare",
  "UTC": "Etc/UTC",
  "Istanbul": "Etc/GMT+3",
  "Perth": "Australia/Perth",
  "Adelaide": "Australia/Adelaide",
  "Darwin": "Australia/Darwin",
  "Brisbane": "Australia/Brisbane",
  "Canberra": "Australia/Canberra",
  "Hobart": "Australia/Hobart",
  "Melbourne": "Australia/Melbourne",
  "Sydney": "Australia/Sydney",
  "Australia/Eucla": "Australia/Eucla",
  "Australia/Lord_Howe": "Australia/Lord_Howe",

  "International Date Line West": "Etc/GMT+12",
  "Guadalajara": "Etc/GMT+6",
  "Quito": "Etc/GMT+5",
  "Georgetown": "Etc/GMT+4",
  "Brasilia": "Etc/GMT+3",
  "Mid-Atlantic": "Etc/GMT+4",
  "Edinburgh": "Etc/GMT-1",
  "Bern": "Etc/GMT-2",
  "West Central Africa": "Etc/GMT-1",
  "Pretoria": "Etc/GMT-2",
  "Abu Dhabi": "Etc/GMT-4",
  "Islamabad": "Etc/GMT-5",
  "Chennai": "Etc/GMT-5:30",
  "Mumbai": "Etc/GMT-5:30",
  "New Delhi": "Etc/GMT-5:30",
  "Sri Jayawardenepura": "Etc/GMT-5:30",
  "Astana": "Etc/GMT-6",
  "Hanoi": "Etc/GMT-7",
  "Beijing": "Etc/GMT-8",
  "Osaka": "Etc/GMT-9",
  "Sapporo": "Etc/GMT-9",
  "New Caledonia": "Etc/GMT-11",
  "Solomon Is.": "Etc/GMT-11",
  "Marshall Is.": "Etc/GMT-12",
  "Wellington": "Etc/GMT-12",
  "Nuku'alofa": "Etc/GMT-13",
  "Tokelau Is.": "Etc/GMT-13",
}

export const Callback: React.FC<CallbackProps> = (props) => {
  //const navigate = useNavigate()
  const [sToken, setSToken] = useState<string>('');
  const [accessKeyId, setAccessKeyId] = useState<string>('');
  const [secretAccessKey, setSecretAccessKey] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const idToken = getIdToken();
    const accessToken:any = getIdToken('access_token');
    const currentZone = searchParams.get("q");

    // Temporarily disable
    const temp_userdata = localStorage.getItem("userData") || ''
    localStorage.clear()
    localStorage.setItem("userData", temp_userdata);

    makeCognitoRequest(idToken ||  /* istanbul ignore next */'').then((result:any) => {
      console.log(result)
      if(result && !result.expired) {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('idToken', idToken ||  /* istanbul ignore next */'');
        localStorage.setItem('accessToken', accessToken ||  /* istanbul ignore next */'');
        if (currentZone && currentZone.length > 0) {
          localStorage.setItem('currentZone', currentZone)
          sessionStorage.setItem('currentZone', currentZone)
        }

        setSToken(result.sessionToken);
        setAccessKeyId(result.accessKeyId);
        setSecretAccessKey(result.secretAccessKey);
        if(idToken) {
          const decoded:any = jwt_decode(idToken);
          if(decoded) {
            const userData = {
              userId: decoded.user_id,
              fullName: decoded.full_name,
              group: decoded.group,
              email: decoded.email,
              role: decoded.role,
              zoneId: decoded.zone_id,
              tz_offset: decoded.tz,
              tz_name: timeZone[decoded.tz_name]
            }
            localStorage.setItem('userData', JSON.stringify(userData));
          }
        }

        window.location.href = '/';
      } else {
        localStorage.setItem('isLoggedIn', '');
        let loginLink =   "https://"+process.env.REACT_APP_AWS_CONFIG_LOGIN_URL;
        const isLogout = sessionStorage.getItem('isLogout');
        if (isLogout==='company_admin') {
          loginLink += "/company_admin";
        } else {
          loginLink += "/admin";
        }
        window.location.href = loginLink;
      }
    });
  }, []);

  return (
    <div className={'full-page'}>
      <div className="spinner-wrap">
        <Image width="75px" srcImg={Images.Spinner} />
      </div>
    </div>
  );
};
