
import {useLocation, Outlet} from "react-router-dom";
// @ts-ignore
const PrivateRoute = () => {
    const location = useLocation();
    const userData = localStorage.getItem('userData');
    
    if (!userData) {
        window.location.href = location.pathname
    }
    return <Outlet/>;
}

export default PrivateRoute;