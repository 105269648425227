import React, {AriaRole, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {EditorProps} from './Editor.props';
import './Editor.styles.css';
import JoditEditor from "jodit-react";
import {isMobileOnly} from "react-device-detect";
import Button from "@mui/material/Button";
import {FormHelperText, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import ConfirmMessage from "../../organisms/ConfirmMessage/ConfirmMessage";
import {AlertMessage} from "../../atoms/AlertMessage";

export const Editor: React.FC<EditorProps> = ({
    setParamSendMail,
    paramSendMail,
    defaultContent,
    editorError,
    validateEmailContent,
    setValidateEmailContent,
    isMessageDownload = false
    }) => {
    const [joditInstance, setJoditInstance] = useState('')
    const [downloadLinkTooltipContent, setDownloadLinkTooltipContent] = useState('')
    const editorConfig: any = useMemo(
        () => ({
            readonly: false,
            events: {
                afterInit: (instance: any) => {
                    setJoditInstance(instance)
                }
            },
            tabIndex: 1,
            placeholder: 'Typing something here...',
            toolbar: !isMobileOnly,
            spellcheck: true,
            language: "en",
            toolbarButtonSize: "medium",
            toolbarAdaptive: false,
            showWordsCounter: false,
            showCharsCounter: false,
            askBeforePasteHTML: true,
            askBeforePasteFromWord: false,
            showXPathInStatusbar: false,
            uploader: {
                insertImageAsBase64URI: true,
                url: ''
            },
            filebrowser: {
                ajax: {
                    url: ''
                }
            },
            buttons: [
                'bold',
                'strikethrough',
                'underline',
                'italic', '|',
                'ul',
                'ol', '|',
                'outdent', 'indent', '|',
                'font',
                'fontsize',
                'brush',
                'paragraph', '|',
                'image',
                'table',
                'link', '|',
                'align', 'undo', 'redo', '|',
                'hr',
                'eraser',
                'copyformat', '|',
                'fullsize',
            ],
            height: 322,
            beautyHTML: true,
            cleanHTML: {
                denyTags: {
                    script: true,
                    style: true
                }
            },
        }),
        []
    );

    const [editorText, setEditorText] = useState<string>(() => {
        if (paramSendMail && paramSendMail[ 'email_content' ]) {
            return paramSendMail[ 'email_content' ];
        }
        return '';
    });
    const [isDisable, setIsDisable] = useState<boolean>(false);
    const [error, setError] = useState(false);

    const validateField = (value: string, type?: string) => {
      /* istanbul ignore next */
      if (!value || !value?.replace(/<\/?[^>]+(>|$)/g, "")) {
            setError(true);
        } else {
            setError(false);
        }
    }
    const [isConfirm, setIsConfirm] = useState(false);
    const downloadText = "{{download reported email}}";
    useEffect(() => {
        if (paramSendMail && defaultContent && paramSendMail?.email_content === defaultContent) {
            setIsDisable(true);
        }
        validateField(editorText);
    }, []);

    // @ts-ignore
    useEffect(() => {
        setParamSendMail({
            ...paramSendMail,
            email_content: editorText,
        });
        validateField(editorText);
    }, [editorText]);

    const handleChange = async (e: any) => {
        await setEditorText(e);
        validateField(e);
        if (setValidateEmailContent) {
            setValidateEmailContent(true);
        }
        if (defaultContent && defaultContent === e) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
        setIsConfirm(false);
    }

    const handleConfirm = () => {
        setIsConfirm(true);
    }

    // add text download on top of content
    // const insertAtIndex = (str: string, substring: string) => {
    //     let insertValue;
    //     const index = str.search(`<p>`);
    //     if (index !== -1) {
    //         insertValue = str.slice(0, index + 3) + substring + str.slice(index + 3, str.length);
    //     } else {
    //         insertValue = str + substring;
    //     }
    //     return insertValue;
    // }
    //
    // const addTextToContent = (value: any) => {
    //     const editorValue = insertAtIndex(editorText, value)
    //     handleChange(editorValue);
    // }

    // add text download on cursor in content
    const addTextToContent = (text: any) => {
        // @ts-ignore
        joditInstance.selection.insertHTML(text);
        // @ts-ignore
        const text1 = joditInstance?.currentPlace?.element?.value
        setEditorText(text1)
        validateField(text1);
    };

    const handleDownloadLinkTooltip =(contentEditable: any)=>{
        if(contentEditable?.includes(downloadText)){
            setDownloadLinkTooltipContent("Already included in 'Email Content'. But you can add more.")
        }else {
            setDownloadLinkTooltipContent("Click to add")
        }
    }

    useEffect(() => {
        handleDownloadLinkTooltip(editorText)
    }, []);
    return (
        <>
            {isMessageDownload && <AlertMessage severity='info' content={<span>Click this token <Tooltip placement={"top"} title={downloadLinkTooltipContent}>
                    <strong style={{cursor: 'pointer', fontSize: '13px'}} onClick={() => addTextToContent(downloadText)}>{downloadText}</strong>
                </Tooltip> to add to the email content. When the email is sent, it will appear as a link that users can click on it to download the reported email.</span>}/>}
            <div className={(editorError && validateEmailContent && error) ? 'jodit-error-message' : ''}
                 style={{marginBottom: 0}}>
                <JoditEditor
                    value={editorText}
                    config={editorConfig}
                    onBlur={(newContent) => handleChange(newContent)}
                    onChange={() => {
                        // @ts-ignore
                        handleDownloadLinkTooltip(joditInstance?.element?.value)
                    }}
                />
            </div>
            <Grid container item xs={12}>
                <Grid xs={6}>
                    <FormHelperText id={'fromNameError'}
                                    error
                                    style={{margin: '4px 0 0 16px', fontWeight: 500, fontSize: '12px'}}
                    >{validateEmailContent && error && editorError}
                    </FormHelperText>
                </Grid>
                <Grid xs={6}>
                    {defaultContent && <Button
                        onClick={() => handleConfirm()}
                        variant="text"
                        disabled={isDisable}
                        style={{maxWidth: '195px', marginTop: '10px', display: 'flex', marginLeft: 'auto'}}>
                        Restore default content
                    </Button>}
                </Grid>
            </Grid>
            <ConfirmMessage showMessage={isConfirm}
                            onConfirm={() => handleChange(defaultContent)}
                            onCancel={() => setIsConfirm(false)}/>
        </>
    );
};
