import React, {useEffect, useRef, useState} from 'react';
import {DropDownListProps} from './DropDownList.props';
import './DropDownList.styles.css';
import {isMobileOnly} from "react-device-detect";
import {Image} from "../Image";
import {Images} from "../../../configs/images";

export const DropDownList: React.FC<DropDownListProps> = ({
  width,
  height,
  disabled,
  labelTitle,
  selectedTitle,
  children,
  labelChild,
  data,
  margin,
  padding,
  variant,
  variantDropdown,
  onSelectItem,
  onSelectActions,
  /* istanbul ignore next */
  backgroundColor= '#ffffff',
  itemSelected,
  className
}) => {
  const refEvent = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  }

  const handleSelectItem = (item:any) => {
    if (onSelectItem) {
      setIsOpen(false);
      onSelectItem(item.content, variant);
    }
  }

  const handleClickOutSide = (event:any) => {
    /* istanbul ignore next */
    //@ts-ignore
    if(refEvent.current && !refEvent.current.contains(event.target)){
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, false);
    return () => {
      document.addEventListener("click", handleClickOutSide, false);
    };

  }, []);

  return (
    <div className={`dropdown-list ${variantDropdown} ${className} ${isOpen ? 'opened': ''} ${disabled?'disabled':''}`} ref={refEvent} style={{margin, padding, height, width, backgroundColor}}>
      <label className={isOpen ? 'opened': ''}>{labelTitle} {labelTitle && ":"} <span className='selected-title' onClick={handleClick}>{itemSelected || selectedTitle} {variantDropdown === 'actions-dropdown' && <span className='arrow-action'></span>}{labelChild}</span></label>
      {
        isOpen && <div className={(isMobileOnly && variantDropdown === 'actions-dropdown-inbox' && 'mobile-popup ') + (isOpen ? 'show-filter ': /* istanbul ignore next */'') + 'dropdown-content ' + variantDropdown + '-for-action'} id={variantDropdown}>
          {isMobileOnly && variantDropdown === 'actions-dropdown-inbox' && <div className='filter-title-mb'>Action <Image data-testid={'filter-open-mb'} onClick={()=>setIsOpen(false)} srcImg={Images.CloseGray} width={18}/></div>}
          {
            variant !== 'checkbox' && data && <ul>
              {
                data && data.map((item: any) => <li data-testid={'test-item-'+item.id} key={item.id} onClick={() => variantDropdown === 'actions-dropdown' ? onSelectActions && setIsOpen(false): handleSelectItem(item)}>{item.content}</li>)
              }
              </ul>
          }

          {
            children
          }
          </div>
      }
    </div>
  );
};
