import React from 'react';
import {ControlDisplayProps} from './ControlDisplay.props';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import './ControlDisplay.styles.css';
import {Colors} from "../../../configs/colors"

export const ControlDisplay: React.FC<ControlDisplayProps> = ({
  /* istanbul ignore next */
  label,
  content,
  /* istanbul ignore next */
  labelWidth = "100px",
  /* istanbul ignore next */
  labelAlign= "left",
  /* istanbul ignore next */
  labelColor = Colors.LabelColor,
  /* istanbul ignore next */
  labelFontWeight= "400",
  /* istanbul ignore next */
  contentAlign= "left",
  /* istanbul ignore next */
  contentColor= Colors.Black80,
  /* istanbul ignore next */
  contentFontWeight= "400",
  /* istanbul ignore next */
  contentFontStyle= "",
  /* istanbul ignore next */
  boxDirection="row",
  /* istanbul ignore next */
  boxAlign="flex-start",
  /* istanbul ignore next */
  boxMb=2,
  /* istanbul ignore next */
  display='flex',
  /* istanbul ignore next */
  variant='body2',
  /* istanbul ignore next */
  flexBasis="auto",
  pointerType = "default",
  contentOverflow = "auto",
  onClick=()=>{}
}) => {
    return (
        <Box sx={{display: display, flexDirection: boxDirection, alignItems: boxAlign, mb: boxMb, marginBottom: "8px"}} className={'control-display'}>
            <Typography variant={variant} style={{
              width: labelWidth,
              color: labelColor,
              fontWeight: labelFontWeight,
              marginRight: 16,
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: flexBasis
            }}
                        className={'control-display-label'} align={labelAlign}>{label} </Typography>
          {content !== undefined ?
            <Typography onClick={onClick} variant={variant}
                         style={{color: contentColor, fontWeight: contentFontWeight, fontStyle: contentFontStyle, overflow: contentOverflow, cursor: pointerType}}
                         className={'control-display-content'} align={contentAlign}>{content}</Typography>
            :/* istanbul ignore next */
            <Skeleton variant="text" height={20} sx={{fontSize: '1rem', width: '50%'}}/>
          }
        </Box>
    );
};
