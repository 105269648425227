import React, {forwardRef, useImperativeHandle, useState} from 'react'
import './EditBlockListModal.styles.css';
import {Box, Tooltip, FormControl, MenuItem, Select} from "@mui/material";
import {
    BLOCK_LIST_DURATION,
    BLOCK_LIST_ATTRIBUTE_TYPE
} from "../../../constants/contants";

export const EditBlockListModal = forwardRef((props:any, ref:any) => {
    const [blockListExpireDuration, setBlockListExpireDuration] = useState<any>(Object.keys(BLOCK_LIST_DURATION)[0])

    let description:string = ''
    switch (props.payload.attr_type) {
        case BLOCK_LIST_ATTRIBUTE_TYPE.URL:
            description = 'Block URL:'
            break
        case BLOCK_LIST_ATTRIBUTE_TYPE.FILE_HASH:
            description = 'Block File Hash:'
            break
        case BLOCK_LIST_ATTRIBUTE_TYPE.SENDER:
            description = 'Block Sender:'
            break
    }

    const handleChangeExpireDuration = (event: any) => {
        const item:any = event.target.value
        setBlockListExpireDuration(item)
    }

    const getValue = () => {
        const newPayload = JSON.parse(JSON.stringify(props.payload))
        newPayload.expire_code = parseInt(blockListExpireDuration)
        return newPayload
    }

    useImperativeHandle(ref, () => ({
        getValue
    }))

    return (
        <Box className={'edit-block-list-content'}>
            <Box className={'edit-block-list-info'}>
                <span className={'edit-block-list-title'}>{description}</span>
                <Tooltip placement={"top"} title={props.payload.value}
                         componentsProps={{
                             tooltip: {
                                 sx: {
                                     maxWidth: "500px",
                                 },
                             },
                         }}
                >
                    <span className={'edit-block-list-value'}>{props.payload.value}</span>
                </Tooltip>

            </Box>
            <Box className={'edit-block-list-duration'}>
                <span className={'edit-block-list-title'}>Remove block entry after</span>
                <FormControl>
                    <Select
                        id={'select-block-list-duration'}
                        sx={{ fontSize: '14px', fontWeight: 500, minWidth: '244px', minHeight: '48px'}}
                        value={blockListExpireDuration}
                        onChange={(e: any) => {handleChangeExpireDuration(e)}}
                    >
                        {Object.keys(BLOCK_LIST_DURATION).map((key:any, index: number) => (
                            <MenuItem
                                key={index}
                                value={key}
                                style={{
                                    paddingRight: '12px',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    minHeight: '48px'
                                }}
                            >
                                {BLOCK_LIST_DURATION[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
})
