import React, {useEffect, useRef, useState,} from "react";
import {DateRangePickerProps} from './DateRangePicker.props';
import moment from 'moment-timezone';

import {action} from '@storybook/addon-actions';
import DatePicker from '../../../lib/react-bootstrap-daterangepicker';
import '../../../lib/bootstrap-daterangepicker/daterangepicker.css';
import './DateRangePicker.styles.css';
import {Text} from '../Text';
import { ReactComponent as Calendar } from "../../../assets/images/calendar.svg";
import {isMobileOnly} from "react-device-detect";

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
    textFilter,
    linkIcon= true,
    fontSize,
    color,
    colorText,
    width,
    padding,
    margin,
    cssClass,
    onDateFilter,
    start,
    end,
    dates,
    titleCalendar='Reported At:',
    handleApplyDateRange,
    colorInput='#ffffff',
    dateKeyRef
}) => {

    let tz_name = 'Etc/UTC';
    const localStorageUserData = localStorage.getItem('userData');
    if(localStorageUserData) {
        const parseUserData = JSON.parse(localStorageUserData)
        tz_name = parseUserData["tz_name"]
    }

    moment.tz.setDefault(tz_name);
    
    let [isSingleDate, setSingleDate] = useState({isSingle: window.innerWidth < 768 || isMobileOnly})
    const [seed, setSeed] = useState(1);
    
    const [displayDate, setDisplayDate] = useState({
        start: dates && dates.startDate  || start,
        end: dates && dates.endDate || end
    })
    
    const keyRef = useRef();

    const handleApply = (event:any ,picker:any)=> {
        if(event.type === "apply"){
            
            handleApplyDateRange({
                startDate: moment(picker.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment(picker.endDate).utc().format('YYYY-MM-DD HH:mm:ss')
            });
            setDisplayDate({
                  start: moment(picker.startDate).format('MM/DD/YYYY'),
                  end: moment(picker.endDate).format('MM/DD/YYYY')
              }
            )
            onDateFilter && onDateFilter(picker.startDate.utc().format('YYYY-MM-DD HH:mm:ss'), picker.endDate.utc().format('YYYY-MM-DD HH:mm:ss'))
        }
    }

    const dateRange = {
        Today: [moment().toDate(), moment().toDate()],
        Yesterday: [
            moment().subtract(1, 'days').toDate(),
            moment().subtract(1, 'days').toDate(),
        ],
        'Last 7 Days': [
            moment().subtract(6, 'days').toDate(),
            moment().toDate(),
        ],
        'Last 30 Days': [
            moment().subtract(29, 'days').toDate(),
            moment().toDate(),
        ],
        'This Month': [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
        ],
        'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
        ],
    }
    
    const resizeHandler = () => {
        setSeed(Math.random());
        setSingleDate({isSingle: window.innerWidth < 768 || isMobileOnly})
    };
    
    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        //@ts-ignores

        return () => {
            window.removeEventListener('resize', resizeHandler);
        }
    }, []);

    return (
        <div className="date-range-filter" style={{margin}}>
            <Text cssClass='date-range-title' children={textFilter} color={colorText} fontSize={14} margin='0 5px'/>
            <DatePicker
                ref={keyRef.current}
                key={seed}
                onApply={handleApply}
                onCancel={action('onCancel')}
                onEvent={action('onEvent')}
                onHide={action('onHide')}
                onHideCalendar={action('onHideCalendar')}
                onShow={action('onShow')}
                onShowCalendar={action('onShowCalendar')}
                initialSettings={
                    {
                        startDate: displayDate.start,
                        endDate: displayDate.end,
                        ranges: dateRange,
                        singleDatePicker: isSingleDate.isSingle,
                        alwaysShowCalendars: true
                    }
                }
            >
                <input type="text" className={"form-control " + cssClass} inputMode={'none'}  readOnly={true} name='input-date-range'/>
            </DatePicker>
            <span className='date-label'>{titleCalendar}<span className='date-value'>{(moment(start, 'MM/DD/YYYY').format('DD/MM/YYYY')) + ' - ' + (moment(end, 'MM/DD/YYYY').format('DD/MM/YYYY'))}</span>{
              linkIcon? <Calendar fill={'#345C8B'}/> : ''}</span>
        </div>
    );
};
