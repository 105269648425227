  import React from 'react';
import {TextProps} from './Text.props';
import './Text.styles.css';

export const Text: React.FC<TextProps> = ({
  children,
  /* istanbul ignore next */
  fontSize= 14,
  /* istanbul ignore next */
  color= '#000000',
  /* istanbul ignore next */
  cssClass= '',
  /* istanbul ignore next */
  margin='0',
  fontWeight,
  width,
  height,
  /* istanbul ignore next */
  textAlign = '',
  /* istanbul ignore next */
  backgroundColor= 'transparent',
}) => {
  return (
    <p className={`text-style ${cssClass}`} style={{height, width, fontSize, color, margin, fontWeight,textAlign, backgroundColor}}>
      {children}
    </p>
  );
};
