import React, {useState} from 'react';
import {DropdownContentProps} from "./DropdownContent.props";
import './DropdownContent.styles.css';
import {IconButton, Tooltip} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {Modal} from "../../atoms/Modal";
import {
  PURGE_TITLE,
  MESSAGES_STATUS_CHANGED,
  MESSAGES_PRIORITY_CHANGED,
  MULTIPLE_SELECTED_EMAIL,
  PURGE_NOTICE,
  PURGE_CONFIRM,
  LOGIN_AZURE_TITLE,
  LOGIN_AZURE_CONTENT,
  MULTIPLE_SELECTED_PHISH_CLEAR, MENU_CLASSES
} from "../../../constants/contants";

export const DropdownContent: React.FC<DropdownContentProps> = ({
  menuData,
  handleOpenSendMail,
  handleDeleteMail,
  handleArchiveMail,
  actionListData,
  handleActionList,
  actionOtherData,
  actionPhishData,
  handlePurgeMail,
  handleConvertToMailTemplate,
  handleAddToBlockList,
  isAuthenticatedAzure,
  loginAzure,
  hasAccessToken
}) => {
  const zoneID:any = sessionStorage.getItem('currentZone') || localStorage.getItem('currentZone');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [contentModal, setContentModal] = useState({title: '', content: '', action: '', textAgree: '', status: '', priority: '', children: '', variant: ""});

  const handleOpenConfirm = (action?:any) => {
    let title, content, textAgree, textCancel, html = ''
    let log:any = contentPopup(action)
    title = log.title
    html = log.childHTML
    textAgree = log.textAgree
    content = log.content

    const popupContent:any = {
      title: title,
      content: content,
      children: <div dangerouslySetInnerHTML={{ __html: html }} />,
      action: action,
      textAgree: textAgree,
      variant: action === 'Archive' || action === 'SendMail' ? 'default' : 'delete'
    }
    setOpenConfirm(true);
    setContentModal(popupContent)
  };

  /* istanbul ignore next */
  const handleClose = () => {
    setOpenConfirm(false);
  };

  const handleAction = (rule:string) => {
    /* istanbul ignore next */
    switch (rule){
      case 'Delete':
        let emailDelete = []
        if(menuData[0].email_id){
          emailDelete.push({
            'email_id': menuData[0].email_id,
            'zone_id': zoneID.toString()
          })
        }
        return handleDeleteMail(emailDelete);
      case 'Archive':
        let emailArchive = []
        if(menuData[0].email_id){
          emailArchive.push({
            'email_id': menuData[0].email_id,
          })
        }
        return handleArchiveMail(emailArchive);
      case 'Purge':
        let emailPurge = []
        if(menuData[0].email_id){
          emailPurge.push({
            'email_id': menuData[0].email_id,
            'email_content': menuData[0].email_content
          })
        }
        return handlePurgeMail(emailPurge)
      case 'LoginAzure':
        return loginAzure()
    }
  }

  const contentPopup = (action:string) => {
    let title, content, textAgree, textCancel, html, pluralEmail= '', pluralMessage = ''
    title = action + ' message'
    textAgree = action === "Delete" ? "Delete" : 'Archive'
    if (menuData.length > 1){
        pluralEmail = "emails"
        pluralMessage = "messages"
    }else {
        pluralEmail = "email"
        pluralMessage = "message"
    }
    if (action === 'Purge'){
      title = `Remove ${pluralEmail} from your organisation mailboxes`
      html = `<div> Phish Clear will find and remove emails that have the <strong>same subject</strong> and from the <strong>same sender</strong> across your organisation mailboxes.
                    ${PURGE_CONFIRM}</div>
                <div class="notes">
                    <div>${MESSAGES_STATUS_CHANGED}: <span>Resolved</span></div>
                </div>`
      textAgree = 'Remove'
    } else if (window.location.pathname.split('/')[2]){
      content = `Are you sure you want to ${action.toLowerCase()} this message?`
    } else if(menuData.length === 1){
      content = `Are you sure you want to ${action.toLowerCase()} 1 selected message?`
    } else {
      content = `Are you sure you want to ${action.toLowerCase()} ` + menuData.length + ' selected messages?'
    }

    return {title: title, content: content, childHTML: html, textAgree: textAgree, textCancel: textCancel}
  }

  const noticeLoginAzure = (pwExpired:any) => {
    let content_html = '';
    if (pwExpired){
      content_html = `<div>
          <div>Your Microsoft Purview credentials have expired. Please re-log into Microsoft 365 account with required Microsoft Purview eDiscovery Administrator credentials.</div>
          <div>This will allow the purging of emails from the organisation mailboxes on your behalf.</div>
      </div>`
    } else {
      content_html = `<div>
          <div>To perform this action, please log into Microsoft 365 account with required Microsoft Purview eDiscovery Administrator credentials.</div>
          <div>This will allow the purging of emails from the organisation mailboxes on your behalf.</div>
      </div>`
    }
    const popupContent:any = {
      title: LOGIN_AZURE_TITLE,
      children: <div dangerouslySetInnerHTML={{ __html: content_html }} />,
      action: 'LoginAzure',
      textAgree: 'Log in',
      variant: 'default'
    }
    setOpenConfirm(true);
    setContentModal(popupContent)
  }

  const checkLoginAzure = () => {
    if (isAuthenticatedAzure){
      if(!hasAccessToken){
        noticeLoginAzure(!hasAccessToken)
      }
    } else {
      noticeLoginAzure(isAuthenticatedAzure)
    }

  }

  const actionClickRule = (rule:string) => {
    switch (rule){
      case 'SendMail':
        return handleOpenSendMail(rule);
      case 'Delete':
        return handleOpenConfirm(rule)
      case 'Archive':
        return handleOpenConfirm(rule)
      case 'Purge':
        if (isAuthenticatedAzure){
          if(hasAccessToken){
            return handleOpenConfirm(rule)
          } else {
            return checkLoginAzure()
          }
        } else {
          return checkLoginAzure()
        }
      case "ConvertToMailTemplate":
        return handleConvertToMailTemplate && handleConvertToMailTemplate();
      case "AddToBlockList":
        return handleAddToBlockList && handleAddToBlockList();
    }
  }

  const handleClickActionList = (type:string) => {
    const rowSelectedId:string = menuData[0]
    handleActionList(type, rowSelectedId)
  }

  const handleDropdownItemClick = (rule: string) => {
    if (rule === 'Delete' ||  rule === 'Archive' || rule === 'Purge') {
      handleOpenConfirm(rule);
    } else if (rule === 'ConvertToMailTemplate') {
      handleConvertToMailTemplate && handleConvertToMailTemplate();
    } else {
      handleOpenSendMail(rule);
    }
  };

  const actionList:any = (
    actionListData ?
      <div className='actions-rules'>
        <ul>
          {
            Object.keys(actionListData).map((type:string) => <li key={type} data-testid={'action-list-'+type} onClick={() => handleClickActionList(type)} >{actionListData[type]}</li>)
          }
        </ul>
      </div> : <></>
  )

  const otherList:any = (
    actionOtherData ?
    <div className='actions-rules for-email'>
      {/*<div className='action-title'>Others</div>*/}
      <ul>
        {
          Object.keys(actionOtherData).map((rule:string) =>
              menuData.length >= 2 && rule === 'SendMail' ?
                  <Tooltip key={rule} placement={"top"} title={MULTIPLE_SELECTED_EMAIL}>
                      <li key={rule} className='disabled hidden'>{actionOtherData[rule]}</li>
                  </Tooltip> : menuData.length >= 2 && rule === 'Purge' ?
                      <Tooltip key={rule} placement={"top"} title={MULTIPLE_SELECTED_PHISH_CLEAR}>
                        <li key={rule} className='disabled hidden'>{actionOtherData[rule]}</li>
                      </Tooltip> : rule === 'Purge' ?
                  <Tooltip key={rule} placement={"top"} title={"Remove the emails from all users' inbox"}>
                    <li className={MENU_CLASSES[rule]} data-testid={'rule-'+rule} key={rule} onClick={() => actionClickRule(rule)}>{actionOtherData[rule]}</li>
                  </Tooltip> :
                  <li className={MENU_CLASSES[rule]} data-testid={'rule-'+rule} key={rule} onClick={() => actionClickRule(rule)}>{actionOtherData[rule]}</li>
          )
        }
      </ul>
    </div> : /* istanbul ignore next */<></>
  )

  const phishList:any = (
    actionPhishData ?
    <div className='actions-rules for-email'>
      <div className='action-title'>Phish Hook</div>
      <ul>
        {
          Object.keys(actionPhishData).map((rule:string) => <li key={rule}>{actionPhishData[rule]}</li>)
        }
      </ul>
    </div> : /* istanbul ignore next */<></>
  )
  return (
    <div>
      {actionList}
      {otherList}
      {phishList}
      <Modal idModal= { contentModal.action == 'LoginAzure' ? 'modal-login-azure' : 'modal-delete-mail' }
             openDialog={openConfirm}
             variant={contentModal.variant}
             title={contentModal.title}
             content={contentModal.content}
             actionAgree={/* istanbul ignore next */() => {
               handleAction(contentModal.action)
               /* istanbul ignore next */
               handleClose()
             }}
             actionCancel={handleClose}
             textAgree={contentModal.textAgree || 'Accept'}
             onClose={handleClose}
             children={contentModal.children}
      />
    </div>
  )
};
