import React from 'react'
import {DashedLineChartProps} from './DashedLineChart.props'
import './DashedLineChart.styles.css';
import ReactApexChart from "react-apexcharts";

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {NoDataCard} from "../NoDataCard";

export const  DashedLineChart: React.FC<DashedLineChartProps> = ({data}) => {
  const options =  {

    colors: ['#ED1C24', '#FCAB10', '#03A9F5', '#909090'],
    fill: {
      colors: ['#ED1C24', '#FCAB10', '#03A9F5', '#909090'],
      opacity: 1,
    },
    legend: {
      show: true,
      fontSize: '11px',
      fontFamily: 'Montserrat, Helvetica, Arial',
      fontWeight: 400,
      colors: ['#808888', '#808888', '#808888', '#808888'],
      markers: {
        width: 14,
        height: 14,
        radius: 14,
      } ,
      onItemClick: {
        toggleDataSeries: false
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
      enabled: false
    },
  },
    tooltip: {
      enabled: true
    },
  dataLabels: {
    enabled: false
  },
    stroke: {
      curve: 'straight',
      show: true,
      width: 2,
    },
  markers: {
    size: 0,
      hover: {
      sizeOffset: 6
    }
  },
  xaxis: {
    show: true,
    axisBorder: {
      show: true,
      height: 2,
      color: '#808888',
    },
    labels: {
      style: {
        colors: ['#808888','#808888','#808888','#808888','#808888','#808888'],
        fontSize: '10px',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
        fontWeight: 400,
      }
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#808888',
      width: 1000

    },
    categories: data.months,
  },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: ['#808888'],
          fontSize: '10px',
          fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
      },
    },

  grid: {
      show: false
  },
};

  const series =  [{
    name: "High",
    data: data.High,

  },
    {
      name: "Medium",
      data: data.Medium
    },
    {
      name: 'Low',
      data: data.Low
    },
    {
      name: 'Unknown',
      data: data.Unknown
    }
  ];

  let hasData = false
  for (const item of series) {
    for (const sub_item of item.data) {
      if(hasData) { break; }
      if(sub_item > 0){
        hasData = true;
        break;
      }
    }
  }

  return (
    <Box className={'dashed-line-chart'}>
      {/*//@ts-ignores*/
        hasData ? <ReactApexChart
          type="line"
          //@ts-ignores
          options={options}
          series={series}
          height={'240px'}
          width={'100%'}
        /> :
          /* istanbul ignore next */<NoDataCard type={'dashedLine'} padding={'70px 0 0 0'} ></NoDataCard> }
    </Box>

  );
}
