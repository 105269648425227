import React from 'react'
import {NoThreatDetectedCardProps} from './NoThreatDetectedCard.props'
import './NoThreatDetectedCard.styles.css';
import Box from "@mui/material/Box";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RefreshIcon from '@mui/icons-material/Refresh';

export const NoThreatDetectedCard: React.FC<NoThreatDetectedCardProps> = ({
    score = 0
}) => {
    let title:string
    let description:string
    let icon: React.ReactElement;

    if (score === -1){
        title = "Analysing..."
        description = "Phish Focus is still analysing this email to identify any threats. Please check again shortly."
        icon = <RefreshIcon fontSize={"large"} htmlColor={"#193051"} />
    }else if (score === 0){
        title = "No threat detected"
        description = "Phish Focus did not detect any threat in this email. You should remain cautious when working with this email."
        icon = <VerifiedUserIcon fontSize={"large"} htmlColor={"#193051"} />
    }else {
        title = "Undetermined"
        description = "Phish Focus was unable to determine if a threat exists in this email. Please exercise caution when working with this email."
        icon = <HelpOutlineIcon fontSize={"large"} htmlColor={"#193051"} />
    }

   return (
       <Box className={'no-threat-detected-card-wrapper'}>
           <Box>
               <Box sx={{display: "flex", justifyContent: "center"}}>
                   <Box className={'no-threat-detected-icon'} >{icon}</Box>
               </Box>
               <Box className={'no-threat-detected-title'}>{title}</Box>
               <Box className={'no-threat-detected-description'}>{description}</Box>
           </Box>
       </Box>
   )
}
