import React, { useState } from 'react';
import { InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';

import { ConvertToTemplateModalProps } from '.';
import { ADMIN_ROLE } from '../../../constants/contants';
import './ConvertToTemplateModal.styles.css';

export interface IConvertToTemplateData {
  subject: string;
  type: string;
  ranking: number;
};

const ConvertToTemplateModal = ({
  userRole,
  convertTemplateData,
  setConvertTemplateData
}: ConvertToTemplateModalProps) => {
  const { subject, type, ranking } = convertTemplateData;

  const types = [
    { name: 'Campaign', value: 'campaign' },
    { name: 'Baseline', value: 'baseline' },
    { name: 'Spear Phishing', value: 'spear_phishing' },
  ];

  const rankings = (userRole: string, type: string) => type === 'campaign' ?
    userRole === ADMIN_ROLE ? [
      { name: 'Ranking 1', value: 1 },
      { name: 'Ranking 2', value: 2 },
      { name: 'Ranking 3', value: 3 },
      { name: 'Ranking 4', value: 4 },
      { name: 'Ranking 5', value: 5 },
      { name: 'Ranking 6', value: 6 },
      { name: 'Ranking 7', value: 7 },
      { name: 'Ranking 8', value: 8 },
      { name: 'Ranking 9', value: 9 }
    ] : [
      { name: 'Custom level 1', value: 50 },
      { name: 'Custom level 2', value: 60 },
      { name: 'Custom level 3', value: 70 },
    ] : [type === 'baseline' ? { name: userRole === ADMIN_ROLE ? 'Baseline (Ranking 10)' : 'Baseline', value: 10 } :
          { name: 'Spear Phishing', value: 99 }];

  const handleChange = (e: any, type: string) => {
    const { value } = e.target;
    const data = { [type]: value };
    if (type === 'type') {
      Object.assign(data, {
        ranking: rankings(userRole, value)[0]['value']
      });
    }

    setConvertTemplateData((prev: IConvertToTemplateData) => ({
      ...prev,
      ...data,
    }));
  };

  return (
    <Stack className="form-body" gap={3}>
      <Stack>
        <InputLabel htmlFor="name" className="label-input">Template Name</InputLabel>
        <OutlinedInput
          id="subject"
          className="form-input"
          value={subject}
          onChange={(e: any) => handleChange(e, 'subject')}
          sx={{ fontSize: '14px', fontWeight: 500, }}
        />
      </Stack>
      <Stack direction="row" gap={3}>
        <Stack className="select-container">
          <InputLabel htmlFor="type" className="label-input">Template Type</InputLabel>
          <Select
            id="type"
            value={type}
            sx={{ fontSize: '14px', fontWeight: 500, }}
            onChange={(e) => handleChange(e, 'type')}
            size={'small'}
          >
            {types.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
                style={{
                  paddingRight: '12px',
                  fontSize: '14px',
                  fontWeight: 500
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack className="select-container">
          <InputLabel htmlFor="ranking" className="label-input">Ranking</InputLabel>
          <Select
            id="ranking"
            value={ranking}
            disabled={rankings(userRole, type).length <= 1}
            sx={{ fontSize: '14px', fontWeight: 500, }}
            onChange={(e) => handleChange(e, 'ranking')}
          >
            {rankings(userRole, type).map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
                style={{
                  paddingRight: '12px',
                  fontSize: '14px',
                  fontWeight: 500
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ConvertToTemplateModal;
