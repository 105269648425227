import React, {useEffect, useRef, useState} from 'react'
import {SendMailModalProps} from './SendMailModal.props'
import './SendMailModal.styles.css';
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";
import {AlertMessage} from "../../atoms/AlertMessage";
import {TagInput} from "../../atoms/TagInput";
import {isMobileOnly} from "react-device-detect";
import {Editor} from "../../molecules/Editor";
import {MESSAGES_INFECTED_SENDMAIL} from "../../../constants/contants";
import InputAdornment from "@mui/material/InputAdornment";
import { FromInputMail } from "../../molecules/FromInputMail/FromInputMail";

export const SendMailModal: React.FC<SendMailModalProps> = ({
    defaultParams,
    paramSendMail,
    setParamSendMail,
    scanResult,
    /* istanbul ignore next */
    // isHiddenAttachedAndStatus = false,
    /* istanbul ignore next */
    isFromName = false,
    defaultContent,
    /* istanbul ignore next */
    // isShowAttachedDefault = false,
    /* istanbul ignore next */
    attachedLabel = '',
    checkboxInputCC = true,
    subjectPrefix = '',
    isHiddenToCC = false,
    validateFromName= false,
    setValidateFromName,
    fromNameError,
    editorError,
    validateToField,
    setValidateToField,
    validateEmailContent,
    setValidateEmailContent,
    validateUserName,
    setValidateUserName,
    validateBaseSender,
    setValidateBaseSender,
    lineBelowContent,
    isTestDomain,
    testDomainName,
    isMessageDownload= false
}) => {
    // @ts-ignore
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : /* istanbul ignore next */{};
    const defaultData: any = {
        subject: defaultParams.subject || /* istanbul ignore next */'',
        from: userData.email,
        fromName: userData.fullName,
        to: [],
        cc: [],
        originalReporter: true,
        statusToResolved: true,
        content: '',
        base64Data: '',
        originalEmail: '',
        recipients: [],
        reportedBy: defaultParams.reportedBy
    }
    const tagRefTo: any = useRef();
    const tagRefCC: any = useRef();

    const handleOnChange = (e: any, type: any) => {
        let item: any = e.target.value;
        if (type === 'originalReporter') {
            item = e.target.checked
        }
        setParamSendMail({
            ...paramSendMail,
            [ type ]: item,
        });

        if(!item.trim() && type === 'fromName' && setValidateFromName) {
            setValidateFromName(true) ;
        }

    }

    let xsValue = 6

    if (isMobileOnly) {
        xsValue = 12
    }

    return (
        <div className='form-body'>
            {scanResult[ 'scan-result' ] === 'Infected' ?
                <AlertMessage severity='error' content={MESSAGES_INFECTED_SENDMAIL}/> : null}
            <Grid container item xs={12} rowSpacing={1} >
                {!isTestDomain ? <FromInputMail
                    validateFromName={validateFromName}
                    paramSendMail={paramSendMail}
                    fromNameError={fromNameError}
                    setParamSendMail={setParamSendMail}
                    setValidateFromName={setValidateFromName}
                    validateUserName={validateUserName}
                    setValidateUserName={setValidateUserName}
                    validateBaseSender={validateBaseSender}
                    setValidateBaseSender={setValidateBaseSender}
                /> : <Grid item xs={12} md={12}>
                    <InputLabel htmlFor="to-field" className='label-input' sx={{marginTop: '16px'}}>From </InputLabel>
                    <p style={{
                        display:"flex",
                        alignItems:"center",
                        fontSize: '14px',
                        fontWeight: 500,
                        fontFamily: 'Montserrat',
                        color: '#193051',
                        margin: 0,
                    }}>{testDomainName}
                    </p>
                </Grid>}
                {!isHiddenToCC && <>
                    <Grid item xs={12} md={12}>
                        <InputLabel htmlFor="to-field" className='label-input'>To <span style={{color:'#B00020'}}>*</span></InputLabel>
                        <TagInput tagRef={tagRefTo} type={'to'} paramSendMail={paramSendMail}
                                  setParamSendMail={setParamSendMail}
                                  validateToField={validateToField}
                                  setValidateToField={setValidateToField}
                        />
                    </Grid>
                    {!isTestDomain && <Grid item xs={12} md={12}>
                        <InputLabel htmlFor="cc-field" className='label-input'>CC</InputLabel>
                        <TagInput tagRef={tagRefCC} type={'cc'} paramSendMail={paramSendMail}
                                  setParamSendMail={setParamSendMail} checkboxInput={checkboxInputCC}/>
                    </Grid>}
                </>}
            </Grid>
            <div style={{marginBottom: '24px'}}>
                <InputLabel htmlFor="subject" className='label-input'>Subject</InputLabel>
                {subjectPrefix ?
                    <OutlinedInput className='form-input input-prefix' type="text" placeholder='Enter subject'
                                   value={paramSendMail.subject} onChange={(e: any) => handleOnChange(e, 'subject')}
                                   style={{border: "unset"}}
                                   endAdornment={
                                       <InputAdornment position={"end"}>
                                           {subjectPrefix}
                                       </InputAdornment>}
                                   id="outlined-adornment-weight"
                    /> :
                    <OutlinedInput id="subject" className='form-input' type="text" placeholder='Enter subject'
                                   value={paramSendMail.subject} onChange={(e: any) => handleOnChange(e, 'subject')}/>
                }
            </div>
            <InputLabel className='label-input'>Email Content <span style={{color:'#B00020'}}>*</span></InputLabel>
            <div style={{border: '1px solid #E0E0E0', padding: '16px'}}>
            <Editor paramSendMail={paramSendMail}
                    setParamSendMail={setParamSendMail}
                    defaultContent={defaultContent}
                    editorError={editorError}
                    validateEmailContent={validateEmailContent}
                    setValidateEmailContent={setValidateEmailContent}
                    isMessageDownload={isMessageDownload}
            />
                {lineBelowContent && <hr style={{margin: '26px 0 12px 0', color: '#E0E0E0', borderStyle:'solid'}}/>}
            </div>
        </div>
    )
}
