import React, {useEffect, useRef} from 'react';
import { useParams,} from 'react-router-dom';
import {LoadingPage} from "../organisms/LoadingPage";
import axios from "axios";
const DownloadToken = ()=>{
    let { token } = useParams()
    
    const callToDownloadFile = (token:string): Promise<any> => {
        return new Promise((resolve, reject) => {
            const unInterceptedAxiosInstance = axios.create();
            const data = unInterceptedAxiosInstance({
                method: 'POST',
                url: process.env.REACT_APP_BASE_API_URL + '/download-file',
                data: {
                    token
                }
            })
            data.then((response) => {
                resolve(response?.data.presigned_url);
            }).catch((error) => {
                reject(error);
            });
        })
    };
    
    useEffect(() =>{
        if(token){
            callToDownloadFile(token).then(res=>{
                if(res){
                    window.location.href=res;
                }
            });
        }
    }, [])
    return(
    <></>
    )
}

export default DownloadToken;