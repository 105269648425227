import React, {useEffect, useState} from 'react';
import {InputBase, ListItem, Typography} from "@mui/material";
import {ReactComponent as SearchIcon} from "../../../assets/images/searchIcon.svg";
import {TreeDropDownProps} from "./TreeDropDown.props";
import Button from "@mui/material/Button";
import {styled, alpha} from '@mui/material/styles';
import Menu, {MenuProps} from '@mui/material/Menu';
import {SvgIconProps} from '@mui/material/SvgIcon';
import TreeView from '@mui/lab/TreeView';
import TreeItem, {TreeItemProps, treeItemClasses, TreeItemContentProps} from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import {useSpring, animated} from '@react-spring/web';
import {TransitionProps} from '@mui/material/transitions';
import './TreeDropDown.styles.css'
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {COMPANY_ADMIN_ROLE} from "../../../constants/contants";
import clsx from "clsx";
import {useTreeItem} from "@mui/lab";
import Tooltip from '@mui/material/Tooltip';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: '400px',
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[ 300 ],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props} ContentComponent={CustomContent} TransitionComponent={TransitionComponent}/>
))(({theme}) => ({
    [ `& .${treeItemClasses.root}` ]: {},
    [ `& .${treeItemClasses.content}` ]: {
        lineHeight: 36,
        width: '95%',
        padding: '0 7px 0 11px',
        '&:hover': {
            backgroundColor: '#E7EBF0',
        },
    },
    [ `& .${treeItemClasses.iconContainer}` ]: {
        width: '18px !important',
        '& .close': {
            opacity: 0.3,
        },
        fontSize: '14px !important',
    },
    [ `& .${treeItemClasses.label}` ]: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        display: 'flex',
    },
    [ `& .${treeItemClasses.group}` ]: {
        marginLeft: 19,
        paddingLeft: 8,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

function MinusSquare(props: SvgIconProps) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="16" height="16" rx="1" fill="#345C8B"/>
            <rect width="10" height="2" transform="translate(4 8)" fill="white"/>
            <rect x="1" y="1" width="16" height="16" rx="1" stroke="#345C8B" stroke-width="2"/>
        </svg>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM8 14H10V10H14V8H10V4H8V8H4V10H8V14Z"
                fill="#345C8B"/>
        </svg>
    );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 16H2V2H16V16ZM0 0V18H18V0H0ZM14 12.59L12.59 14L9 10.41L5.41 14L4 12.59L7.59 9L4 5.41L5.41 4L9 7.59L12.59 4L14 5.41L10.41 9L14 12.59Z"
                fill="#CCCCCC"/>
        </svg>
    );
}

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });
    
    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const CustomContent = React.forwardRef(function CustomContent(
    props: TreeItemContentProps,
    ref,
) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    } = props;
    
    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection
    } = useTreeItem(nodeId);
    
    const icon = iconProp || expansionIcon || displayIcon;
    
    const handleMouseDown = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        preventSelection(event);
    };
    
    const handleExpansionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        handleExpansion(event);
    };
    
    const handleSelectionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        handleSelection(event);
    };
    
    return (
        <Tooltip className={'tree-tooltip'}
                 classes={{ popper: 'tree-tooltip-label' }}
                 title={label} sx={{width: '100%'}}
                 placement="top-end"
                 arrow
        >
        <div
            className={clsx(className, classes.root, {
                [ classes.expanded ]: expanded,
                [ classes.selected ]: selected,
                [ classes.focused ]: focused,
                [ classes.disabled ]: disabled
            })}
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
             <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label }
            >
               <div style={{display:"contents"}} className={'tree-limit-with-label'}> {label}</div>
            </Typography>
            
        </div></Tooltip>
    );
});

export const TreeDropDown: React.FC<TreeDropDownProps> = ({masterNode, dataSource = [], ...props}) => {
    const [listNode, setListNode] = useState<any>()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [btnZone, setBtnZone] = useState<any>({id: null, name: '', path: ''})
    const [defaultExpanded, setDefaultExpanded] = useState<any>([])
    
    const localStorageUserData = localStorage.getItem('userData');
    let parseUserData: any;
    if (localStorageUserData) {
        parseUserData = JSON.parse(localStorageUserData)
    }
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setListNode(mapTree(dataSource))
    };
    
    const checkPath = (valueCheck: any, dataCheck: any) => {
        for (let i = valueCheck.path.length; i > 0; i--) {
            if (valueCheck.path[ i - 1 ] !== dataCheck.path[ i - 1 ]) {
                return false;
            }
        }
        return true
    }
    const mapTree = (treeData: any) => {
        const mapTreeData = treeData.map((res: any) => {
            return ({...res, path: res.path.trim().split(' ')})
        })
        const treeDataDescending = mapTreeData.sort((a: any, b: any) => b.path.length - a.path.length);
        let list: any = [];
        treeDataDescending.forEach((res: any) => {
            if (res && res.path.length > 1) {
                const map = treeDataDescending.findIndex((item: any) => (item.path.length === res.path.length - 1 && checkPath(item, res)))
                if (map !== -1) {
                    if (treeDataDescending[ map ][ 'children' ] && treeDataDescending[ map ][ 'children' ][ 'length' ] > 0) {
                        treeDataDescending[ map ][ 'children' ] = [...treeDataDescending[ map ][ 'children' ], res];
                    } else {
                        treeDataDescending[ map ][ 'children' ] = [res];
                    }
                    list = treeDataDescending
                }
            }
        });
        list = list.filter((x: any) => x.path.length === 1)
        return list;
    }
    
    useEffect(() => {
        if (dataSource && dataSource.length > 0) {
            setListNode(mapTree(dataSource))
            if (masterNode && Object.keys(masterNode).length !== 0) {
                const pathArrMasterNode = {...masterNode, path: masterNode?.path?.trim().split(' ')};
                setBtnZone(pathArrMasterNode)
            } else {
                const pathArrMasterNode = {...dataSource[ 0 ], path: dataSource[ 0 ]?.path.trim().split(' ')};
                setBtnZone(pathArrMasterNode)
            }
        }
    }, [dataSource])
    
    useEffect(() => {
        if (btnZone && btnZone.id) {
            const expendData = filterExpend(listNode, btnZone.id)
            if (Array.isArray(expendData)) {
                if (parseUserData && parseUserData?.role === COMPANY_ADMIN_ROLE) {
                    setDefaultExpanded(expendData.filter((x: any) => {
                        return (btnZone?.path.length > 1 && x === btnZone.id) ? false : true;
                    }))
                } else {
                    setDefaultExpanded(expendData.filter((x: any) => x !== btnZone.id))
                }
            }
        }
    }, [btnZone])
    const handleItemClick = (item: any) => {
        props.onItemClick(item);
        setBtnZone(item);
        handleClose();
    }
    
    const filterBy = (arr: any, query: any) => {
        return query ? arr.reduce((acc: any, item: any) => {
            if (item.children?.length) {
                const filtered = filterBy(item.children, query)
                if (filtered.length) return [...acc, {...item, children: filtered}]
            }
            const {children, ...itemWithoutChildren} = item;
            return item.name?.toLowerCase().includes(query.toLowerCase()) ? [...acc, itemWithoutChildren] : acc
        }, []) : arr
    }
    const onQueryChange = (e: any) => {
        if (e.target.value) {
            const listData = mapTree(dataSource)
            setListNode(filterBy(listData, e.target.value))
        } else {
            setListNode(mapTree(dataSource))
        }
    }
    
    const filterExpend = (arr: any, id: any) => {
        return id ? arr.reduce((acc: any, item: any) => {
            if (item.children?.length) {
                const filtered = filterExpend(item.children, id)
                if (filtered.length) {
                    return [...acc, item.id, ...filtered]
                }
            }
            const {children, ...itemWithoutChildren} = item;
            if (parseUserData?.role === COMPANY_ADMIN_ROLE) {
                return [...acc, itemWithoutChildren.id];
            } else {
                return item.id === id ? [...acc, itemWithoutChildren.id] : acc
            }
        }, []) : []
    }
    
    const getTreeItemsFromData = (treeItems: any = []) => {
        return treeItems.map((treeItemData: any) => {
            let children = undefined;
            if (treeItemData.children && treeItemData.children.length > 0) {
                children = getTreeItemsFromData(treeItemData.children);
            }
            return (
                <StyledTreeItem sx={{fontFamily: 'Montserrat', fontWeight: 400, fontSize: '14px', width: 'auto'}}
                                key={treeItemData.id}
                                nodeId={treeItemData.id}
                                label={treeItemData.id !== btnZone.id ? <p
                                    style={{
                                        lineHeight: '22px',
                                        padding: '7px 0',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: 0,
                                    }}
                                    data-testid={'tree-item-1'}
                                    onClick={() => handleItemClick(treeItemData)}>
                                    <span className={'tree-label-text'}>{treeItemData.name}</span>
                          </p> : <>
                                    <strong style={{
                                        lineHeight: '22px',
                                        width: '95%',
                                        padding: '7px 0',
                                        display: "flex",
                                        alignItems: 'center',
                                        margin: 0,
                                    }}><span className={'tree-label-text'}>{treeItemData.name}</span></strong>
                                    <CheckIcon className={'checked-icon-tree'} sx={{
                                        marginLeft: 'auto',
                                        color: '#345C8B',
                                        fontSize: '18px',
                                        minHeight: '100%',
                                        height: 'unset'
                                    }}/>
                                </>}
                                children={children}
                />
            );
        });
    };
    const dataTreeView = (treeItems: any = []) => {
        return (
            <TreeView
                defaultExpanded={defaultExpanded}
                className={'tree-view-dropdown'}
                aria-label="customized"
                selected={[btnZone?.id]}
                defaultCollapseIcon={<MinusSquare/>}
                defaultExpandIcon={<PlusSquare/>}
                defaultEndIcon={<CloseSquare/>}
                sx={{maxHeight: 500, flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}
            >
                {getTreeItemsFromData(treeItems)}
            </TreeView>
        );
    };
    
    return (
        <div>
            <Button
                id="tree-customized-button"
                data-testid={'tree-customized-button'}
                aria-controls={open ? 'tree-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon sx={{fontSize: '24px !important'}}/>}
            >
                <div className="tree-customized-button-label">
                    {btnZone.name}
                </div>
            </Button>
            <StyledMenu
                id="tree-customized-menu"
                data-testid={"tree-customized-menu"}
                MenuListProps={{
                    'aria-labelledby': 'tree-customized-button',
                }}
                sx={{top: -12, marginLeft: '54px'}}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <div className={'tree-dropdown-menu-header'}>
                    <ListItem sx={{padding: '8px 0'}}>
                        <InputBase
                            className={'tree-input'}
                            data-testid={"tree-dropdown-menu-item"}
                            sx={{fontSize: "14px", width: '90%'}}
                            fullWidth
                            placeholder={"Search"}
                            onChange={(e) => {
                                onQueryChange(e)
                            }}
                            onKeyDown={e => {
                                e.stopPropagation();
                            }}
                            onFocus={e => {
                                e.stopPropagation();
                            }}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        />
                        <SearchIcon className={'search-icon'}/>
                    </ListItem>
                </div>
                {listNode?.length ? dataTreeView(listNode) : <div className={'no-record-found'} style={{
                    paddingLeft: '10px',
                    fontSize: '14px',
                    lineHeight: '40px'
                }}>No record found</div>}
            </StyledMenu>
        </div>
    );
};
