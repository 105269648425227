import React from 'react';
import './login.style.css';
import appConfig from '../../configs/appConfig.json';
import {AuthenProps} from './Authen.props';
import {Image} from '../../components/atoms/Image';
import {Images} from '../../configs/images';
import {Text} from '../../components/atoms/Text';
import {Button} from '../../components/atoms/Button';

export const Authen: React.FC<AuthenProps> = () => {

  const login = (resource_type='company_admin') => {
    var client_id = process.env.REACT_APP_PH2_CLIENT_ID; //appConfig.PH2Authorize.clientId;
    const originLocation = window.location.origin;
    var redirect_uri = originLocation + '/callback?';
    var url =
      process.env.REACT_APP_PH2_OAUTH//appConfig.PH2Authorize.oauth
      + '?response_type=id_token token'
      + '&scope=openid profile email'
      + '&nonce=abc'
      + '&client_id=' + client_id
      + '&redirect_uri=' + redirect_uri
      + '&resource_type=' + resource_type;
    // Redirect the user to Salesforce
    window.location.replace(url);
  }

  const isLoggedIn = localStorage.getItem('isLoggedIn');
  localStorage.removeItem('isLoggedIn');
  if(isLoggedIn) {
    window.location.href = '/';
  }
  return (
    <div className='page-login'>
      <Image srcImg={Images.Logo} />
      <div className='login-content'>
        <div className='welcom'>
          <Text children='Welcome to ' color='#193051' fontSize={36}/>
          <Text children='Phriendly Phish Focus Centre' color='#193051' fontSize={36} fontWeight={700}/>
        </div>
        <div>
          <Text cssClass={'login-text'} color='#345C8B' children='Log in by' fontWeight={500} fontSize={20} margin={'70px 0 20px'}/>
          <Button color={'#fff'} onClick={() => login('company_admin')} height={40} title='Company Admin Account'></Button>
          <Button color={'#fff'} onClick={() => login('admin')} height={40} title='Global Admin Account'></Button>
        </div>
      </div>
    </div>
  );
};





