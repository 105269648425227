import React, {useState} from 'react'
import {ChipGroupProps} from '../ChipGroup'
import './ChipGroup.styles.css';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import {Clear, Edit, Visibility, Send, Error} from "@mui/icons-material";
import {Tooltip} from "@mui/material";

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const ChipGroup: React.FC<ChipGroupProps> = ({
  initDomainList,
  defaultDomain,
  noneVerifiedSender,
  handleEdit,
  handleSendTest,
  confirmDelete
}) => {
  const chipEl = (isDefault:any, data:any, isNoneVerified:any) => {
    const actionButton = isNoneVerified ? <>
          <Tooltip placement={"top"} title={'Verify'}>
            <Visibility data-testid={'chipHandleEdit'} sx={{cursor: "pointer", width: "20px", display: "none"}} onClick={() => {
              handleEdit(data)
            }}/>
          </Tooltip>
    </>:<>
          <Tooltip placement={"top"} title={'Send test email'}>
            <Send data-testid={"chipHandleSendEmail"} sx={{cursor: "pointer", width: "20px", display: "none"}} onClick={() => {
              handleSendTest(data)
            }}/>
          </Tooltip>
    </>
    return (
      <Chip
        sx={{
          padding: '16px 10px',
          backgroundColor: '#E7EBF0',
        }}
        className={`${isDefault ? "domain-default" : isNoneVerified ? "sender-none-verified" : ""}`}
        itemID={data.value}
        label={data.value}
        icon={isDefault ? <></>: isNoneVerified ? <Tooltip placement={"top"} title={'This email address has not been verified or the verification failed.'}><Error style={{"color":'#193051'}} /></Tooltip> : <></>}
        deleteIcon={<>
          {actionButton}
          <Tooltip placement={"top"} title={'Delete'}>
            <Clear data-testid={"chipHandleDelete"} sx={{cursor: "pointer", width: "17px", display: "none"}} onClick={() => {
              confirmDelete(data)
            }}/>
          </Tooltip></>}
        onDelete={isDefault ? undefined : () => {
          confirmDelete(data)
        }}
      />
    )
  }

  return (
    <Paper
      id={'chip-group'}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        boxShadow: 'unset',
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
        padding: '10px',
      }}
      component="ul"
    >
      {initDomainList.map((data:any) => {
        const isDefault = defaultDomain.find((domain:any) => data.value === domain)
        const isNoneVerified = noneVerifiedSender.find((domain:any) => data.value === domain)
        return (
          <ListItem key={data.mail_sender_id} className={`${isDefault ? "domain-default" : ""}`}>
            {isDefault ? <Tooltip placement={"top"}
                      title={'This is the default email from Phriendly Phishing. Cannot Edit or Delete.'}>
              { chipEl(isDefault, data, isNoneVerified) }
            </Tooltip> : chipEl(isDefault, data, isNoneVerified)}
          </ListItem>
        );
      })}
    </Paper>
  );
}
