import React, {useState} from 'react';
import {BlockListEntryProps} from "./BlockListEntry.props";
import './BlockListEntry.styles.css';
import {
    CancelRounded,
    Done,
    InsertDriveFile,
    Person,
    RemoveCircle
} from "@mui/icons-material";
import {Checkbox, Chip, styled, Tooltip, Typography} from "@mui/material";
import {ReactComponent as LinkIcon} from '../../../assets/images/link_icon.svg';

const StyledCheckbox = styled(Checkbox)`
      &.Mui-checked {
        color: #345C8B;
      }
    `;

export const BlockListEntry: React.FC<BlockListEntryProps> = (
    {
        checked = false,
        attrType = '',
        value = '',
        expireCode = 0,
        onChange = ()=>{},
        onExpireDateChanged = () =>{},
        enable=true,
        status='valid'
    }
) => {

    const onChangeExpireDate = (code:any) => {
      onExpireDateChanged(code)
    }

    return (
        enable ?
        <div className={'blocklist-entry-item ' + (checked ? 'selected' : '') }>
            <div className={"blocklist-entry-start"}>
                <div className={"entry-checkbox"}>
                    <StyledCheckbox checked={checked}  onChange={onChange} />
                </div>
                <div className={"entry-icon"}>{
                    attrType === 'sender' ? <Person /> : attrType === 'url' ? <LinkIcon /> : <InsertDriveFile />
                }</div>
            </div>
            <div className={"blocklist-entry-end"}>
                <table className={"blocklist-table"}>
                    <tr>
                        <td>
                            <Tooltip className={""} placement={"top"} title={value}>
                            <div className={"entry-value"}>
                                {value}
                            </div>
                            </Tooltip>
                        </td>
                        {
                            checked && <td className={"blocklist-table-expire-cell"}>
                                <div className={"entry-expire-date"}>
                                    <div className={"entry-expire-date-content"}>
                                        <div><Typography className={"label-small"}>Expiration Date:</Typography></div>
                                        <Chip className={"blocklist-chip " + (expireCode==='0' ? 'selected': '')}
                                              label="Never"
                                              variant="outlined"
                                              deleteIcon={expireCode===0 ? <Done style={{color: '#000000'}}/> : <></>}
                                              onClick={() => { onChangeExpireDate(0)} }
                                              onDelete={() => {}}
                                        />
                                        <Chip className={"blocklist-chip " + (expireCode===7 ? 'selected': '')}
                                              label="7 days"
                                              variant="outlined"
                                              deleteIcon={expireCode===7 ? <Done style={{color: '#000000'}}/> : <></>}
                                              onClick={() => { onChangeExpireDate(7)} }
                                              onDelete={() => {}}
                                        />
                                        <Chip className={"blocklist-chip " + (expireCode===30 ? 'selected': '')}
                                              label="30 days"
                                              variant="outlined"
                                              deleteIcon={expireCode===30 ? <Done style={{color: '#000000'}}/> : <></>}
                                              onClick={() => { onChangeExpireDate(30)} }
                                              onDelete={() => {}}
                                        />
                                        {/*<Chip className={"blocklist-chip " + (expireCode===1 ? 'selected': '')}*/}
                                        {/*      label="1 day"*/}
                                        {/*      variant="outlined"*/}
                                        {/*      deleteIcon={expireCode===1 ? <Done style={{color: '#000000'}}/> : <></>}*/}
                                        {/*      onClick={() => { onChangeExpireDate(1)} }*/}
                                        {/*      onDelete={() => {}}*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                            </td>
                        }
                </tr></table>
            </div>

        </div>:<div className={'blocklist-entry-item disabled ' }>
                <div className={"blocklist-entry-start"}>
                    <div className={"entry-icon"}>{
                        attrType === 'sender' ? <Person /> : attrType === 'url' ? <LinkIcon /> : <InsertDriveFile />
                    }</div>
                </div>
                <div className={"blocklist-entry-end"}>
                    <table className={"blocklist-table"}>
                        <tr>
                            <td>
                                <Tooltip className={""} placement={"top"} title={value}>
                                    <div className={"entry-value"}>
                                        {value}
                                    </div>
                                </Tooltip>
                            </td>
                            <td className={"blocklist-table-invalid-cell"}>
                                {
                                    status === 'error' || status === 'invalid' ?
                                        <div
                                            className={"blocklist-entry-status error"}>
                                            <CancelRounded />
                                            <span>Error</span>
                                        </div> :
                                    status === 'existing' ?
                                        <div
                                            className={"blocklist-entry-status existing"}>
                                            <RemoveCircle />
                                            <span>Existing</span>
                                        </div> : null

                                }
                            </td>
                        </tr></table>
                </div>
        </div>
    )
}
