import React, {useEffect} from 'react'
import {DonutChartProps} from './DonutChart.props'
import './DonutChart.styles.css';
import ReactApexChart from "react-apexcharts";
import {NoDataCard} from "../NoDataCard";

export const DonutChart: React.FC<DonutChartProps> = ({monthsData, cardName}) => {
  let series = monthsData && monthsData.data
  // let series = [20,30,50]
  let totalCount = 0
  series.forEach((element:any) => totalCount += element)
  const options = {
    tooltips: {enabled: false},
    dataLabels: {enabled: false},
    tooltip: {enabled: false},
    labels: ['Automatically Resolved', 'Manually Resolved', 'Unresolved'],
    legend: {
      show: true,
      position: 'bottom',
      customLegendItems: ['Automatically', 'Manually', 'Unresolved'],
      fontSize: '11px',
      fontFamily: 'Montserrat, Helvetica, Arial',
      fontWeight: 400,
      color: '#808888',
      markers: {
        width: 14,
        height: 14,
        radius: 14,
      } ,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        }
      },
    },
    chart: {
      height: '200px',
      width: '200px',
    },
    stroke: {show: false,},
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: '80%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '12px',
              fontFamily: 'Montserrat',
              fontWeight:  700,
              color: '#808888',
              offsetY: 30,
              formatter:/* istanbul ignore next */(val:any) => {
                return val
              }
            },
            value: {
              show: true,
              fontSize: '45px',
              fontFamily: 'Montserrat',
              fontWeight: 400,
              color: '#808888',
              offsetY: -10,
              formatter:/* istanbul ignore next */(val:any) => {
                return Math.round(val * 100 / totalCount) + '%'
              }
            },
            total: {
              show: true,
              label: 'Automatically Resolved',
              fontSize: '10px',
              fontFamily: 'Montserrat',
              fontWeight: 700,
              color: '#808888',
              offsetY: -10,
              formatter:/* istanbul ignore next */(val:any) => {
                return Math.round(val.config.series[0] * 100 / totalCount) + '%'
              }
            },
          }
        }
      },
    },
    colors: ['#BDE541', '#3C79D0', '#DDDDDD'],
    fill: {
      colors: ['#BDE541', '#3C79D0', '#DDDDDD'],
      opacity: 1,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
      }
    }]
  };

  return (
    //@ts-ignores
    (Math.max(...series) > 0) ? <ReactApexChart
        type="donut"
        //@ts-ignores
        options={options}
        series={series}
        height={276}
        width={290}
    /> : <NoDataCard type={'donut'}></NoDataCard>
  );
}
