import React, {useContext, useEffect, useState} from 'react';
import  { useNavigate, useLocation } from 'react-router-dom';
import {LeftNavButtonProps} from './LeftNavButton.props';
import './LeftNavButton.styles.css';
import {Button} from "../../atoms/Button";
import {Image} from "../../atoms/Image";
import {Images} from "../../../configs/images";
import {Text} from "../../atoms/Text"

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {isMobileOnly} from "react-device-detect";
import {GlobalContext} from "../../../context/GlobalState";
import ChangeWithZone from "../../../hoc/ChangeWithZone";
import {AccordionButton} from "../AccordionButton";
import {getSettings} from "../../../api/emailTriage";
import {defaultSettings, useRoleContext} from "../../../context/RoleContext";


function findById(array:any, id:any) {
  for (const item of array) {
    if (item.id === id) return item;
    if (item.children?.length) {
      const innerResult:any = findById(item.children, id);
      if (innerResult) return innerResult
    }
  }
}

const LeftNavButtonRoot: React.FC<LeftNavButtonProps> = ({
  leftNavBtnArr,
  isOpen = false,
  setOpenNav,
  setSettingStatus,
  setLoadingPage
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  let { globalState } = useContext(GlobalContext);

  let [zoneTree, setZoneTree] = useState(globalState.zones || [])
  let [zoneName, setZoneName] = useState('')
  const { setting, setSetting } = useRoleContext()

  useEffect(() => {
    const oldZoneId = sessionStorage.getItem('currentZone') || localStorage.getItem('currentZone') || '0'
    let currentTree = findById(zoneTree, parseInt(oldZoneId))
    if (currentTree) {
      setZoneName(currentTree.name)
    } else {
      setZoneName("")
    }
    setLoadingPage(true)
    getSettings().then((response) => {
      const newSettings = { ...defaultSettings };
      response.data.forEach((item: any) =>
        Object.assign(newSettings, { [item.setting_type]: item.setting_value })
      );
      setSetting(response.data.length > 0 ? newSettings : defaultSettings);
      setLoadingPage(false)
    }).catch((e)=>console.log(e))

  }, [zoneTree])


  const btnNav = (btn:any, index: number, hasTitle: boolean, isOpen: boolean, hasChild?: boolean, icon?:any) =>
    <div key={index} className={'nav-bg'}>
      <Button
        key={index}
        margin='0 0 4px 12px'
        color='#677070'
        variant={'left-nav-btn'}
        padding={'0 11px'}
        border='none' backgroundColor='transparent'
        onClick={(e:any) => {
            e.preventDefault();
            navigate(btn.href)
            if (setOpenNav && isMobileOnly) {
              setOpenNav()
            }
          }
        }
        component={hasChild ? null : icon}
        title={btn.name}
        actived={hanldeActiveBtn(btn.href, location.pathname)}
        borderRadius='4px'
        height={40}
        titleMargin={hasChild ? '0 0 0 36px' : '0 0 0 14px'}
        width={hasTitle && !isOpen ? 'calc(100% - 12px)': 'calc(100% - 24px)'}
        // tooltipComponent = {
        //   isMobileOnly ? "" :  isOpen && <div className='tooltip'>
        //     <div className='tooltip-inner'>{btn.name}</div>
        //     <span className='arrow'></span>
        //   </div>
        // }
      />

    </div>
  const hanldeActiveBtn = (path:any, location:any) => {
    // console.log(path, location, isArchivedEmail, location.indexOf(path))
    if (location === '/' && path === '/dashboard') {
      return true
    }  else if(location !== '/') {
      return location.indexOf(path) > -1
    }
  }

  const activedLeftMenu:any = location.pathname
  const currentYear = new Date().getFullYear()

  return (
      <div className={`left-nav fixed ${isOpen? 'opened' : ''}`}>
        <div className={"left-nav-content"}>
          <Image srcImg={Images.AvatarLogo}
                 height={40}
                 width={40}
                 borderRadius={'50%'}
          />
          <Text children={zoneName}
                margin={"0 0 0 12px"}
                color={"#193051"}
                fontWeight={700}
          />
          { isMobileOnly ? <IconButton
            aria-label="close"
            onClick={setOpenNav}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: '#000'
            }}
          >
            <CloseIcon />
          </IconButton> : null}
        </div>
        <div className="button-section-group ">
          {
            leftNavBtnArr?.map(( btn:any, index) =>
              <div key={index}>
                {btn.child ? <AccordionButton idComponent={'left-menu'}
                                              title={btn} icon={btn.icon}
                                              actived={activedLeftMenu.includes(btn.href) || (activedLeftMenu.includes('/archived') && btn.href === '/inbox' )}
                                              children={btn.child.map((btnChild:any, indexChild:any) => btnNav(btnChild, indexChild, true, isOpen, true, btn.icon))}/>
                  : btnNav(btn, index, true, isOpen, false, btn.icon)}
              </div>
            )
          }
        </div>
        <div className={`left-nav-bottom ${isOpen ? 'opened' : ''} `}>
          <div className='company-logo'>
            <Image srcImg={Images.LogoOnly} width={24} height={26} margin='0 0 0 16px'/>
            <Image cssClass='dark-logo-text' srcImg={Images.DarkLogo} height={28} width={64} margin='0 0 0 6px'/>
          </div>
          {isMobileOnly ? <div className='company-name'>
            <span style={{color : "#011111"}} className='copyright-sign'>&#169; {currentYear} Phriendly Phish Focus Centre</span>
          </div> : null}
        </div>
      </div>
  );
};

export const LeftNavButton = ChangeWithZone(LeftNavButtonRoot);
