import React from 'react'
import {MiddleDonutCardProps} from './MiddleDonutCard.props'
import './MiddleDonutCard.styles.css';

import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {DonutChart} from "../DonutChart";
import Skeleton from "@mui/material/Skeleton";


export const MiddleDonutCard: React.FC<MiddleDonutCardProps> = ({
   isLoading,
  data
}
) => {
    // check loading first times
    let isLoadingDonutCard;
    if(data !== null){
        isLoadingDonutCard = false;
    } else {
        isLoadingDonutCard = true;
    }
  return (
    <Grid sx={{
      width: '100%',
      height: 315,
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      padding: '15px 23px',
      boxSizing: 'border-box'
    }}
          container
    >
      <Box>
        <Typography
          align={'left'}
          sx={{
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '24px',
            color: '#808888',
            minWidth: 270
          }}
        >
          {(isLoading || isLoadingDonutCard)? <Skeleton variant="text" sx={{ fontSize: '20px' }} width={200} /> : "Reported Messages"}
    
        </Typography>
    
        <Typography
          align={'left'}
          sx={{
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '15px',
            color: '#4D5858',
          }}
        >
          {(isLoading || isLoadingDonutCard) ? <Skeleton variant="text" sx={{ fontSize: '20px' }} width={200} /> : data && "Breakdown    | " + data['currentMonth'] }
        </Typography>
      </Box>
      <Stack
        sx={{
          width: '100%'
        }}
        justifyContent="center"
        alignItems="center"
      >
        {(isLoading || isLoadingDonutCard) ? <Skeleton variant="circular" width={200} height={200} /> : data && <DonutChart
            monthsData={data}
        />}
      </Stack>
  
    </Grid>
  );
}
