import React, {useEffect, useState} from 'react'
import {SearchMenuBarProps} from './SearchMenuBar.props'
import './SearchMenuBar.styles.css';
import {Images} from '../../../configs/images';
import {SearchFilter} from '../../molecules/SearchFilter';
import {isMobileOnly} from "react-device-detect";
import {Button} from '../../atoms/Button';
import {Image} from '../../atoms/Image';
import {DropDownFilter} from "../../molecules/DropDownFilter";
import moment from "moment";

const arrayStatus:any = [
  {id: 1, content: 'In Review'},
  {id: 2, content: 'Resolved'},
  {id: 3, content: 'Unknown'},
];

const arrayPriority:any = [
  {id: 1, content: 'High'},
  {id: 2, content: 'Medium'},
  {id: 3, content: 'Low'},
];

const arrayCategory:any = [
  {id: 1, content: 'Threat'},
  {id: 3, content: 'Clean'},
  {id: 4, content: 'Unknown'},
];

export const SearchMenuBar: React.FC<SearchMenuBarProps> = ({
  onFilter,
  handleEnterSearch,
  selectedItems,
  resetFilter,
  searchInputValue,
  setSearchInputValue,
  onClearFilter,
}) => {
  const sDate = moment().utc().subtract(1, 'months').format('MM/DD/YYYY');
  const eDate = moment().utc().format('MM/DD/YYYY');

  const arrayStatus:any = [
    {id: 1, content: 'In Review'},
    {id: 2, content: 'Resolved'},
    {id: 3, content: 'Unknown'},
  ];

  const arrayPriority:any = [
    {id: 1, content: 'High'},
    {id: 2, content: 'Medium'},
    {id: 3, content: 'Low'},
    {id: 4, content: 'Unknown'},
  ];

  const arrayCategory:any = [
    {id: 1, content: 'Threat'},
    {id: 3, content: 'Clean'},
    {id: 4, content: 'Unknown'},
  ];

  const arrayThreatAnalysis:any = [
    {id: 1, content: 'Threat detected'},
    {id: 2, content: 'Undetermined'},
    {id: 3, content: 'No threat'},
    {id: 4, content: 'Analysing'}
  ];

  const [arrStatus, setArrStatus] = useState<any>(arrayStatus)
  const [arrPriority, setArrPriority] = useState<any>(arrayPriority)
  const [arrCategory, setArrCategory] = useState<any>(arrayCategory)
  const [arrThreatAnalysis, setArrThreatAnalysis] = useState<any>(arrayThreatAnalysis)
  const [isShowFilter, SetShowFilter] = useState<boolean>(false)

  const [isOpen, setIsOpen] = useState<any>({category: null, priority: null, threat_analysis: null})
  const url = window.location.pathname

  const onSearch = (searchText: string, array:any, type:string) => {
    let filteredOptions =
      array.filter((item:any) => {
        if(searchText.length !== 0){
          return (item.content.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
        } else {
          return true
        }
      });
    if (type === 'status'){
      setArrStatus(filteredOptions)
    } else if (type === 'priority'){
      setArrPriority(filteredOptions)
    } else if (type === 'category') {
      setArrCategory(filteredOptions)
    } else if (type === 'threat_analysis'){
      setArrThreatAnalysis(filteredOptions)
    }
  }

  const filterSelection = (item:any, type:any) => onFilter(item, type);

  // use effect close dropdown filter
  useEffect(() => {
    setIsOpen({category: null, priority: null, threat_analysis: null})
  }, [url])

  return <div className={'search-menu'}>
    <SearchFilter
      height={38}
      margin={'0'}
      widthTextInput={180}
      titleInput={'Search'}
      iconSrc={Images.SearchIcon}
      borderRadius={'4px'}
      handleKeyDownEvent={handleEnterSearch}
      inputValue={searchInputValue}
      setInputValue={setSearchInputValue}
    />
    {
      isMobileOnly && <div data-testid={'more-filter'} className={'more-filter ' + (isShowFilter ? 'hide-icon' : '')} onClick={()=>SetShowFilter(!isShowFilter)}><Image srcImg={Images.FilterIcon} width={18}/></div>
    }

    <div className={'search-menu-right ' + (isMobileOnly && 'mobile-popup ') + (isShowFilter ? 'show-filter ': 'hide-filter')}>
      {isMobileOnly && <div className='filter-title-mb'>Filter <Image onClick={()=>SetShowFilter(false)} srcImg={Images.CloseGray} width={18}/></div>}
      <DropDownFilter isOpen={isOpen} setIsOpen={setIsOpen} clearFilerSelection={onClearFilter} onSelectItem={filterSelection} selectedItems={selectedItems} label={'Priority'} type={'priority'} arrList={arrPriority} setSearchQuery={(e:any) => onSearch(e, arrayPriority, 'priority')} width={95}/>
      <DropDownFilter isOpen={isOpen} setIsOpen={setIsOpen} clearFilerSelection={onClearFilter} onSelectItem={filterSelection} selectedItems={selectedItems} label={'Category'} type={'category'} arrList={arrCategory} setSearchQuery={(e:any) => onSearch(e, arrayCategory, 'category')} width={107}/>
      <DropDownFilter isOpen={isOpen} setIsOpen={setIsOpen} clearFilerSelection={onClearFilter} onSelectItem={filterSelection} selectedItems={selectedItems} label={'Threat Analysis'} type={'threat_analysis'} arrList={arrThreatAnalysis} setSearchQuery={(e:any) => onSearch(e, arrayThreatAnalysis, 'threat_analysis')} width={150}/>
      <div className={isMobileOnly ? 'bottom-buttons-popup': ''}>
        <Button title={'Reset'}
                backgroundColor={'transparent'}
                variant={'reset-btn'}
                width={44} height={24}
                fontSize={16}
                fontWeight={'600'}
                color={'#467599'}
                margin={'0 12px 0 0'}
                onClick={() => resetFilter()}
        />
        {isMobileOnly && <Button cssClass={'btn-done'} title='Done' onClick={()=>SetShowFilter(false)} width={89} backgroundColor={'#345C8B'}/>}
      </div>
    </div>
  </div>
}
