import React from 'react';
// import {Colors} from '../../../configs/colors';
import {LinkProps} from './Link.props';
import './Link.styles.css';
import {useNavigate} from "react-router-dom";
export const Link: React.FC<LinkProps> = ({
  onClick,
  children,
  text,
  /* istanbul ignore next */
  url='',
  /* istanbul ignore next */
  color = '',
  fontSize,
  cssClass
}) => {
  return (<a href={url} className={"link-style " + cssClass} style={{color,fontSize}} onClick={onClick}>{text}{children}</a>);
};
