import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { Cancel, Close } from '@mui/icons-material';

import { MessageProps } from '.';
import './Message.styles.css';

const Message = ({ title, content, showMessage, onConfirm, icon, confirmColor }: MessageProps) => (
  <div style={{ maxWidth: '560px' }}>
    <Dialog
      open={showMessage}
      className={'message-dialog'}
    >
      <DialogTitle id="message-title">
        {icon}
        <span style={{ fontSize: '20px', fontWeight: 700, color: '#212121' }}>{title}</span>
        <IconButton
          aria-label="close"
          onClick={onConfirm}
          sx={{
            position: 'absolute',
            right: 18,
            top: 18,
            fontSize: 24,
          }}
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '0 24px 10px 24px', textAlign: 'center' }}>
        <DialogContentText id="leave-page-description" sx={{ fontSize: '16px', fontWeight: 500, color: '#212121' }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 24px 24px' }}>
        <Button onClick={onConfirm} variant="contained" size={'medium'} style={{ backgroundColor: confirmColor ?? '#345C8B', width: 69, height: 40 }}>
          <Typography sx={{
            fontSize: '14px',
            fontFamily: 'Metropolis',
            fontStyle: 'normal',
            fontWeight: 600,
          }}>
            OK
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

export default Message;
