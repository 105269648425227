import React from 'react'
import {LegendChartProps} from './LegendChart.props'
import './LegendChart.styles.css';

import Box from '@mui/material/Box';
import {Typography} from "@mui/material";
import Stack from '@mui/material/Stack';

let legendArr = [
  {
    bgColor: '#0E4F83',
    name: 'Threat'
  },
  {
    bgColor: '#61BBD8',
    name: 'Unknown'
  },
  {
    bgColor: '#BDE541',
    name: 'Clean'
  },
]

const legendArr2 = [
  {
    bgColor: '#0E4F83',
    name: 'Received'
  },
  {
    bgColor: '#3C79D0',
    name: 'In Review'
  },
  {
    bgColor: '#BDE541',
    name: 'Resolved '
  },
]

export const LegendChart: React.FC<LegendChartProps> = ({number}) => {
  let legendArrTemp =  Object.assign([], legendArr)
  if (number===2) {
    Object.assign(legendArrTemp, legendArr2)
  }

  return (
    <Box sx={{
      display: 'flex',
      height: '14px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginBottom: '6px;'
    }}>
      {legendArrTemp.map((element:any) => {
        return <Stack key={element.name} direction="row" alignItems={'center'}>
          <Box
            sx={{
              borderRadius: '50%',
              width: 14,
              height: 14,
              backgroundColor: element.bgColor,
              margin: '0 8px'
            }}
          >
          </Box>
          <Typography
            align={'center'}
            sx={{
              fontWeight: 400,
              fontSize: '11px',
              lineHeight: '13px',
              color: '#808888',
              margin: '0 px'
            }}
          >
            {element.name}
          </Typography>
        </Stack>
      })}
    </Box>
  )

}
