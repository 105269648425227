import React, {useRef, useEffect} from 'react';

import { AnalysisBoxItemProps } from './AnalysisBoxItem.props';
import './AnalysisBoxItem.styles.css'
import Box from "@mui/material/Box";
import {RadialGraph} from "../../atoms/RadialGraph";
import {Typography, Tooltip} from "@mui/material";

export const AnalysisBoxItem: React.FC<AnalysisBoxItemProps>= ({
    payload = {},
    index=0
}) => {
    const entity_score:number = payload.score;
    const entity_value:string = payload.value;
    const entity_message:string = payload.message;
    const entity_type:string = payload.message;

    return <Box className={'analysis-box-item'}>
        <div className={'analysis-box-top'}>
            <div className={'analysis-box-start'}>
                <div className={'analysis-box-item-no'}>
                    {index}
                </div>
                <div className={'analysis-box-item-value'}>
                    <Tooltip placement={"top"} title={entity_value}>
                        <div>{entity_value}</div>
                    </Tooltip>
                </div>
                <div style={{paddingLeft: '30px'}}>
                {payload.content}
                </div>
            </div>
        </div>
        <div>
            <div className={'analysis-box-bottom'}>
                <Typography variant={'body2'}  className={'app-label analysis-box-item-message'}>
                    {entity_message}
                </Typography>
            </div>
        </div>
        <div className={'analysis-box-item-score'}>
            <RadialGraph chartLayoutPosition={{left: 20}} chartStyle={{stroke: 'unset'}} isToolTipHover={true} value={entity_score}/>
        </div>
        <div className={'under-line'}></div>
    </Box>;
};
