import React, {useState, useEffect} from "react";
import {BlockListActivityLogsProps} from "./BlockListActivityLogs.props";

import {PageHeader} from "../../components/organisms/PageHeader";
import "./BlockListActivityLogs.styles.css";
import ChangeWithZone from "../../hoc/ChangeWithZone";
import {
    BLOCK_LIST_ACTIVITY_LOGS,
    BLOCK_LIST_ACTIVITY_LOGS_ACTION_TYPE, BLOCK_LIST_API_ACTIONS, SETTING_BLOCK_LIST, STATUS_CODE
} from "../../constants/contants";
import {Box, Button, Tooltip, Typography, Stack, IconButton} from "@mui/material";
import {SearchFilter} from "../../components/molecules/SearchFilter";
import {Images} from "../../configs/images";
import {DropDownFilter} from "../../components/molecules/DropDownFilter";
import {DataTable} from "../../components/molecules/DataTable";
import {GridColDef} from "@mui/x-data-grid";
import moment from "moment-timezone";
import {downloadBlockListActivityLogs, getBlockListActivityLogs, getBlockListsSetting} from "../../api/emailTriage";
import {noUserDataRedirect} from "../../utils/common";
import DownloadIcon from "@mui/icons-material/Download";
import {Pagination} from "../../components/atoms/Pagination";
import {LoadingPage} from "../../components/organisms/LoadingPage";
import {useRoleContext} from "../../context/RoleContext";

const blockListDetailLinks = [
    {
        name: "Block List",
        href: "/block-list",
    },
    {
        name: "Activity Logs",
    },
];

export const BlockListActivityLogsFull: React.FC<BlockListActivityLogsProps> = () => {
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [startPoint, setStartPoint] = useState(0);
    const [endPoint, setEndPoint] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0)
    const [activityLogsData, setActivityLogsData] = useState([]);
    const [filterSearchKeyword, setFilterSearchKeyword] = useState('');
    const initialFilter = {filterData: { search_text: '', action_type: []}}
    const [activityLogsFilter, setActivityLogsFilter] = useState<any>(initialFilter)
    const defaultSortDirection: string = 'desc';
    const [sortDirection, setSortDirection] = useState<any>(defaultSortDirection);
    const [sortBy, setSortBy] = useState<any>('date_modify');
    const [isOpenAttribute, setIsOpenAttribute] = useState<any>({action_type: null})
    const [selectedItemsDropDown, setSelectedItemsDropDown] = useState<any>({});
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [loadingPage, setLoadingPage] = useState(false);
    const [timer, setTimer] = useState<any>(null);
    const [blocklistTenantId, setBlocklistTenantId] = useState<any>(null)

    const url = window.location.pathname
    let tz_name = 'Etc/UTC';
    const localStorageUserData = localStorage.getItem('userData');
    const { setting } = useRoleContext()

    if(localStorageUserData) {
        const parseUserData = JSON.parse(localStorageUserData)
        tz_name = parseUserData["tz_name"]
    }


    useEffect(() => {
        if (!setting[SETTING_BLOCK_LIST]) {
            return
        }
        getBlockListsSetting({
            service: 'BLOCK_LIST',
            user: 'pre',
        }).then((response)=>{
            setBlocklistTenantId(response.data.tenant)
            if(!response.data.tenant) {
                setIsTableLoading(false)
            }
        }).catch((error) => {
        })
    }, [])

    useEffect(() => {
        loadDataTable()
    }, [blocklistTenantId])

    useEffect(() => {
        setIsOpenAttribute({action_type: null})
    }, [url])

    const loadDataTable = () => {
        const userData = localStorage.getItem('userData');
        if (userData === null) {
            noUserDataRedirect()
        } else {
            getActivityLogsRecords(activityLogsFilter);
        }
    }

    useEffect(() => {
        if(!blocklistTenantId) {
            return
        } else {
            loadDataTable()
        }
    }, [activityLogsFilter, page, perPage, sortDirection])

    useEffect(() => {
        handleSetFilterDropDown()
    }, [selectedItemsDropDown])

    const actionTypeData:any = [
        {id: 1, content: 'Deleted'},
        {id: 2, content: 'Edited'},
        {id: 3, content: 'Expired'},
        {id: 4, content: 'Created'},
    ]

    const dropDownFilterArr:any = [
        {
            label: 'Action Type',
            type: 'action_type',
            listArr: actionTypeData
        },
    ]

    const handleDropDownSelected = (item: any, type: any) => {
        setSelectedItemsDropDown((prev: any) => {
            return ({
                ...prev,
                [type]: item,
            });
        });
    };

    const handleClearDropDownSelected = (type: any) => {
        setSelectedItemsDropDown((prev:any) => {
            return ({
                ...prev,
                [type]: [],
            })
        });
    };

    const handleMakeKeyDropDownFilter = (value: string) => {
        return value === 'Edited' ? 'reset_expired' : value.toLowerCase().trim()
    }

    const handleSetFilter = (event: any = null) => {
        setPage(1)
        setActivityLogsFilter((prev:any) => {
            // the event is null when acting in dropdown filter
            // The event is not null when the Enter key is pressed in the search input.
            let search_text = prev.filterData.search_text
            let action_type_arr = prev.filterData.action_type

            if (event) {
                search_text = event.target.value.trim()
            }else {
                action_type_arr = selectedItemsDropDown.action_type ? selectedItemsDropDown.action_type.map((value: string) => handleMakeKeyDropDownFilter(value)) : action_type_arr
            }

            if (search_text !== '' || action_type_arr.length > 0 ) {
                return ({
                    ...prev,
                    filterData: {
                        action_type: action_type_arr,
                        search_text: search_text,
                    }
                })
            } else {
                return initialFilter
            }
        });
    }

    const getActivityLogsRecords = (params: any) => {
        if(!blocklistTenantId) {
            return
        }
        setIsTableLoading(true);

        params["page"] = page;
        params["sort_dir"] = sortDirection;
        params["per_page"] = perPage;
        params["sort_by"] = sortBy;
        params["tenant_id"] = blocklistTenantId || "0";
        params["action"] = BLOCK_LIST_API_ACTIONS.GET_BLOCK_LIST_ACT_LOGS;

        getBlockListActivityLogs(params).then(res =>{
            if(res && res.status === STATUS_CODE.OK){
                setActivityLogsData(res.data.list.map((entry: any, index: number) => {
                    const { ca_timestamp, message, name, created_by, action_type } = entry;
                    return {
                        id: index.toString(),
                        date_modify: ca_timestamp,
                        value: message,
                        done_by: {
                            name,
                            created_by,
                        },
                        action_type: action_type,
                    };
                }));
                setTotalRecords(res.data.totalItems);
                setStartPoint(res.data.startItemsPerPage);
                setEndPoint(res.data.endItemsPerPage);
                setMaxPage(res.data.maxPage);
                setIsTableLoading(false);
            }
        }).catch((e) => {
            console.log(e)
        })
    };

    const handleResetFilter = () => {
        setPage(1)
        setActivityLogsFilter(initialFilter)
        setFilterSearchKeyword('')
        setSelectedItemsDropDown({})
    }

    const handleSetFilterDropDown = () => {
        // Handle when changing selected item in filter dropdowns
        handleSetFilter()
    }

    const handleEnterSearchInput = (event:any) => {
        // Handle when Enter on the search input
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        return new Promise((resolve) => {
            const timers = setTimeout(async () => {
                if (event.keyCode === 13) {
                    handleSetFilter(event)
                }
            }, 300);
            setTimer(timers);
        });
    }

    const createDateFromTimeStamp = (timeStamp: number) => {
        return new Date(timeStamp*1000)
    }

    const columns: GridColDef[] = [
        {field: 'date_modify', headerName: 'Date Modified', width: 200, sortable: true,
            renderCell: (row:any) => {
                const dateModify = createDateFromTimeStamp(parseInt(row.formattedValue))
                return (<div>
                    <span>{moment.utc(dateModify, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('DD/MM/YYYY')} </span>
                    <span>{moment.utc(dateModify, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('hh:mm A')}</span>
                </div>)
            }
        },
        {field: 'value', headerName: 'Value', minWidth: 800, sortable: true,
            renderCell: (row) => {
                return <Tooltip placement={"top"}
                                title={row.formattedValue}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            maxWidth: "450px",
                                        },
                                    },
                                }}
                >
                    <span className={'MuiDataGrid-cellContent'}>{row.formattedValue}</span>
                </Tooltip>
            }
        },
        {field: 'action_type', headerName: 'Action Type',  width: 250, sortable: true,
            renderCell: (row:any) => {
                switch (row.value) {
                    case BLOCK_LIST_ACTIVITY_LOGS_ACTION_TYPE.DELETED:
                        return <span>Deleted</span>
                    case BLOCK_LIST_ACTIVITY_LOGS_ACTION_TYPE.CREATED:
                        return <span>Created</span>
                    case BLOCK_LIST_ACTIVITY_LOGS_ACTION_TYPE.RESET_EXPIRED:
                        return <span>Edited</span>
                    case BLOCK_LIST_ACTIVITY_LOGS_ACTION_TYPE.EXPIRED:
                        return <span>Expired</span>
                }
            }
        },

        {field: 'done_by', headerName: 'Done By', width: 300, sortable: false,
            renderCell: (rowData: any) => (
                <div>
                    <strong>{rowData.formattedValue.name}</strong><br/>
                    <Typography>{rowData.formattedValue.created_by}</Typography>
                </div>
            ),
        },
    ];

    const downloadHandler = () => {
        setLoadingPage(true)
        downloadBlockListActivityLogs({
            action: BLOCK_LIST_API_ACTIONS.DOWNLOAD_BLOCK_LIST_ACT_LOGS,
            tenant_id: blocklistTenantId
        }).then((response:any) => {
            if (response["data"]["presigned_url"] !== ''){
                window.location.href= response["data"]["presigned_url"]
                setLoadingPage(false)
            }
        }).catch((error:any) => {
            setLoadingPage(false)
            console.log("download error", error)
        });
    };

    const totalRows = activityLogsData.length;
    let toolbar = null
    if (totalRows > 0){
        toolbar = () => (
            <Stack direction={'row-reverse'}>
                <IconButton onClick={() => downloadHandler()} data-testid={'download-activity-log-icon'}>
                    <Tooltip placement={"top"} title={'Download Block List Activity Logs'}
                             componentsProps={{
                                 tooltip: {
                                     sx: {
                                         position: "relative",
                                         top: "10px"
                                     },
                                 },
                             }}
                    >
                        <DownloadIcon sx={{ color: '#345C8B' }}/>
                    </Tooltip>
                </IconButton>
            </Stack>
        );
    }

    const onSort = (model: any) => {
        model.length > 0 ? setSortDirection(model[0].sort) : setSortDirection(defaultSortDirection)
        model.length > 0 ? setSortBy(model[0].field) : setSortBy('date_modify')
    };

    const handlePerRowsChange = (e: any) => {
        const value = parseInt(e.target.value)
        setPage(1)
        setPerPage(value)
    }

    const handlePageChange = (pageChanged: number) => {
        setPage(pageChanged)
    }

    const paginationComponent = () => {
        return <Pagination
            itemsPerPage = {perPage}
            currentPage = {page}
            startPoint = {startPoint}
            endPoint = {endPoint}
            maxPage = {maxPage}
            totalItems = {totalRecords}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
        />
    }

    return (
        <Box className={'block-list-activity-logs-page page-content-wrapper'}>
            <PageHeader
                pageTitle={BLOCK_LIST_ACTIVITY_LOGS}
                setInboxEvent={false}
                breadCrumbData={blockListDetailLinks}/>

            <Box className={'activity-logs-content'}>
                <Box className={'activity-logs-search-bar'}>
                    <SearchFilter
                        height={38}
                        margin={'0'}
                        widthTextInput={180}
                        titleInput={'Search'}
                        iconSrc={Images.SearchIcon}
                        borderRadius={'4px'}
                        handleKeyDownEvent={handleEnterSearchInput}
                        inputValue={filterSearchKeyword}
                        setInputValue={setFilterSearchKeyword}
                    />
                    <Box className={'activity-logs-search-dropdown'}>
                        {
                            dropDownFilterArr ? dropDownFilterArr.map((item: any) =>
                                <DropDownFilter key={item.label}
                                                isOpen={isOpenAttribute}
                                                setIsOpen={setIsOpenAttribute}
                                                clearFilerSelection={handleClearDropDownSelected}
                                                onSelectItem={handleDropDownSelected}
                                                selectedItems={selectedItemsDropDown}
                                                label={item.label}
                                                type={item.type}
                                                arrList={item.listArr}
                                                width={143}
                                />
                            ) : null
                        }
                        <Button
                            data-testid={"resetSearchFilterBtn"} className={"activity-logs-reset-search-btn"} onClick={() => handleResetFilter()} variant="text" size={"large"}
                            sx={{
                                padding: "8px",
                                width: 'fit-content',
                                height: '40px',
                                lineHeight: '16px',
                                fontFamily: 'Metropolis',
                                fontSize: '16px',
                                fontWeight: 600,
                                backgroundColor: 'transparent',
                                color: '#345C8B'
                            }}
                        >Reset</Button>
                    </Box>
                </Box>

                <Box className={'activity-logs-table'}>
                    <DataTable
                        columns={columns}
                        data={activityLogsData}
                        CustomPagination = {paginationComponent}
                        pagination={true}
                        initialSortCol={'date_modify'}
                        loadingTable={isTableLoading}
                        rowCount={totalRows}
                        rowHeight={60}
                        toolbar={toolbar}
                        onSortModelChange={(model:any) => onSort(model)}
                    />
                </Box>
            </Box>
            <LoadingPage open={loadingPage}/>
        </Box>
    );
}
export const BlockListActivityLogs = ChangeWithZone(BlockListActivityLogsFull);
