import React, {useEffect} from 'react';
import {AddNewBaseDomainProps} from "./AddNewBaseDomain.props";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import './AddNewBaseDomain.styles.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {FormHelperText} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LinearProgress from "@mui/material/LinearProgress";
import {BASE_DOMAIN_MODES, BASE_DOMAIN, BASE_DOMAIN_STATUS} from "../../../constants/contants";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import validator from 'validator';

const lineLoadingTheme = createTheme({
  palette: {
    primary: {
      main: "#345C8B",
    },
  },
});

export const AddNewBaseDomain: React.FC<AddNewBaseDomainProps> = (
    {
        isOpen=false,
        isLineLoading = false,
        result= '',
        onSave,
        onCancel,
        mode,
        value,
        listDomain= [],
    }
) => {
    const [btn, setBtn] = React.useState('');
    const validationSchema = yup.object({
        baseDomain: yup.string().required('This field is required')
            .test('baseDomain', 'This email already exists. Please re-enter.', async (val) => {
                let valueChecked: any;
                if(listDomain && listDomain.length > 0 && btn !== BASE_DOMAIN.TEST_DOMAIN){
                    if(mode !== BASE_DOMAIN_MODES.EDIT){
                        valueChecked = listDomain.find((domain:any) => domain === val);
                        return valueChecked ? false : true;
                    } else{
                        valueChecked = listDomain.find((domain:any) => (domain === val && domain !== value?.domain));
                        return valueChecked ? false : true;
                    }
                }
                return true;
            })
            .test('baseDomain', 'This is not an email address. Please re-enter.', async (val) => {
                return validator.isEmail(val)
            }),
    });
    
    const initialValues = {
        baseDomain: '',
        baseEmail: '',
    }
    
    const formikBaseDomain = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSave(values, btn)
            setBtn('');
        },
    });
    
    const removeSpace = (val: any) =>{
        const test = val.target.value.split(" ").join("");
        formikBaseDomain.setValues({baseDomain: test.toLowerCase(), baseEmail: test.toLowerCase()})
    }
    const handleClear= () => {
        formikBaseDomain.setValues(initialValues)
    };
    
    useEffect(()=>{
        if(isOpen){
            formikBaseDomain.resetForm();
            if(value && mode === BASE_DOMAIN_MODES.EDIT) {
                formikBaseDomain.setValues({baseDomain: value.domain, baseEmail: value.domain})
            }
        }
    }, [isOpen])
    
    return (
        <div className={'new-base-domain'} style={{maxWidth: '589px'}}>
            <Dialog
                open={isOpen}
                className={'new-base-domain-page-dialog'}
                fullWidth={true}
                sx={{zIndex: 100}}
            >
                <form onSubmit={formikBaseDomain.handleSubmit}>
                <DialogTitle id="new-base-domain-title" sx={{padding:'16px 24px 16px 24px'}}>
                    <span className={'new-base-domain-title'}>{mode === BASE_DOMAIN_MODES.EDIT ? 'Edit sender email address' : 'Add new sender email address'}</span>
                    <IconButton
                        aria-label="close"
                        onClick={onCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 14,
                            color: '#666666'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{padding: '24px 24px 75px 24px'}} dividers>
                    <Box>
                      <Box className={'new-email-sender-description'} sx={{marginBottom: '16px'}}>
                        Before this zone can send an email, it must be verified first. Enter it below and click to verify it.
                      </Box>
                    <InputLabel className={'new-base-domain-input-label'}>
                      {mode === BASE_DOMAIN_MODES.EDIT ? 'Email address' : 'New email address'} <span style={{color: 'red'}}>*</span>
                    </InputLabel>
                    <OutlinedInput
                        name={'baseDomain'}
                        id={'baseDomain'}
                        className={'input-field'}
                        onBlur={(e) => {formikBaseDomain.handleBlur(e); removeSpace(e)}}
                        placeholder={'Input new email address'}
                        size={"small"}
                        sx={{ fontSize: '14px', fontWeight: 500}}
                        value={formikBaseDomain.values.baseDomain}
                        onChange={(e) => {
                            formikBaseDomain.handleChange(e);
                        }}
                        onKeyDown={ (e) => {e.key === 'Enter' && e.preventDefault()} }
                        error={formikBaseDomain.touched.baseDomain && Boolean(formikBaseDomain.errors.baseDomain)}
                        endAdornment={
                            <InputAdornment position="end">
                                {(!isLineLoading && formikBaseDomain.values.baseDomain)&&  <IconButton
                                    aria-label="toggle  visibideletelity"
                                    onClick={handleClear}
                                    data-testid="handle-clear"
                                    edge="end"
                                >
                                    {<CloseIcon sx={{color:'#345C8B'}}/>}
                                </IconButton>}
                            </InputAdornment>
                        }
                        disabled={isLineLoading}
                    />
                        <Button
                            className={'btn-new-email-address-contained'}
                            data-testid="btn-new-base-domain-outlined"
                            type="submit"
                            onClick={()=> setBtn(BASE_DOMAIN.SAVE_DOMAIN)}
                            variant="contained"
                            size={"large"}
                            sx={{marginLeft: '8px', width: 'fit-content', marginBottom: '4px', padding: '8px 24px'}}
                            disabled={isLineLoading}
                        >
                            Verify email
                        </Button>
                    </Box>
                    <FormHelperText error id="baseDomain"
                                    style={{margin: '0 0 0 16px', fontWeight: 500, fontSize: '12px'}}>
                        {(formikBaseDomain.touched.baseDomain && formikBaseDomain.errors.baseDomain)}
                    </FormHelperText>
                    {/*show success message*/}
                    {(!isLineLoading && result === BASE_DOMAIN_STATUS.SUCCESS) &&  <p className={'send-mail-base-domain'}>
                    <CheckCircleIcon className={'success'}/>
                    <span className={'success'} > The test email was sent successfully.</span>
                    </p>}
    
                    {/*show fail message*/}
                    { (!isLineLoading && result !== BASE_DOMAIN_STATUS.SUCCESS && result !== BASE_DOMAIN_STATUS.NONE) && <p className={'send-mail-base-domain'}>
                        <CancelIcon className={'fail'}/>
                        <span className={'fail'} > Mail sent error. Can’t send the email!</span>
                    </p> }
    
                    {/*show loading message*/}
                    {isLineLoading && <p className={'loading-base-domain'}>
                        <ThemeProvider theme={lineLoadingTheme}><LinearProgress sx={{width:'400px', height: '6px'}} /></ThemeProvider>
                        <span className={'message'} >Sending a verification email to this email address.</span>
                    </p>}
                </DialogContent>
                </form>
            </Dialog>
        </div>
    )
}