import React from 'react'
import {MiniDashboardCardProps} from './MiniDashboardCard.props'
import './MiniDashboardCard.styles.css';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import {LineChart} from "../LineChart";

export const MiniDashboardCard: React.FC<MiniDashboardCardProps> = ({type, cardName, monthsData, isLoading= true}) => {
  
  let checkHaveData = [];
  let textContentChange;
  let isLoadingMini;
  const  currentMonthTotal = monthsData && (Object.values(monthsData))[0]
  const  preMonthTotal = monthsData && (Object.values(monthsData))[1]
  
    if(monthsData){
        isLoadingMini = false;
        if (currentMonthTotal === preMonthTotal) {
            textContentChange = 'No Changes'
        } else if (preMonthTotal === 0) {
            textContentChange = currentMonthTotal > 0 ? '100% Increase' : '100% Decrease'
        } else {
            const percent = Math.round((currentMonthTotal - preMonthTotal)/(preMonthTotal)*100)
            textContentChange = percent > 0 ? Math.abs(percent).toString() + '% Increase' : Math.abs(percent).toString() + '% Decrease'
        }
    
        checkHaveData = Object.values(monthsData).filter(value => value !== 0);
        if (checkHaveData.length === 0) {
            textContentChange = 'No Activity';
        }
    } else {
        isLoadingMini = true;
    }
  
  return (
    <Grid sx={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '4px',
              display: 'flex',
              padding: '19px',
              height: 145,
              alignItems: 'center',
              boxSizing: 'border-box'
            }}
          alignItems={'center'}
          container
    >
      
      <Grid item
            xs={7.8}
            height={110}
            >
        <Stack alignItems="center" justifyContent={'center'}>
          <Typography
            align={'center'}
            sx={{
              minWidth: 185,
              fontWeight: 600,
              fontSize: '15px',
              lineHeight: '18px',
              color: '#808888',
              letterSpacing: 0
            }}
          >
            
            {(isLoading || isLoadingMini)?  <Skeleton variant="text" sx={{ fontSize: '10px', margin: 'auto' }} width={90} />  : cardName}
          </Typography>
          <Typography
            align={'center'}
            sx={{
              minWidth: 90,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#808888',
              letterSpacing: 0
            }}
          >
    
            {(isLoading || isLoadingMini)?  <Skeleton variant="text" sx={{ fontSize: '10px' }} />  : "6-Month Period"}
          </Typography>
  
          <Typography
            align={'center'}
            sx={{
              minWidth: 30,
              minHeight: 30,
              fontWeight: 500,
              fontSize: '33px',
              lineHeight: '40px',
              color: '#4D5858',
              margin: '4px 0 7px 0'
            }}
          >
              {(isLoading || isLoadingMini)? <Skeleton variant="circular" width={40} height={40}/> : (checkHaveData.length > 0 ? currentMonthTotal : <span style={{color: '#B3B8B8'}}>N/A</span>)}
          </Typography>
  
          <Typography
            align={'center'}
            sx={{
              minWidth: 50,
              fontWeight: 700,
              fontSize: '10px',
              lineHeight: '12px',
              color: '#B3B8B8',
      
            }}
          >
            
            {(isLoading || isLoadingMini)?  <Skeleton variant="text" sx={{ fontSize: '10px' }} />  : textContentChange}
          </Typography>
  
          <Typography
            align={'center'}
            sx={{
              minWidth: 70,
              fontWeight: 700,
              fontSize: '10px',
              lineHeight: '12px',
              color: '#B3B8B8',
            }}
          >
            {(isLoading || isLoadingMini)?  <Skeleton variant="text" sx={{ fontSize: '10px' }} />  : 'Since Last Month'}
          </Typography>
        </Stack>
      </Grid >
      <Grid item
            xs={4.2}
          height={100}
      >
        {(isLoading || isLoadingMini) ? <Skeleton variant="rounded" height={110} /> : <LineChart
          monthsData={monthsData}
          cardName={cardName}
          type={type}
          noData={Boolean(checkHaveData.length === 0)}
        />}
      </Grid>
    </Grid>
  );
}
