import React from 'react'
import {LineChartProps} from './LineChart.props'
import './LineChart.styles.css';
import ReactApexChart from "react-apexcharts";
import Box from '@mui/material/Box';

const markerHoverColor = {
  'Reported Messages': {
    'textColor': '#FFFFFF',
    'bgColor': ['#1C1C1C', '#363636']
  },
  'Automatically Resolved': {
    'textColor': '#193051',
    'bgColor': ['#EBDE6A', '#C0E54C']
  },
  'Manually Resolved': {
    'textColor': '#BDE541',
    'bgColor': ['#437094', '#1A3253']
  },
  'Unresolved': {
    'textColor': '#193051',
    'bgColor': ['#9FD8DB', '#4E8BBA']
  }
}

const chartColorRules = {
  'Reported Messages': {
    'chartColor': ['#0E4F83'],
    'markColor': ['#9FD8DB']
  },
  'Automatically Resolved': {
    'chartColor': ['#9DC914'],
    'markColor': ['#BDE541']
  },
  'Manually Resolved': {
    'chartColor': ['#61BBD8'],
    'markColor': ['#DFF4ED']
  },
  'Unresolved': {
    'chartColor': ['#7C8989'],
    'markColor': ['#D5E2E3']
  }
}

const chartColorRulesWithNoData = {
  'Reported Messages': {
    'chartColor': ['#808888'],
    'markColor': ['#CCCFCF']
  },
  'Automatically Resolved': {
    'chartColor': ['#808888'],
    'markColor': ['#CCCFCF']
  },
  'Manually Resolved': {
    'chartColor': ['#808888'],
    'markColor': ['#CCCFCF']
  },
  'Unresolved': {
    'chartColor': ['#808888'],
    'markColor': ['#CCCFCF']
  }
}

export const LineChart: React.FC<LineChartProps> = ({type, monthsData, cardName, noData}) => {
  
  const chartColor = noData ? chartColorRulesWithNoData[cardName]?.chartColor : chartColorRules[cardName]?.chartColor
  const markerColor = noData ? chartColorRulesWithNoData[cardName]?.markColor : chartColorRules[cardName]?.markColor
  const monthsDataName = Object.keys(monthsData).reverse()
  const optionMonth:any = { month: "long" };
  
  for (let i = 0; i < monthsDataName.length; i++) {
    const date = new Date(monthsDataName[i] + " 1, 2021");
    const formattedDate = date.toLocaleString("en-US", optionMonth );
    monthsDataName[i] = formattedDate;
  }
  const hoverUI = ({ series, seriesIndex, dataPointIndex, w }: any) => {
    const monthIndex = monthsDataName[w.globals.labels[dataPointIndex] - 1]
    const totalIndex = series[seriesIndex][dataPointIndex]
    return '<div class="tooltip-marker">' +
      '<p class="tooltip-title" ">' +  monthIndex + '</p>' +
      '<p class="tooltip-title" ">' +   totalIndex + '</p>' +
      '</div>';
  }
  
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      },
      background: "transparent",
    },
    grid: {
      show: false
    },
    markers: {
      size: 3,
      colors: markerColor[0],
      strokeColor: markerColor[0],
      hover: {
        size: 6,
      }
    },
    stroke: {
      width: 2,
      dashArray: noData ? 4 : 0
    },
    xaxis: {
      show: false,
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      show: false,
      tooltip: {
        enabled: false, }
    },
    tooltip: {
      custom:  hoverUI,
      intersect: true,
      shared: false
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: chartColor[0],
            opacity: 1
          },
          {
            offset: 100,
            color: chartColor[0],
            opacity: 1
          }
        ]
      }
    },
    
  };
  
  const series = [
    {
      name: "Series 1",
      data: monthsData && Object.values(monthsData).reverse()
    }
  ];
  
  return (
    <Box className={'line-chart'}>
      {/*//@ts-ignores*/}
      <ReactApexChart
        type="line"
        options={options}
        series={series}
        height={'100px'}
        width={'100%'}
      />
    </Box>
    
  );
}
