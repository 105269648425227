import React from 'react'
import {Text} from '../../atoms/Text'
import {NoDataCardProps} from './NoDataCard.props'
import './NoDataCard.styles.css';
import Box from "@mui/material/Box";

export const NoDataCard: React.FC<NoDataCardProps> = ({
  /* istanbul ignore next */
  type = 'topTreeMap',
  /* istanbul ignore next */
  title = 'N/A',
  /* istanbul ignore next */
  description = 'No Data To Calculate',
  /* istanbul ignore next */
  fontWeight = '600',
  /* istanbul ignore next */
  color = '#B3B8B8',
  /* istanbul ignore next */
  textAlign = 'center',
  /* istanbul ignore next */
  width = '100%',
  /* istanbul ignore next */
  height = '100%',
  /* istanbul ignore next */
  display = 'block',
  /* istanbul ignore next */
  titleFontSize = '50px',
  /* istanbul ignore next */
  descriptionFontSize = '15px',
  /* istanbul ignore next */
  border = '',
  /* istanbul ignore next */
  padding = '0',
  /* istanbul ignore next */
  treeMapValue = '',
  /* istanbul ignore next */
  borderTop = ''
}) => {
    // type === topTreeMap case is nodata card for topTreeMap chart
    // the other case is for the other charts
   return ( type === 'topTreeMap' ?
           <Box
               sx={{
                   width: width,
                   height: height,
                   textAlign: textAlign as any,
                   border: border,
                   padding: padding,
                   display: display,
               }} className={`no-data-card-border-box`}>
               <Box sx={{display: 'flex', marginBottom: '15px'}}>
                   <Text
                       width={'50%'}
                       textAlign={'left'}
                       fontSize={'14px'}
                       fontWeight={'600'}
                       color={color}
                       cssClass='no-data-card-title'>{treeMapValue}</Text>
                   <Text
                       width={'50%'}
                       textAlign={'right'}
                       fontSize={'14px'}
                       fontWeight={'600'}
                       color={color}
                       cssClass='no-data-card-title'>{'--'}</Text>
                   <Text
                       fontSize={'14px'}
                       margin={'0 0 0 4px'}
                       fontWeight={'600'}
                       color={color}
                       cssClass='no-data-card-title'>{'%'}</Text>
               </Box>
               <Text
                   fontSize={titleFontSize}
                   fontWeight={fontWeight}
                   color={color}
                   cssClass='no-data-card-title'>{title}</Text>
           </Box> :
           <Box
               sx={{
                   width: width,
                   height: height,
                   display: display,
                   padding: padding,
                   textAlign: textAlign as any,
                   border: border,
                   borderTop: borderTop
               }}
               className={`no-data-card-border-box`}
           >
               <Text
                   fontSize={titleFontSize}
                   fontWeight={fontWeight}
                   color={color}
                   cssClass='no-data-card-title'>{title}</Text>
               <Text
                   fontSize={descriptionFontSize}
                   fontWeight={fontWeight}
                   color={color}
                   cssClass='no-data-card-title'>{description}</Text>
           </Box>
   )
}
