import React from "react";
import { Navigate } from "react-router-dom";
import {ADMIN_ROLE, COMPANY_ADMIN_ROLE} from "../constants/contants";
import {Images} from "../configs/images";
import { ReactComponent as MailIcon } from "../assets/images/mail.svg";
import { ReactComponent as DashboardIcon } from "../assets/images/dashboard.svg";
import { ReactComponent as SettingIcon } from "../assets/images/settings.svg";
import { ReactComponent as BuildIcon } from "../assets/images/build.svg";
import { ReactComponent as RemoveIcon } from "../assets/images/remove_icon.svg";

// all routes
const adminRoutes = [
  {path: "*", element: <Navigate to="/" />}
];
// no info page
const userRoutes = [
  {path: "*", element: <Navigate to="/" />}
];
// no info and users
const unauthorizedRoutes = [
  {path: "*", element: <Navigate to="/" />}
];

const ROLES_ROUTES = {
  [ADMIN_ROLE]: userRoutes,
  [COMPANY_ADMIN_ROLE]: adminRoutes,
  undefined: unauthorizedRoutes
};
const ROLES_NAMES = {
  [ADMIN_ROLE]: "adminRole",
  [COMPANY_ADMIN_ROLE]: "comapanyAdminRole",
  undefined: "unauthorizedRole"
};

export const getRoutes = (role:any) => {
  console.log({role: ROLES_NAMES[role]})
  return ROLES_ROUTES[role] ?? unauthorizedRoutes
};

export const setMenuRoute = (settings:any) => {
  let defaultMenu:any = [
    {
      name: 'Dashboard',
      href: '/dashboard',
      linkIcon: Images.DashboardIcon,
      icon: <DashboardIcon className={'btnNode'}/>
    },
    {
      name: 'Inbox',
      href: '/inbox',
      icon: <MailIcon className={'btnNode'}/>,
      child: [
        {
          name: 'Archived',
          href: '/archived',
        }
      ]
    },
  ]

  const isMs365 = sessionStorage.getItem('isMS365') === 'true'

  if(settings.automation_rule) {
    defaultMenu.push({
      name: 'Automation Rules',
      href: '/automation-rules',
      linkIcon: Images.BuildIcon,
      icon: <BuildIcon className={'btnNode'}/>
    })
  }

  if(settings.block_list && isMs365) {
    defaultMenu.push({
      name: 'Block List',
      href: '/block-list',
      icon: <RemoveIcon className={'btnNode'}/>,
      child: [
        {
          name: 'Activity Logs',
          href: '/block-list/activity-logs',
        }
      ]
    })
  }

  defaultMenu.push({
    name: 'Settings',
    href: '/settings',
    linkIcon: Images.SettingIcon,
    icon: <SettingIcon className={'btnNode'}/>
  })

  return defaultMenu
}
