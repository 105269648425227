import React from 'react';
import './AuthenAzure.style.css';
import {AuthenAzureProps} from './AuthenAzure.props';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import {Text} from "../../components/atoms/Text";
import {Button} from "../../components/atoms/Button";
import {Configuration, PublicClientApplication} from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import {loginRequest, msalConfig} from "../../api/azure";

const msalInstance = new PublicClientApplication(msalConfig as Configuration);

export const AuthenAzure: React.FC<AuthenAzureProps> = () => {

  const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    // const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          console.log(response);
        });
    }

    return (
      <>
        <Text cssClass={'login-text'} color='#345C8B' children={`Welcome ${accounts[0].name}`} fontWeight={500} fontSize={20} margin={'70px 0 20px'}/>
      </>
    );
  };

  const MainContent = () => {
    return (
      <div className="App">
        <AuthenticatedTemplate>
          <ProfileContent />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Text cssClass={'login-text'} color='#345C8B' children='Log in Azure Portal' fontWeight={500} fontSize={20} margin={'70px 0 20px'}/>
        </UnauthenticatedTemplate>

      </div>
    );
  };

  const LoginForm = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const handleLogin = (loginType:any) => {
      if (loginType === "popup") {
        instance.loginPopup(loginRequest).catch(e => {
          console.log(e);
        });
      } else if (loginType === "redirect") {
        instance.loginRedirect(loginRequest).catch(e => {
          console.log(e);
        });
      }
    }

    const handleLogout = (logoutType:any) => {
      if (logoutType === "popup") {
        instance.logoutPopup({
          postLogoutRedirectUri: "/oauth-azure",
          mainWindowRedirectUri: "/oauth-azure"
        });
      } else if (logoutType === "redirect") {
        instance.logoutRedirect({
          postLogoutRedirectUri: "/oauth-azure",
        });
      }
    }

    return (
      <div className='page-azure-login page-content-wrapper'>
        <div className='login-content'>
          <div>
            <MainContent />
            {isAuthenticated ? <Button color={'#fff'} onClick={() => handleLogout("popup")} height={40} title='Sign out'></Button>
            : <Button color={'#fff'} onClick={() => handleLogin("popup") } height={40} title={'Sign in'}></Button>}
          </div>
        </div>
      </div>
    )
  }

  return (
    <MsalProvider instance={msalInstance}>
      <LoginForm/>
    </MsalProvider>
  );
};





