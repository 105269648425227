import React, { useEffect, useImperativeHandle, useRef, useState} from 'react'
import './HeaderDeliveryInformationModal.styles.css';
import {HeaderDeliveryInformationModalProps} from './index';
import {Box, Chip} from "@mui/material";
import {TabGroup} from "../../molecules/TabGroup";
import {DeliveryViewer} from "../../molecules/DeliveryViewer";

export const HeaderDeliveryInformationModal: React.FC<HeaderDeliveryInformationModalProps> = ({
    payLoad,
    tabIndex = 0
}) => {
    const [tabValue, setValue] = useState(tabIndex);
    const [payload, setPayload] = useState<any>({dmarc: [], spf: [], dkim: []});

    const parseData = (params:any) => {
        const newData:any = {dkim: [{}], dmarc: [{}], spf: [{}]}

        Object.fromEntries(
            Object.entries(params)
                .filter(([key]) => {
                    if(key.startsWith("dkim")){
                        newData["dkim"][0][key] = params[key]
                    } else if(key.startsWith("dmarc")){
                        newData["dmarc"][0][key] = params[key]
                    } else if(key.startsWith("spf")){
                        newData["spf"][0][key] = params[key]
                    }
                })
        );
        return newData
    }

    useEffect(() => {
        const data = parseData(payLoad)
        setPayload(data)

    }, []);

    const tabContents = [
        <DeliveryViewer title={'DMARC'} payload={payload['dmarc']}/>,
        <DeliveryViewer title={'SPF'} payload={payload['spf']}/>,
        <DeliveryViewer title={'DKIM'} payload={payload['dkim']}/>
    ]

    return (
        <Box className={'delivery-information-modal-container'}>
            <TabGroup setValue={setValue}
                      tabValue={tabValue}
                      tabTitles={[
                            <div>DMARC</div>,
                            <div>SPF</div>,
                            <div>DKIM &nbsp;
                                {
                                    payload["dkim"].length > 0 && payload["dkim"][0]?.dkim_details.length > 1 ?
                                        <Chip sx={{backgroundColor: '#808888', color: 'white'}} size={'small'} label={payload["dkim"][0]?.dkim_details.length}/>
                                    : null
                                }
                            </div>
                      ]}
                      tabContents={tabContents}
                      statistic={false}>
            </TabGroup>
        </Box>
    )
}
