import React from 'react'
import {BottomReportedMessagesCardProps} from './BottomReportedMessagesCard.props'
import './BottomReportedMessagesCard.styles.css';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';


import {StackedColumnChart} from "../StackedColumnChart";
import {LegendChart} from "../LegendChart"
import Skeleton from "@mui/material/Skeleton";


export const BottomReportedMessagesCard: React.FC<BottomReportedMessagesCardProps> = ({
  data,
  isLoading
  }) => {
    // check loading first times
    let isLoadingReportedMessagesCard;
    if(data !== null){
        isLoadingReportedMessagesCard = false;
    } else {
        isLoadingReportedMessagesCard = true;
    }
  return (
    <Box sx={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '4px',
              padding: '16px 24px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              boxSizing: 'border-box',
              height: 315
            }}
    >
      <Box
            sx={{
              display: 'flex',
              alignItems: 'top',
              justifyContent: 'left'
            }}
            >
        <Box>
          <Typography
            align={'left'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '20px',
              lineHeight: '24px',
              minWidth: 270,
              color: '#808888',
            }}
          >
            {(isLoading || isLoadingReportedMessagesCard)? <Skeleton variant="text" sx={{ fontSize: '20px' }} /> : "Reported Messages Category"}
          </Typography>
  
          <Typography
            align={'left'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#4D5858',
            }}
          >
            {(isLoading || isLoadingReportedMessagesCard) ? <Skeleton variant="text" sx={{ fontSize: '12px' }} /> : "Total messages | 6-Month Period"}
          </Typography>
        </Box>
      </Box >
      <Box
          height={250}
      >
        { (isLoading || isLoadingReportedMessagesCard) ? <Skeleton  sx={{ marginTop: "12px" }} variant="rounded" height={220} width={'100%'} /> : data && <StackedColumnChart data={data}/>}
      </Box>
    </Box>
  );
}
