export const Colors = {
  White: '#FFFFFF',
  Black: '#000000',
  Black80: '#011111',
  Blue: '#467599',
  DarkBlue: '#1a3141',
  Grey: '#737373',
  WhiteGrey: '#B7B7B7',
  BorderColor: '#B3B8B8',
  LabelColor: '#7C8989',
  Transparent: 'tranparent',
}
