import React, {useEffect, useState} from 'react';
import {AutomationRulesProps} from './AutomationRules.props';
import './AutomationRules.styles.css';
import {PageHeader} from "../../components/organisms/PageHeader";
import {useNavigate, useParams} from "react-router-dom";
import {AUTOMATION_RULES, AUTOMATION_RULES_INFO} from "../../constants/contants";
import {DataTable} from "../../components/molecules/DataTable";
import {GridColDef} from "@mui/x-data-grid";
import {getRules, updateRules} from "../../api/emailTriage";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {Drawer, FormControlLabel} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Toast} from "../../components/atoms/Toast";
import ChangeWithZone from "../../hoc/ChangeWithZone";
import LeavePageDialog from "../../components/organisms/LeavePageDialog/LeavePageDialog";
import {useCallbackPrompt} from "../../hooks/useCallbackPrompt";

const AutomationRulesFull: React.FC<AutomationRulesProps> = () => {
  const navigate = useNavigate();
  const [initChecked, setInitChecked] = useState<any>({})
  const [isShowLoading , setShowLoading] = useState(false);
  const [ruleData, setRuleData] = useState([])
  const [isActionShow, setActionShow] = React.useState(false);
  const [checked, setChecked] = useState({1: true, 2: true, 3: true, 4: true});
  const [modalID, setModalID] = useState(1)
  const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0})
  const [sortCol, setSortCol] = useState({})
  // useState show leavePage
  const [showLeaveDialog, setShowLeaveDialog] = useState<boolean>(false)
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showLeaveDialog)

  const toggleDrawer = (newOpen: boolean) => () => {
    setActionShow(newOpen);
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#008545",
      '&:hover': {
        backgroundColor: "#B3DAC7",
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#B3DAC7",
    },
  }));

  const columns: GridColDef[] = [
    {field: 'rule_name', headerName: 'Rule Name', width: 300, sortable: true,
      cellClassName: (row:any) => {
        return checked[row.id] ? '' : 'custom-disable'
      }
    },
    {field: 'description', headerName: 'Description', flex: 1, minWidth: 600, sortable: false,
      cellClassName: (row:any) => {
        return checked[row.id] ? '' : 'custom-disable'
      }
    },
    {field: 'is_active', headerName: 'Status', width: 150, sortable: false,
      renderCell: (row:any) => {
        return <FormControlLabel className={`status-col ${checked[row.id] ? 'active' : 'inactive'}`} control={
          <GreenSwitch checked={checked[row.id]} onChange={(status) => changeStatusRule(status, row)}/>
        } label={checked[row.id] ? 'Active' : 'Inactive'} />
      }
    },
  ];

  useEffect(() => {
    setShowLoading(true)
    const params:any = {
      sortField: sortCol[0]?.sort || 'asc'
    }
    getRules(params).then((response:any) => {
      const data = response.data
      setRuleData(data)
      for(let i = 0; i < data.length; i++) {
        Object.assign(initChecked, {[data[i].rule_id]: data[i]['is_active']})
      }
      setShowLoading(false)
      setChecked(initChecked)
      setInitChecked(initChecked)
    })
  }, [sortCol])

  const handleDiscard = () => {
    setActionShow(false)
    setChecked(initChecked)
    setShowLeaveDialog(false);
  }

  const handleSaveChange = () => {
    setShowLoading(true)
    const params:any = []
    const newChecked:any = {}
    Object.entries(checked).forEach((item:any, index:any) => {
      params.push({ruleId: item[0], isActived: Boolean(item[1])})
      Object.assign(newChecked, {[item[0]]: item[1]});
    })
    const paramsData = {'itemsRule': params}
    updateRules(paramsData).then((response:any) => {
      setModalID(modalID + 1)
      setToastValue({type: 'success', message: 'Saved successfully.', isOpen: true, toastId: modalID})
      setInitChecked(newChecked)
      setActionShow(false)
      setShowLoading(false)
      setShowLeaveDialog(false);
    })
  }

  const saveChangeUI = (
    <Box className={'save-change-content'} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px 0'}}>
      <div className={'btn discard-btn'}>
        <Button variant="outlined" onClick={handleDiscard}>Discard</Button>
      </div>
      <div className={'btn save-btn'}>
        <Button variant="contained" onClick={handleSaveChange}>Save Change</Button>
      </div>
    </Box>
  )

  const changeStatusRule = (status:any, row:any) => {
    const isChecked = status.target.checked
    setChecked({
      ...checked,
      [row.id]: isChecked
    })
    setActionShow(true);
    setShowLeaveDialog(true);
  }

  const handleClickItem = (event:any) =>{
    const fields = ['is_active']
    if(!fields.includes(event.field)) {
      navigate('/automation-rules/' + event.id, {
        state: {
          selectedRule: event.row
        }
      })
    }
  }

  const onSort = (model:any) => {
    console.log(model)
    setSortCol(model)
    setActionShow(false)
    setShowLeaveDialog(false)
  }

  return(<div className={'automation-rule-page page-content-wrapper'}>
      <PageHeader pageTitle={AUTOMATION_RULES} infoPage={AUTOMATION_RULES_INFO}/>
      <div className='page-content-body'>
        <div className='automation-rule-content'>
          <div className='rules-datatable'>
            <DataTable columns={columns}
                       data={ruleData}
                       actionsColumn={false}
                       pagination={false}
                       loadingTable={isShowLoading}
                       onRowDoubleClick={handleClickItem}
                       onSortModelChange={(model:any) => onSort(model)}
                       initialSortCol={'rule_name'}/>
          </div>
        </div>
      </div>
      <Drawer
        variant="persistent"
        anchor="bottom"
        open={isActionShow}
        onClose={toggleDrawer(false)}
        className={'save-change-container'}
        children={saveChangeUI}
      ></Drawer>
      <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message} toastId={toastValue.toastId} autoHideDuration={2000}/>
      <LeavePageDialog
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
    </div>
  );
};

export const AutomationRules = ChangeWithZone(AutomationRulesFull);