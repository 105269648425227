import React from 'react'
import { DynamicColumnChartProps} from './DynamicColumnChart.props'
import './DynamicColumnChart.styles.css';
import ReactApexChart from "react-apexcharts";
import {NoDataCard} from "../NoDataCard";

export const DynamicColumnChart: React.FC<DynamicColumnChartProps> = ({data}) => {
  let countData = 0
  data?.map((item:any) => { if(item > 0){countData += 1}})
  const hasData = countData > 0 ? true : false
  const options = {
    colors:  ['#0E4F83', '#61BBD8', '#BDE541'],
    fill: {
      colors:  ['#0E4F83', '#61BBD8', '#BDE541'],
      opacity: 1,
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      },
      background: "transparent",
    },
    yaxis: {
      show: false,
      tickAmount: 5,
      min: 0,
      max: Math.max(...data)*1.1
    },
    grid: {
      show: false
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        distributed: true,
        borderRadius: 4,
        dataLabels: {
          position: 'top'
        }
      },
      
    },
    tooltip: {
      enabled: false,
      enabledOnSeries: undefined,
      marker: {
        show: false,
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333']
      },
      offsetY: -20
    },
    legend: {
      show: false,
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      categories: ["a", "b", "c"],
      labels: {
        show: false,
        style: {
          colors: '#5A5A5A',
          fontSize: '9px',
          fontFamily: 'Montserrat',
          fontWeight: '400'
        }
        
      },
      axisBorder: {
        show: false,
      },
    }
  };
  
  const series = [{
    data:  data
  }];
  
  
  
  
  return (
    //@ts-ignores
    hasData ? <ReactApexChart
          type="bar"
          options={options}
          series={series }
          height={'100%'}
          width={'100%'}
      /> :
      <NoDataCard type={'dynamicColumn'} padding={'70px 0 0 0'} ></NoDataCard>
  );
}
