import React from 'react'
import { LoadingPageProps } from './LoadingPage.props'
import './LoadingPage.styles.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const LoadingPage: React.FC<LoadingPageProps> = ({
    open = false
}) => {
  return (
    <Backdrop
      id={'loading-page'}
      sx={{ color: '#fff'}}
      open={open}
    >
      <CircularProgress />
    </Backdrop>
  )
}
