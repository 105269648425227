import React, {useEffect, useState} from 'react';
import './Logout.css';
import { Images} from '../../configs/images';
import {Image} from '../../components/atoms/Image';
import {Text} from "../../components/atoms/Text";
import {Link} from "../../components/atoms/Link";
import { ReactComponent as LogoutIcon } from "../../assets/images/entypo_log-out.svg";
import { ReactComponent as LockIcon } from "../../assets/images/lock.svg";
import {isMobileOnly, isMobile} from "react-device-detect";
import {useLocation} from "react-router-dom";
import {LogoutProps} from "./Logout.props";

export const Logout:  React.FC<LogoutProps> = ({
  pageTitle
}) => {

  let loginLink =   "https://"+process.env.REACT_APP_AWS_CONFIG_LOGIN_URL;
  const isLogout = sessionStorage.getItem('isLogout');
  const isTimeout = sessionStorage.getItem('isTimeout');

  let checkTimeout =  false
  if (isLogout === 'company_admin') {
    loginLink += "/company_admin";
  } else {
    loginLink += "/admin";
  }

  if (isTimeout==='true') {
    checkTimeout = true
  }

  const [isAllow, setIsAllow] = useState(false)
  useEffect(() => {
    const isLogout = sessionStorage.getItem('isLogout');
    setIsAllow(isLogout ? true : false)
    // if (isLogout !== null) {
    //   setIsAllow(true)
    // } else {
    //   setIsAllow(false)
    // }
  },[])

  const location = useLocation();

  let fontIcon = 32

  if (isMobile) {
    fontIcon = 26
  }

  if (isMobileOnly) {
    fontIcon = 20
  }

  const iconDeny = <LockIcon className={'logout-icon'} width={fontIcon} height={fontIcon}/>
  const iconAllow = <LogoutIcon className={'logout-icon'} width={fontIcon} height={fontIcon}/>

  const titleAllow = checkTimeout ?  "Your login session has timed out.":"You have successfully logged out."
  const titleDeny = "Sorry, you don’t have permission to access this page."

  const contentAllow = 'To log back in again, return to'
  const contentDeny = <span>Please contact your <span style={{fontWeight: 700}}>Phriendly Phishing Customer Success Manager</span></span>

  const lastContentAllow = <span>
      <Link cssClass={'link-logout' + (isMobile ? 'is-mobile': '') + (isMobileOnly ? 'mobile-only': '')}
            url={loginLink}
      >
        Phriendly Phishing
      </Link>
    </span>
  const lastContentDeny = 'if you should have access to this feature.'
  return (
    <div className={'logout-wrapper' + (isMobile ? ' is-mobile ': '' ) + (isMobileOnly ? ' mobile-only ': '')}>
      <Link url={loginLink}>
        <Image cssClass={'logo-logout-left'} srcImg={Images.Logo}/>
      </Link>
      <div className={'center-logout'} >
        <div className={'logout-logo'} >
          {location.pathname!=='/logout' ? iconDeny: (isAllow? iconAllow : checkTimeout? iconAllow : iconDeny)}
        </div>
        <Text cssClass={'log-out-text-title'}
              children={location.pathname!=='/logout' ? titleDeny : (isAllow ? titleAllow : checkTimeout ? titleAllow : titleDeny)} color={'#193051'}/>
        <Text cssClass={'log-out-text'}
              children={location.pathname!=='/logout' ? contentDeny : (isAllow?contentAllow:checkTimeout ? contentAllow : contentDeny)} />
        <Text cssClass={'log-out-text'}
              children={
                location.pathname!=='/logout' ? lastContentDeny : (isAllow? lastContentAllow : checkTimeout ? lastContentAllow: lastContentDeny)
              }
        />
      </div>
    </div>
  )
}
