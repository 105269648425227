import React, {Fragment} from 'react';
import {MailViewerProps} from "./MailViewer.props";
import Iframe from "../IFrame/IFrame";
import Skeleton from "@mui/material/Skeleton";

export const MailViewer: React.FC<MailViewerProps> = ({
    content,
    image
}) => {
  return (
    <Fragment>
      {
        content == undefined ?
          (<Skeleton variant="rounded" width={'100%'} height={60} />)
        :
          (<Iframe content={content} image={image}/>)
      }
    </Fragment>
  );
};
