import {useCallback, useContext, useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBlocker } from './useBlocker';
import {ROUTE_LOGOUT_PAGE} from '../constants/contants'
import {GlobalContext} from "../context/GlobalState";

export function useCallbackPrompt(when: boolean): (boolean | (() => void))[] {
    const navigate = useNavigate()
    const location = useLocation()
    const [showPrompt, setShowPrompt] = useState<boolean>(false)
    const [lastLocation, setLastLocation] = useState<any>(null)
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)
    //@ts-ignores
    const userLocalData = JSON.parse(localStorage.getItem('userData'));
    const cancelNavigation = useCallback(() => {
        setShowPrompt(false)
        setLastLocation(null)
    }, [])
    const { globalDispatch } = useContext(GlobalContext);
    // handle blocking when user click on another route prompt will be shown
    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            // in if condition we are checking next location and current location are equals or not
           if(sessionStorage.getItem("isTimeout")){
               setShowPrompt(false)
               setConfirmedNavigation(true)
               setLastLocation(nextLocation)
                return false
            }
           if (
                !confirmedNavigation &&
                nextLocation.location.pathname !== location.pathname
            ) {
                setShowPrompt(true)
                setLastLocation(nextLocation)
                return false
            }
            return true
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [confirmedNavigation, location]
    )

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false)
        setConfirmedNavigation(true)
        globalDispatch({
            type: 'UPDATE_IS_CONFIRM_NAVIGATION',
            payload: false,
        })
    }, [])

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            if(lastLocation.location?.pathname === ROUTE_LOGOUT_PAGE) {
                sessionStorage.setItem("isLogout", userLocalData['role']);
                localStorage.removeItem('userData')
                window.location.href = ROUTE_LOGOUT_PAGE;
                localStorage.clear();
            } else {
                navigate(lastLocation.location?.pathname)
            }

            // Clean-up state on confirmed navigation
            setConfirmedNavigation(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmedNavigation, lastLocation])

    useEffect(() =>{
        if(when) {
            globalDispatch({
                type: 'UPDATE_IS_CONFIRM_NAVIGATION',
                payload: true,
            })
        } else {
            globalDispatch({
                type: 'UPDATE_IS_CONFIRM_NAVIGATION',
                payload: false,
            })
        }
    }, [when])

    useBlocker(handleBlockedNavigation, when)

    return [showPrompt, confirmNavigation, cancelNavigation]
}
