import React from 'react';
import {PanigationProps} from './Panigation.props';
import './Pagination.styles.css';

export const Pagination: React.FC<PanigationProps> = ({
    itemsPerPage,
    currentPage,
    startPoint,
    endPoint,
    maxPage,
    totalItems,
    handlePerRowsChange,
    handlePageChange
}) => {
    const perRowsPages = [5, 10, 20]
    const nextDisabled = currentPage === maxPage ? 'disabled': ''
    const previousDisabled = currentPage === 1 ? 'disabled': ''

    return (
      <nav className='pagination'>
          <div className='pagination-flex'>

              <span className='rowPerPages' style={{marginRight: 8}}>Rows per page</span>
              <select value={itemsPerPage} onChange={handlePerRowsChange} style={{width: 59}}>
                  {
                      perRowsPages.map(page => <option key={page} value={page}>{page}</option>)
                  }
              </select>

              <span className='totalRows' style={{marginRight: 32, marginLeft: 32}}> {startPoint}-{endPoint} of {totalItems}  </span>

              <ul className="pagination-numbers">
                  <li data-testid="first-page" onClick={() => handlePageChange(1)} className={'first-page ' + previousDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path><path fill="none" d="M24 24H0V0h24v24z"></path></svg></li>
                  <li data-testid="previous-page" onClick={() => handlePageChange(currentPage - 1)} className={'previous-page ' + previousDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></li>
                  <li data-testid="next-page" onClick={() => handlePageChange(currentPage + 1)} className={'next-page ' + nextDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></li>
                  <li data-testid="last-page" onClick={() => handlePageChange(maxPage)}  className={'last-page ' + nextDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg></li>
              </ul>
          </div>
      </nav>
    );
};
