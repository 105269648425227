import React, {createContext, useEffect, useReducer, useState} from 'react';
import AppReducer from './AppReducer';
import { useNavigate, useLocation } from "react-router-dom";

const initialState:any = {
   categories: {
     1: { id: 'Threat', text: 'Threat', value: 'Threat'},
     2: { id: 'Clean', text: 'Clean', value: 'Clean'},
     3: { id: 'Unknown', text: 'Unknown', value: 'Unknown'},
   },
   actionRules: [
        { text: 'Potential Threat', value: 0, id: 0 },
        { text: 'Clean email', value: 1, id: 1 },
        { text: 'Spam', value: 2, id: 2 },
   ],
    selectedEmail: {
        subject: '',
        email: '',
        reply_to: '',
        to: '',
        cc: '',
        reported_at: '',
        reported_by: '',
    },
    currentZone: null,
    ca: null,
    zones: [],
    isConfirmedNavigation: false,
    ms365: null
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children, onZoneChanged } : {children:any, onZoneChanged:any}) => {
   const [state, dispatch] = useReducer(AppReducer, initialState);
   const [currentZone, setCurrentZone] = useState(sessionStorage.getItem('currentZone') || localStorage.getItem('currentZone') || /* istanbul ignore next */null)
   const navigate = useNavigate()
   const location = useLocation()

   useEffect(() => {
     const fromNodeChange = sessionStorage.getItem('fromChangeNode') || localStorage.getItem('fromChangeNode')
     if(fromNodeChange === '1') {
       if(location.pathname.includes('/inbox')) {
         navigate('/inbox')
       }
       localStorage.removeItem('fromChangeNode')
       sessionStorage.removeItem('fromChangeNode')
     }
     onZoneChanged()
   }, [currentZone])

   return(
      <GlobalContext.Provider value = {{globalState: state, globalDispatch: dispatch, reloadDispatcher: setCurrentZone}}>
        {  (children) }
      </GlobalContext.Provider>
   )
}
