import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BreadcrumbProps} from './Breadcrumb.props';
import './Breadcrumb.styles.css';
import {Link} from '../../atoms/Link';
import {Image} from "../../atoms/Image";
import {Images} from "../../../configs/images";
import { ReactComponent as BreadscrumRight } from "../../../assets/images/breadscrum_right.svg";

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  navigationLinks,
  setInboxEvent,
  border,
}) => {
  const [hideGoBack, setHideGoBack] = useState('hidden')
  const navigate = useNavigate();
  const navigateUrl = (url:string) => {
    const isInbox = url === '/inbox' || url === '/archived'
    if(isInbox || url === '/automation-rules') {
      navigate(url)
      if(isInbox){ setInboxEvent({id: '', selectedEmail: ''}) }
      setHideGoBack('')
    } else if(url==='/block-list') {
        navigate(url)
        setHideGoBack('')
    } else {
      url = window.location.pathname
      return navigate(url);
    }
  }

  useEffect(() => {
    /* istanbul ignore next */
    if (navigationLinks && navigationLinks[navigationLinks?.length - 1]['name'] !== navigationLinks[0]['name']) {
      setHideGoBack('')
    } else {
      setHideGoBack('hidden')
    }
  }, [navigationLinks])
  return (
      <div className="breadcrumb" style={{border: 'none'}}>
        <Image srcImg={Images.ArrowBack}
               cssClass={`go-back-icon ${hideGoBack}`}
               height={20}
               width={20}
               borderRadius={'4px'}
               border={'1px solid #E0E0E0'}
               margin={'0 7px 0 0'}
               onClick={() => {
                 navigate(-1);
                 let pageURL;
                 if (navigationLinks){
                   /* istanbul ignore next */
                   pageURL = navigationLinks[navigationLinks?.length - 2]['href']
                 }
                 navigateUrl(pageURL);
               }}
               data-testid="go-back-icon"
        />
        {
          navigationLinks/* istanbul ignore next */?.map((bread:any, index) =>  {
              return (<div className={"sub-link"} key={index}>
                <Link onClick={(e:any) => {
                    e.preventDefault();
                    navigateUrl(bread.href)
                }} cssClass={bread.href && bread.href.length > 0 ? 'has-link': ''} text={bread.name} />
                {bread.href && bread.href.length > 0 ? <BreadscrumRight/> : null}
              </div>)
          })
        }
      </div>
  );
};
