import React, {Fragment, useEffect, useState} from "react";
import {Configuration, PublicClientApplication} from '@azure/msal-browser';
import jwt_decode from "jwt-decode";
import {LOGGED_AZURE_TITLE, LOGIN_AZURE_TITLE, STATUS_CODE} from "../../../constants/contants";
import {Modal} from "../../atoms/Modal";
import {getUserLogin, logoutService, storeUserLogin} from "../../../api/emailTriage";
const { forwardRef, useImperativeHandle } = React;


export const Ms365BlockListAuth = forwardRef((props:any, ref:any) => {
    const [accessToken, setAccessToken] = useState<any>("");
    const [userAccount, setUserAccount] = useState<any>(null);
    const [tenantId, setTenantID] = useState<any>(undefined);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [confirmModalContent, setConfirmModalContent] =
        useState({title: '', content: '', action: '', textAgree: '', children: '', variant: ''});
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [successModalContent, setSuccessModalContent] =
        useState({title: '', content: '', action: '', textAgree: '', children: '', variant: ''});
    const parseUserData = props.userData;

    const [msalInstance, setMsalInstance] = useState<any>(null)

    const blockListTokenKey = 'ms365_bl_tk_' + sessionStorage.getItem('currentZone');
    const blockListTenantKey = 'ms365_bl_tenant_' + sessionStorage.getItem('currentZone');

    const defaultScope = ['https://outlook.office365.com/.default'];

    const loginRequest = {
        scopes: defaultScope,
        prompt: "consent"
    }

    const generateConfig = () => {
        return {
            auth: {
                clientId: process.env.REACT_APP_PHISH_FOCUS_AZURE_BLOCK_LIST_APP_ID || process.env.REACT_APP_PHISH_FOCUS_AZURE_APP_ID,
                authority: "https://login.microsoftonline.com/" + (props.tenant || 'common'),
                redirectUri: window.location.origin,
            },
            cache: {
                cacheLocation: "none",
                temporaryCacheLocation: "sessionStorage",
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: {},
                tokenRenewalOffsetSeconds: 120
            }
        };
    }

    useEffect(() => {
        setAccessToken(sessionStorage.getItem(blockListTokenKey))
        setTenantID(props.tenant)
        setMsalInstance(new PublicClientApplication(generateConfig() as Configuration))
    }, []);

    useEffect(() => {
        setMsalInstance(new PublicClientApplication(generateConfig() as Configuration))
    }, [props.tenant]);

    const accessGained = (loginResponse:any) => {
        props.accessGained(loginResponse)
    }

    const setData = (loginResponse:any, showSuccessModal:boolean=true) => {
        let accessToken:any = loginResponse.accessToken;
        let tenantId:any = loginResponse.tenantId;
        setAccessToken(accessToken);
        setTenantID(tenantId);
        sessionStorage.setItem(blockListTokenKey, accessToken);
        accessGained(loginResponse);
        if(showSuccessModal) {
            openSuccessModal(loginResponse);
        }
    }

    const openLoginAzure = () => {
        msalInstance.loginPopup(loginRequest)
            .then((loginResponse: any) => {
                const cacheInfos:any = Array.from(msalInstance.getTokenCache().storage.getCache().cache)
                let refreshToken:any = {secret: ""}
                cacheInfos.forEach((arr:any)=> {
                    if (arr[0].indexOf('refreshtoken') > -1) {
                        refreshToken = JSON.parse(arr[1]).secret
                    }
                });
                props?.onBeforeGetUser(true)
                storeUserLogin({
                    account: loginResponse.account,
                    tenant: loginResponse.account?.tenantId,
                    loginDate: loginResponse.account?.idTokenClaims?.iat,
                    token: refreshToken,
                    refreshToken: refreshToken,
                    userEmail: parseUserData?.email,
                    userId: parseUserData?.userId,
                    userRole: parseUserData?.role,
                    service: 'block_list',
                    action: 'update_new',
                    clientId: process.env.REACT_APP_PHISH_FOCUS_AZURE_BLOCK_LIST_APP_ID || process.env.REACT_APP_PHISH_FOCUS_AZURE_APP_ID,
                    scope: "https://outlook.office365.com/.default",
                }).then((result) => {
                    setData(loginResponse)
                    openSuccessModal(loginResponse)
                    accessGained(loginResponse)
                    props?.onBeforeGetUser(false)
                }).catch((error) => {
                    console.log('Unable to store credential', error)
                    props?.onBeforeGetUser(false)
                });
            })
            .catch((e:any) => {
                console.log('Unable to log user in', e);
            });
    }

    const getTokenFromUserAccount = async (): Promise<any> => {
        return new Promise<any>((resolve, reject) =>{
            getUserLogin({
                userEmail: parseUserData?.email,
                userId: parseUserData?.userId,
                userRole: parseUserData?.role,
                service: 'block_list',
                clientId: process.env.REACT_APP_PHISH_FOCUS_AZURE_BLOCK_LIST_APP_ID || process.env.REACT_APP_PHISH_FOCUS_AZURE_APP_ID,
                scope: "https://outlook.office365.com/.default"
            }).then((response) => {
                const loginResponse = response.data
                setData(loginResponse, false);
                props?.onBeforeGetUser(false)
                resolve(loginResponse)
            }).catch((error:any) => {
                props?.onBeforeGetUser(false)
                reject()
            })
        })
    }

    const requestAccessToken = async (): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            let tokenValid = false;
            let sessionAccessToken = sessionStorage.getItem(blockListTokenKey)
            if(sessionAccessToken) {
                let decodedToken:any = jwt_decode(sessionAccessToken);
                let currentDate = new Date();
                tokenValid = decodedToken?.exp * 1000 > currentDate.getTime()
            }
            if (tokenValid) {
                resolve(sessionAccessToken)
            } else {
                props?.onBeforeGetUser(true)
                getTokenFromUserAccount().then((loginResponse:any)=> {
                    props?.onAfterGetUser(false)
                    let accessToken:any = loginResponse.accessToken;
                    let tenantId:any = loginResponse.tenantId;
                    setAccessToken(accessToken);
                    setTenantID(tenantId);
                    sessionStorage.setItem(blockListTokenKey, accessToken);
                    resolve(loginResponse);
                }).catch((error)=> {
                    props?.onAfterGetUser(false)
                    reject('Error: Cannot revoke Access Token. Reauthenticate user...')
                });
            }
        })
    }

    const logoutAccount = async (payload:any): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            logoutService({
                actionName: 'BLOCK_LIST_LOGOUT_ACCOUNT',
                userEmail: parseUserData?.email,
                userId: parseUserData?.userId,
                userRole: parseUserData?.role,
            }).then((res: any)=> {
                if(res && res.status === STATUS_CODE.OK){
                    sessionStorage.removeItem(blockListTokenKey)
                    resolve('Success')
                }else {
                    reject('Error')
                }
            }).catch(err =>{
                console.log(err)
            });
        });
    }

    const logoutTenant = async (payload:any): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            logoutService({
                actionName: 'BLOCK_LIST_LOGOUT_TENANT',
                userEmail: parseUserData?.email,
                userId: parseUserData?.userId,
                userRole: parseUserData?.role,
            }).then((res: any)=> {
                if(res && res.status === STATUS_CODE.OK){
                    sessionStorage.removeItem(blockListTokenKey)
                    resolve('Success')
                }else {
                    reject('Error')
                }
            }).catch(err =>{
                console.log(err)
            });
        });
    }

    useImperativeHandle(ref, () => ({
        requestAccessToken,
        openConfirmModal,
        accessGained,
        logoutAccount,
        logoutTenant,
    }));

    /***
     * Confirmation window
     */
    const handleConfirmAccept = () => {
        openLoginAzure();
        setConfirmModalOpen(false);
    }

    const handleConfirmCancel = () => {
        setConfirmModalOpen(false);
    }

    const handleCloseSuccessModal = () => {
        setSuccessModalOpen(false);
        props.accessGainedSuccess()
    }

    const openSuccessModal = (params:any) => {
        let title = ""
        let content_html = ""
        title = LOGGED_AZURE_TITLE
        // language=HTML
        content_html = `<div>You’ve logged into <strong>${params?.account?.username}</strong>’s account. You can now use the Block List functionality.</div>
                    <br/>
                    <div>
                        We store Microsoft Purview authorisation for 24 hours. These details will be used by PH2 Phish Focus portal for integrating the block list entries to your organisation mailboxes, you can cancel this anytime by terminating the refresh token under <strong>${params?.account?.username}</strong>’s account in Azure AD. You will be required to log in again to re-authorise after the 24 hours period.
                    </div>`

        const content:any = {
            title: title,
            children: <div dangerouslySetInnerHTML={{ __html: content_html }} />,
            action: '',
            textAgree: 'OK',
            variant: 'success'
        }
        setSuccessModalOpen(true);
        setSuccessModalContent(content);
    }

    const openConfirmModal = (pwExpired:any=false) => {
        handleConfirmAccept()
        return
      //   let content_html = '';
      //   if (pwExpired){
      //       content_html = `<div>
      //     <div>Your Microsoft Purview credentials have expired. Please re-log into Microsoft 365 account with required Microsoft Purview eDiscovery Administrator credentials.</div>
      //     <div>This will allow the purging of emails from the organisation mailboxes on your behalf.</div>
      // </div>`
      //   } else {
      //       content_html = `<div>
      //     <div>To perform this action, please log into Microsoft 365 account with required Microsoft Purview eDiscovery Administrator credentials.</div>
      //     <div>This will allow the purging of emails from the organisation mailboxes on your behalf.</div>
      // </div>`
      //   }
      //   const popupContent:any = {
      //       title: LOGIN_AZURE_TITLE,
      //       children: <div dangerouslySetInnerHTML={{ __html: content_html }} />,
      //       action: 'LoginAzure',
      //       textAgree: 'Log in',
      //       variant: 'default'
      //   }

        // setConfirmModalOpen(true);
        // setConfirmModalContent(popupContent)
    }

    return <div>
        <Modal
            idModal='modal-block-list-login'
            openDialog={confirmModalOpen}
            variant={confirmModalContent?.variant}
            title={confirmModalContent?.title}
            content={confirmModalContent?.content}
            textAgree={confirmModalContent?.textAgree}
            actionAgree={() => {
                handleConfirmAccept()
            }}
            onClose={handleConfirmCancel}
            children={confirmModalContent?.children}
        />
        <Modal
            idModal='modal-phish-clear'
            openDialog={successModalOpen}
            variant={successModalContent?.variant}
            title={successModalContent?.title}
            content={successModalContent?.content}
            textAgree={successModalContent?.textAgree}
            actionAgree={() => {
                handleCloseSuccessModal()
            }}
            onClose={handleCloseSuccessModal}
            children={successModalContent?.children}
        />
    </div>
})
