import React, {useEffect, useState} from 'react'
import {InboxDetailsHeaderProps} from './InboxDetailsHeader.props'
import './InboxDetailsHeader.styles.css'
import {Typography} from "@mui/material";
import Divider from '@mui/material/Divider';
import {ControlDisplay} from "../../molecules/ControlDisplay";
import AttachmentIcon from '@mui/icons-material/Attachment';
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {isMobileOnly} from "react-device-detect";
import {AlertMessage} from "../../atoms/AlertMessage";
import {
    MESSAGES_ANALYSING_DETAIL,
    MESSAGES_INFECTED_DETAIL,
    MESSAGES_NO_THREAT_DETAIL,
    MESSAGES_UNDETERMINED_DETAIL
} from "../../../constants/contants";
import moment from "moment-timezone";
import {Colors} from "../../../configs/colors";
import {Error} from "@mui/icons-material";

const headerDetailStyle = {
    fontSize: 14
}

const footerDetailStyle = {
    display: "flex",
    flexWrap: "wrap",
    rowGap: 2,
    columnGap: 4,
    fontSize: 12
}

export const InboxDetailsHeader: React.FC<InboxDetailsHeaderProps> = (props) => {
    const header = props.header;
    const parsedData = props.parsedData
    const scanResult = props.scanResult;
    const theme = useTheme();
    console.log(parsedData)
    const [timer, setTimer] = useState<any>(null)
    const [emailHeaderMessage, setEmailHeaderMessage] = useState<object>({ type: "", message: "" })

    useEffect(() => {
        if (Object.keys(parsedData.analyzed_overview).length > 0 ) {
            switch(parsedData.analyzed_overview.score){
                case 100:
                    setEmailHeaderMessage({type: "error", message: MESSAGES_INFECTED_DETAIL})
                    break
                case 50:
                    setEmailHeaderMessage({type: "error", message: MESSAGES_UNDETERMINED_DETAIL})
                    break
                case 0:
                    setEmailHeaderMessage({type: "info", message: MESSAGES_NO_THREAT_DETAIL})
                    break
                default:
                    setEmailHeaderMessage({type: "info", message: MESSAGES_ANALYSING_DETAIL})
                    break
            }
        }
    }, [parsedData.analyzed_overview])

    const handleChangeEmailDetailActiveTab = (type:string) => {
        // 1 is index number of Scan Result tab in EmailDetails
        props.setInboxDetailActiveTab(1)
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        return new Promise(() => {
            const timers = setTimeout(async () => {
                const classNameElement = type === 'url' ? 'scan-result-url-box' : 'scan-result-attachment-box'
                const emailDetailElement = document.getElementById('email-detail')
                const scanResultBox = emailDetailElement?.getElementsByClassName(classNameElement)[0]

                if (scanResultBox) {
                    scanResultBox.scrollIntoView({ behavior: 'smooth', block: "center"})
                }
            }, 100)
            setTimer(timers)
        });
    }

  //@ts-ignore
  const tz_name = JSON.parse(localStorage.getItem('userData'))["tz_name"] || 'Etc/UTC';

    return (
        <>
          {isMobileOnly ? <Typography sx={{fontWeight: '500', color: '#909090', fontSize: '12px'}} variant={'body2'}>{header?.email}</Typography> : ""}

          <Box sx={{display: 'inline-flex', alignItems: 'top', width:'100%', boxSizing: 'border-box'}}>

              {header?.hasAttachment ? <AttachmentIcon color={'secondary'} sx={{mr: 1}}/> : null}
              {
                header?.subject ? (
                  <Typography sx={{fontWeight: '700', fontSize: '20px'}}>{header?.subject}</Typography>
                ): <Typography sx={{fontWeight: '400', fontSize: '20px', color: '#cccccc', fontFamily: 'Righteous', lineHeight: '25px'}}>
                  {`< No Subject >`}</Typography>
              }

          </Box>
          <Divider sx={{my: 2}} light/>

          {
              Object.keys(parsedData.analyzed_overview).length > 0 ? <AlertMessage severity={emailHeaderMessage["type"]} content={emailHeaderMessage["message"]}/> : null
          }

          <Box sx={headerDetailStyle} >

              {isMobileOnly ? <Accordion sx={{
                backgroundColor: ' #F5F5F5',
                borderRadius: '4px',
                boxShadow: 'none',
                boxSizing: 'border-box'
              }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color={'primary'}/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{fontSize: '14px', fontWeight: '600'}}>Email Information</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <ControlDisplay
                    label={'Reply To: '}
                    labelAlign={'left'}
                    labelColor={theme.palette.black.light}
                    contentColor={"#011111"}
                    variant={'caption'}
                    content={header.replyTo}
                    contentFontWeight={'500'}
                    labelWidth='80px'/>
                  <ControlDisplay
                    label={'To: '}
                    labelAlign={'left'}
                    labelColor={theme.palette.black.light}
                    contentColor={"#011111"}
                    variant={'caption'}
                    content={header.to}
                    contentFontWeight={'500'}
                    labelWidth='80px'/>
                  <Divider sx={{my: 2}} light/>
                  <Box sx={footerDetailStyle}>
                    <ControlDisplay
                      contentColor={"#011111"}
                      boxMb={0}
                      boxDirection={'row'}
                      label={'Reported at:'}
                      content={moment.utc(header?.reportedAt,'DD/MM/YYYY hh:mm:ss' ).tz(tz_name).format('DD/MM/YYYY hh:mm:ss')}
                      variant={'caption'}
                      contentFontWeight={'500'}
                      labelWidth='80px'
                    />
                    <ControlDisplay
                      contentColor={"#011111"}
                      boxMb={0}
                      variant={'caption'}
                      boxDirection={'row'}
                      label={'Reported by:'}
                      content={header?.reportedBy}
                      contentFontWeight={'500'}
                      labelWidth='80px'
                    />
                  </Box>

                </AccordionDetails>
              </Accordion> : <Box>
                  {/*{*/}
                  {/*   Object.keys(parsedData.analyzed_overview).length > 0 && parsedData.analyzed_overview.score > 0 ? <Box className={'threat-analysis-header-note'}>*/}
                  {/*        <Box sx={{width: "4px", backgroundColor: "#B20007", borderRadius: "4px 0 0 4px"}} />*/}
                  {/*        <Box sx={{margin: "8px", display: "flex", alignItems: "center"}}>*/}
                  {/*            <Box>*/}
                  {/*                <Error sx={{ fontSize: "16px", color: "#B20007", display: "flex", alignItems: "center"}}/>*/}
                  {/*            </Box>*/}
                  {/*            <span style={{marginLeft: "8px", color: "#B20007", fontWeight: 500, lineHeight: "20px"}}>*/}
                  {/*              This email contains a potential threat. Please exercise caution when working with this email.*/}
                  {/*          </span>*/}
                  {/*        </Box>*/}
                  {/*    </Box> : <></>*/}
                  {/*}*/}
                <ControlDisplay label={'From: '} labelAlign={'right'} labelColor={theme.palette.black.light} contentColor={theme.palette.black.main} content={header.email} labelWidth='70px'/>
                <ControlDisplay label={'Reply To: '} labelAlign={'right'} labelColor={theme.palette.black.light} contentColor={theme.palette.black.main} content={header.replyTo} labelWidth='70px'/>
                <ControlDisplay label={'To: '} labelAlign={'right'} labelColor={theme.palette.black.light} contentColor={theme.palette.black.main} content={header.to} labelWidth='70px'/>
                <ControlDisplay label={'CC: '} labelAlign={'right'} labelColor={theme.palette.black.light} contentColor={theme.palette.black.main} content={header.cc} labelWidth='70px'/>
                <Divider sx={{my: 2}} light/>
                <Box sx={footerDetailStyle}>
                  <ControlDisplay
                    boxMb={0}
                    boxDirection={'column'}
                    label={'Reported at:'}
                    content={moment.utc(header?.reportedAt,'DD/MM/YYYY hh:mm:ss' ).tz(tz_name).format('DD/MM/YYYY hh:mm:ss A')}
                    contentFontWeight={'500'}
                    variant={'caption'}
                  />
                  <ControlDisplay
                    boxMb={0}
                    variant={'caption'}
                    boxDirection={'column'}
                    label={'Reported by:'}
                    content={header?.reportedBy}
                    contentFontWeight={'500'}
                  />
                <Box sx={{width: '1px', backgroundColor: '#CCCFCF', height: 'auto'}}></Box>
                <ControlDisplay
                    boxMb={0}
                    variant={'caption'}
                    boxDirection={'column'}
                    label={'Found URLs:'}
                    content={parsedData?.urls}
                    pointerType={'pointer'}
                    onClick={() => handleChangeEmailDetailActiveTab('url')}
                    contentFontWeight={'700'}
                    contentColor={Colors.Blue}
                />
                <ControlDisplay
                    boxMb={0}
                    labelWidth={'120px'}
                    variant={'caption'}
                    boxDirection={'column'}
                    label={'Found Attachment:'}
                    content={parsedData?.attachments}
                    pointerType={'pointer'}
                    onClick={() => handleChangeEmailDetailActiveTab('attachment')}
                    contentFontWeight={'700'}
                    contentColor={Colors.Blue}
                />
                </Box>
                  {
                      header?.subject === '' ? (
                          <Typography sx={{fontWeight: '700', fontSize: '20px'}}>&nbsp;</Typography>
                      ): ('')
                  }
              </Box>}
            </Box>

        </>
    )
}
