import React, {Fragment, useContext, useState} from "react";
import {GlobalContext} from "../context/GlobalState";
import {useLocation, useNavigate} from "react-router-dom";

export default function ChangeWithZone<T>(Component: React.ComponentType<T>) {
  const CE: React.FC<T> = (props) => {
    const { globalState } = useContext(GlobalContext)
    const [currentZone, setCurrentZone] = useState(globalState.currentZone)
  
    const location = useLocation();
    const navigate = useNavigate();
    
    if (currentZone !== globalState.currentZone) {
      setCurrentZone(globalState.currentZone)
      if (location["pathname"].includes('rules/') ) {
        navigate('/automation-rules');
      }
    }
    return (
      <Fragment key={currentZone}>
        {/*//@ts-ignore*/}
        {<Component currentZone {...props} />}
      </Fragment>
    )
  }
  return CE
}
