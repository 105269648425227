import React from 'react'
import { MiddleDashboardProps} from './MiddleDashboard.props'
import './MiniDashboard.styles.css';

import {MiddleReportedMessagesCard} from "../../molecules/MiddleReportedMessagesCard";
import {MiddleDonutCard} from "../../molecules/MiddleDonutCard";
import {MiddleTreeMapCard} from "../../molecules/MiddleTreeMapCard";

import Grid from '@mui/material/Grid';



export const MiddleDashboard: React.FC<MiddleDashboardProps> = ({
  reportedMessagesData,
  donutReportedData,
  isLoading,
  treeMapData
}
) => {
  return (
    <Grid
      container
      spacing={2.5}
    >
         <Grid
           item
           xs={3}
         >
           <MiddleReportedMessagesCard
             data={reportedMessagesData}
             isLoading={isLoading}
           />
           <MiddleDonutCard
             data={donutReportedData}
             isLoading={isLoading}
           />
         </Grid>
        <Grid
          item
          xs={9}
        >
         <MiddleTreeMapCard
           treeMapData={treeMapData}
           isLoading={isLoading}
         />
        </Grid>
    </Grid>
  )

}
