import React from 'react';
import {ImageProps} from './Image.props';
import './Image.styles.css';

export const Image: React.FC<ImageProps> = ({
  srcImg,
  /* istanbul ignore next */
  width = '200px',
  /* istanbul ignore next */
  height = 'auto',
  /* istanbul ignore next */
  backgroundColor = 'transparent',
  /* istanbul ignore next */
  borderRadius = '0px',
  /* istanbul ignore next */
  border = 0,
  /* istanbul ignore next */
  margin = 0,
  onClick,
  cssClass,
  color,
  /* istanbul ignore next */
  alt = '',
  ...props
}) => {
  return (
    <img
      alt={alt}
      className={`image-style ${cssClass}`}
      src={srcImg} style={{
        width,
        height,
        backgroundColor,
        borderRadius,
        border,
        margin,
        color
      }}
      onClick={onClick}
      {...props}
    />
  );
};
