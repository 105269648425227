import React, {useContext, useEffect, useState} from 'react';
import { SettingsProps } from './Settings.props';
import './Settings.styles.css';
import { PageHeader } from '../../components/organisms/PageHeader';
import { SettingCard } from '../../components/molecules/SettingCard';
import { Box } from "@mui/material";
import {getSettings} from "../../api/emailTriage";
import {
    ADMIN_ROLE,
    SETTING_TYPE_REPORTED_EMAILS_ACTIONS,
    SETTING_TYPE_AUTOMATION_RULE,
    SETTING_BLOCK_LIST
} from "../../constants/contants";
import ChangeWithZone from "../../hoc/ChangeWithZone";
import {BaseDomainSetting} from "../../components/organisms/BaseDomainSetting";
import {GlobalContext} from "../../context/GlobalState";

const navigationLinks = [
  {
    name: 'Settings'
  }
]

export const SettingFull: React.FC<SettingsProps> = () => {
  const [statusSettings, setStatusSetting] = useState<any>({})
  const [loadingPage, setLoadingPage] = useState(false)
  const [roleUser, setRoleUser] = useState('')

  const localStorageUserData = localStorage.getItem('userData');
  const { globalState } = useContext(GlobalContext);
  const isMS365 = globalState.ms365

  const settingsArr: any = isMS365 ?
      [
        {
          title: 'Actions for reported emails',
          description: 'Enable Phish Clear functionality',
          type: SETTING_TYPE_REPORTED_EMAILS_ACTIONS,
        },
        {
          title: 'Automation rules',
          description: 'Enable automation rules',
          type: SETTING_TYPE_AUTOMATION_RULE,
        },
          {
              title: 'Block list',
              description: 'Enable Block List functionality',
              type: SETTING_BLOCK_LIST,
          }
      ] : [{
          title: 'Automation rules',
          description: 'Enable automation rules',
          type: SETTING_TYPE_AUTOMATION_RULE,
        }]
  ;

  useEffect(() => {
    if(localStorageUserData) {
      const parseUserData = JSON.parse(localStorageUserData)
      setRoleUser(parseUserData["role"])
    }
    setLoadingPage(true)
    getSettings().then((response) => {
      const data:any = response.data
      const statuses = {}
      for(let i = 0; i < data.length; i++) {
        Object.assign(statuses, {[data[i]['setting_type']]: data[i]['setting_value']})
      }
      setStatusSetting(statuses)
      setLoadingPage(false)
    })
  },[])

  return (
      <Box className='setting-page page-content-wrapper' >
        <PageHeader pageTitle={navigationLinks[0].name} />
        <Box className='page-content-body'>
            {
                settingsArr ? settingsArr.map((value: any , index: any) =>
                  <SettingCard key={index} title={value.title} description={value.description} status={statusSettings[value.type]} type={value.type} loading={loadingPage}/>) : ''
            }
          {roleUser === ADMIN_ROLE ? <BaseDomainSetting/> : null}
        </Box>
      </Box>
  );
};

export const Settings = ChangeWithZone(SettingFull);
