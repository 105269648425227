import React from 'react';
import {TabGroupProps} from './TabGroup.props';
import './TabGroup.styles.css';
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab, {TabProps} from "@mui/material/Tab";
import {styled} from "@mui/material/styles";
import {Chip} from "@mui/material";
import {isMobileOnly} from "react-device-detect";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTab = styled((props: TabProps) => (<Tab {...props} />
))(({theme}) => ({
    '&.MuiTab-root': {
      color: theme.palette.black.light,
      fontWeight: 600,
    },
    '&.MuiTab-root div':{
      display:'flex',
      alignItems:"center",
    },
    '&.MuiTab-root.Mui-selected': isMobileOnly? {
      color: '#345C8B',
      fontWeight: 700,
      backgroundColor: '#E7EBF0',
      borderRadius: "24px"
    } : {
      color: '#345C8B',
      fontWeight: 700
    }
  }
));

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{pt: 3, pr: 3, pl: 3, pb: 16}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number, item: string) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'data-value': `${item}`
  };
}

export const TabGroup: React.FC<TabGroupProps> = ({tabTitles, tabContents, setActiveFilter, setValue, tabValue, statistic}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setActiveFilter(event.currentTarget.getAttribute('data-value'))
  };

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 0, borderColor: 'divider'}}>
        <Tabs sx={isMobileOnly ? {
          [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': {
            opacity: 0.3,
            display: 'flex',
            alignItems: "center",
          },
          },
          display: 'flex',
          alignItems: 'center',
          mr: 1,
          ml: 1} : {}}  indicatorColor={"secondary"} value={tabValue} TabIndicatorProps={{
          style: isMobileOnly ? { display: 'none' } : {}
        }}
              onChange={handleChange}
              // Disabled for causing errors in UT
              // variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile={true}
              aria-label="visible arrows tabs example" >
          {
            tabTitles?.map((item: any, index: any) => {
              return (
                <CustomTab key={index} label={item} sx={{px: 3, py: 2}} icon={statistic ? <Chip sx={{backgroundColor: '#E7EBF0', color: '#000'}} size={'small'} label={statistic ? statistic[item] : ''}/> : ''} iconPosition="end" {...a11yProps(index, item)} />
                
              )
            })
          }
        </Tabs>
      </Box>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        {
          tabContents?.map((item: any, index: any) => {
            return (
              <TabPanel value={tabValue} key={index} index={index}>
                {item}
              </TabPanel>
            )
          })
        }
      </Box>
    </Box>
  );
};
