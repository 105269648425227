import React, {ChangeEvent, useEffect, useState} from 'react';
import './TextInput.styles.css';
import {TextInputProps} from './TextInput.props';


export const TextInput: React.FC<TextInputProps> = ({
  width,
  height,
  typeInput,
  placeholderInput,
  borderColor = '',
  nameInput,
  borderRadius,
  padding = '0 16px',
  margin = 0,
  isDisabled= false,
  color,
  fontWeight,
  fontSize,
  onChangeInput = () => {},
  onKeyDownEvent,
  value,
}) => {
  const [inputValue, setInputValue] = useState('');
  useEffect(() =>{
    setInputValue(value)
  },[value])
  const handleTextInputValue= (event:ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    onChangeInput && onChangeInput(event.target.value)
  }
  return (
    <input className='text-input-style' onKeyDown={onKeyDownEvent} onChange={handleTextInputValue} disabled={isDisabled} value={inputValue} type={typeInput} placeholder={placeholderInput} name={nameInput} style={{ width, height, borderRadius, borderColor, padding, margin, color,fontWeight,fontSize }}/>
  );
};
