import React from 'react'
import {TopTreeMapProps} from './TopTreeMap.props'
import './TopTreeMap.styles.css';

import Box from '@mui/material/Box';
import {Typography} from "@mui/material";
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

export const TopTreeMap: React.FC<TopTreeMapProps> = ({
                                                        bgColor,
                                                        name,
                                                        percent,
                                                        count

                                                      }) => {
  
  const textTooltip = name + ' | ' + percent + '% | ' + count
  return (
    <Tooltip title={textTooltip} placement="top">
    <Box sx={{
      boxSizing: 'border-box',
      backgroundColor: bgColor,
      height: 80,
      width: '100%',
      padding: 1,
    }}
         className={'top-treemap-text'}
    >
      <Stack
             justifyContent={'space-between'}

      >
        <Stack direction={'row'}
               justifyContent={'space-between'}
               alignItems={'center'}
        >
          <Typography
            className={'text-priority'}
            align={'center'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF',
            }}
          >
            {name }
          </Typography>
          <Typography
            className={'text-percent'}
            align={'center'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF',
            }}
          >
            {percent + ' %'}
          </Typography>
        </Stack>
        <Typography
          className={'text-number'}
          align={'left'}
          sx={{
            fontFamily: 'Montserrat',
            fontWeight: 700,
            fontSize: '28px',
            lineHeight: '34px',
            color: '#FFFFFF',
            padding: '12px 0 0 0'
          }}
        >
          {count}
        </Typography>
      </Stack>
    </Box>
    </Tooltip>
  )
  
}
