import React from 'react'
import {MiddleReportedMessagesCardProps} from './MiddleReportedMessagesCard.props'
import './MiddleReportedMessagesCard.styles.css';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';


import {ColumnChart} from "../ColumnChart";
import Skeleton from "@mui/material/Skeleton";


export const MiddleReportedMessagesCard: React.FC<MiddleReportedMessagesCardProps> = ({
  isLoading = true,
  data
}
) => {
    let isLoadingMessageCard;
    if(data[0].months !== null){
        isLoadingMessageCard = false;
    } else {
        isLoadingMessageCard = true;
    }
  return (
    <Grid sx={{
              width: '100%',
              height: 250,
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '4px',
              display: 'flex',
              padding: '16px',
              alignItems: 'center',
              boxSizing: 'border-box',
              margin: '20px 0',
            }}
          alignItems={'center'}
         container
    >
      
      <Grid item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left'
            }}
            >
        <Box>
          <Typography
            align={'left'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '20px',
              lineHeight: '24px',
              color: '#808888',
              minWidth: 190
            }}
          >
            {(isLoading || isLoadingMessageCard) ? <Skeleton variant="text" sx={{ fontSize: '20px' }} /> : "Reported Messages"}
          </Typography>
  
          <Typography
            align={'left'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#4D5858',
            }}
          >
            {(isLoading || isLoadingMessageCard) ? <Skeleton variant="text" sx={{ fontSize: '12px' }} /> : "Total messages |  6-Month Period"}
          </Typography>
        </Box>
      </Grid >
      <Grid item
            xs={12}
          height={170}
       
      >
        {(isLoading || isLoadingMessageCard) ? <Skeleton variant="rounded" height={"100%"}  /> : <ColumnChart data={data}/>}
      </Grid>
    </Grid>
  );
}
