import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import './AddBlockListModal.styles.css';
import {Box} from "@mui/material";
import {BLOCK_LIST_API_ACTIONS} from "../../../constants/contants";
import {acquireBlockListEntries} from "../../../api/emailTriage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {CancelRounded} from "@mui/icons-material";
import {BlockListEntry} from "../BlockListEntry";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";

export const AddBlockListModal = forwardRef((props:any, ref:any) => {
    const [tableBlockListData, setTableBlockListData] = useState<any>({});

    const blockListTokenKey = 'ms365_bl_tk_' + sessionStorage.getItem('currentZone');

    const localStorageUserData = localStorage.getItem('userData');
    let tz_name = 'Etc/UTC';
    let tz_offset = 0;
    if(localStorageUserData) {
        const parseUserData = JSON.parse(localStorageUserData)
        tz_name = parseUserData["tz_name"]
        tz_offset = moment.tz(tz_name).utcOffset()
    }

    const standardizeTableData = (sourceData:any) => {
        const gen = (sourceArr:any, desArr:any) => {
            sourceArr?.forEach((item:any) => {
                desArr.push({
                    attr_type: item['attr_type'],
                    entry_id: item['entry_id'],
                    id: item['id'],
                    ref_id: item['ref_id'],
                    status: item['status'],
                    value: item['value'],
                    details: item['details'],
                    expire_code: 0,
                    selected: false
                })
            })
        }

        const newSource:any = {
            valid: { senders: [], urls: [], file_hashes: [], total: 0, selected_state: 0},
            invalid: {senders: [], urls: [], file_hashes: [], total: 0, selected_state: 0}
        }

        gen(sourceData?.senders?.valid, newSource.valid.senders)
        gen(sourceData?.urls?.valid, newSource.valid.urls)
        gen(sourceData?.file_hashes?.valid, newSource.valid.file_hashes)

        gen(sourceData?.senders?.invalid, newSource.invalid.senders)
        gen(sourceData?.urls?.invalid, newSource.invalid.urls)
        gen(sourceData?.file_hashes?.invalid, newSource.invalid.file_hashes)

        newSource.valid.total = newSource.valid.senders.length + newSource.valid.urls.length +
            newSource.valid.file_hashes.length

        newSource.invalid.total = newSource.invalid.senders.length + newSource.invalid.urls.length +
            newSource.invalid.file_hashes.length

        return newSource
    }

    const getValidBlockList = () => {
        const result:any = []
        tableBlockListData.valid.senders.forEach((item:any) => {
            if(item['selected']) {result.push(item)}
        })
        tableBlockListData.valid.urls.forEach((item:any) => {
            if(item['selected']) {result.push(item)}
        })
        tableBlockListData.valid.file_hashes.forEach((item:any) => {
            if(item['selected']) {result.push(item)}
        })
        return result
    }

    useEffect(()=> {
        const newSource:any = standardizeTableData(props.payload)
        setValidNumber(newSource.valid.total)
        setInvalidNumber(newSource.invalid.total)
        setTableBlockListData(newSource);
    }, [props.payload])

    const saveBlockList = async (): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            const blockListPayload = getValidBlockList()
            const token:any = sessionStorage.getItem(blockListTokenKey)
            const accessTokenDecoded:any = jwt_decode(token)
            console.log("blockListPayload", blockListPayload)
            if(blockListPayload.length===0) {
                reject('Error: Empty')
            }
            acquireBlockListEntries({
                blocklist: blockListPayload,
                action: BLOCK_LIST_API_ACTIONS.CREATE_BLOCK_LIST,
                accessToken: sessionStorage.getItem(blockListTokenKey),
                emailId: props.emailId,
                upn: accessTokenDecoded?.upn,
                tzOffset: tz_offset
            }).then((response:any) => {
                const currentTime = new Date().getTime();
                sessionStorage.setItem('lastSync', currentTime.toString())
                resolve(response)
            }).catch((error:any) => {
                console.log(error)
                reject(error)
            });
        });
    }

    useImperativeHandle(ref, () => ({
        saveBlockList
    }));

    const [validNumber, setValidNumber] = useState(0);
    const [invalidNumber, setInvalidNumber] = useState(0);

    const onSelectEntryChange = (type:any, index:any) => {
        const data = JSON.parse(JSON.stringify(tableBlockListData))
        data['valid'][type][index]['selected'] = !data['valid'][type][index]['selected']
        setTableBlockListData(data)
    }

    const onSelectExpireDay = (type:any, index:any, value:any) => {
        const data = JSON.parse(JSON.stringify(tableBlockListData))
        data['valid'][type][index]['expire_code'] = value
        setTableBlockListData(data)
        console.log(data)
    }

    return (
       <Box className={'create-block-list-container'}>
           <div className={"blocklist-header"}>
               Below are the <strong>Sender</strong>, <strong>URLs</strong> and <strong>File hash</strong> entries found in the message. Choose the valid entry that you would like to add to Block List.
           </div>
           {
               validNumber > 0 &&
               <div className={"blocklist-valid-entry-wrapper"}>
                   <div className={"blocklist-valid-title"}><CheckCircleIcon fontSize={"large"} htmlColor={'#008545'}/>{validNumber + ' ' + (validNumber > 1 ? 'entries' : 'entry')} can be added to the block list</div>
                   {
                       tableBlockListData?.valid?.senders?.length > 0 && <div className={"blocklist-entries"}>
                           <div className={"blocklist-attribute-title"}>Sender</div>
                           {
                               tableBlockListData?.valid?.senders?.map((item:any, index:number) => {
                                    return <BlockListEntry
                                        key={index}
                                        checked={item?.selected}
                                        value={item?.value}
                                        attrType={item?.attr_type}
                                        expireCode={item?.expire_code}
                                        status={item?.status}
                                        enable={true}
                                        onChange={() => onSelectEntryChange('senders', index)}
                                        onExpireDateChanged={(expireCode:any) => onSelectExpireDay('senders', index, expireCode)}
                                    />
                               })
                           }
                       </div>
                   }
                   {
                       tableBlockListData?.valid?.urls?.length > 0 && <div className={"blocklist-entries"}>
                           <div className={"blocklist-attribute-title"}>URL ({tableBlockListData?.valid?.urls?.length})</div>
                           {
                               tableBlockListData?.valid?.urls?.map((item:any, index:number) => {
                                   return <BlockListEntry
                                       key={index}
                                       checked={item?.selected}
                                       value={item?.value}
                                       attrType={item?.attr_type}
                                       expireCode={item?.expire_code}
                                       status={item?.status}
                                       enable={true}
                                       onChange={() => onSelectEntryChange('urls', index)}
                                       onExpireDateChanged={(expireCode:any) => onSelectExpireDay('urls', index, expireCode)}
                                   />
                               })
                           }
                       </div>
                   }
                   {
                       tableBlockListData?.valid?.file_hashes?.length > 0 && <div className={"blocklist-entries"}>
                           <div className={"blocklist-attribute-title"}>File Hash ({tableBlockListData?.valid?.file_hashes?.length})</div>
                           {
                               tableBlockListData?.valid?.file_hashes?.map((item:any, index:number) => {
                                   return <BlockListEntry
                                       key={index}
                                       checked={item?.selected}
                                       value={item?.value}
                                       attrType={item?.attr_type}
                                       expireCode={item?.expire_code}
                                       status={item?.status}
                                       enable={true}
                                       onChange={() => onSelectEntryChange('file_hashes', index)}
                                       onExpireDateChanged={(expireCode:any) => onSelectExpireDay('file_hashes', index, expireCode)}
                                   />
                               })
                           }
                       </div>
                   }
               </div>
           }
           {
               invalidNumber > 0 &&
               <div className={"blocklist-invalid-entry-wrapper"}>
                   <div className={"blocklist-invalid-title"}><CancelRounded fontSize={"large"} htmlColor={'#C80000'}/>{invalidNumber + ' ' + (invalidNumber > 1 ? 'entries' : 'entry')} cannot be added to the block list</div>
                   {
                       tableBlockListData?.invalid?.senders?.length > 0 && <div className={"blocklist-entries"}>
                           <div className={"blocklist-attribute-title"}>Sender</div>
                           {
                               tableBlockListData?.invalid?.senders?.map((item:any, index:number) => {
                                    return <BlockListEntry
                                        key={index}
                                        checked={item?.selected}
                                        value={item?.value}
                                        attrType={item?.attr_type}
                                        expireCode={item?.expire_code}
                                        status={item?.status}
                                        enable={false}
                                    />
                               })
                           }
                       </div>
                   }
                   {
                       tableBlockListData?.invalid?.urls?.length > 0 && <div className={"blocklist-entries"}>
                           <div className={"blocklist-attribute-title"}>URL ({tableBlockListData?.invalid?.urls?.length})</div>
                           {
                               tableBlockListData?.invalid?.urls?.map((item:any, index:number) => {
                                   return <BlockListEntry
                                       key={index}
                                       checked={item?.selected}
                                       value={item?.value}
                                       attrType={item?.attr_type}
                                       expireCode={item?.expire_code}
                                       status={item?.status}
                                       enable={false}
                                   />
                               })
                           }
                       </div>
                   }
                   {
                       tableBlockListData?.invalid?.file_hashes?.length > 0 && <div className={"blocklist-entries"}>
                           <div className={"blocklist-attribute-title"}>File Hash ({tableBlockListData?.invalid?.file_hashes?.length})</div>
                           {
                               tableBlockListData?.invalid?.file_hashes?.map((item:any, index:number) => {
                                   return <BlockListEntry
                                       key={index}
                                       checked={item?.selected}
                                       value={item?.value}
                                       attrType={item?.attr_type}
                                       expireCode={item?.expire_code}
                                       status={item?.status}
                                       enable={false}
                                   />
                               })
                           }
                       </div>
                   }
               </div>
           }
       </Box>
    )
})
