import React from 'react';
import './PageNotFound.styles.css';
import { Images} from '../../configs/images';
import {Image} from '../../components/atoms/Image';
import {Text} from "../../components/atoms/Text";

export const PageNotFound: React.FC = () => {
    return (
        <div className={'notfound'}>
            <div style={{padding: '20px'}}>
                <span style={{color:'white', fontSize: '18px', fontFamily: 'Righteous, serif'}}>Phriendly Phish Focus Centre</span>
            </div>
            <div className={'center'}>
                <Image width={'100%'} srcImg={Images.NotFoundIcon}/>
                <Text width={'100%'}  fontWeight={700} fontSize={32} children={'404 Error'} color={'#9cc145'}/>
                <Text width={'100%'}  color={'#73a2ae'} fontSize={18} children={"The Page you're looking for doesn't appear to exist."} />
            </div>
            <div className={'wave'}>
                <Image srcImg={Images.Wave} width={'100%'}/>
            </div>
        </div>
    )
}