import React from 'react'
import {AccordionButtonProps} from '../AccordionButton'
import './AccordionButton.styles.css';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {useNavigate} from "react-router-dom";

export const AccordionButton: React.FC<AccordionButtonProps> = ({
  idComponent,
  children,
  title,
  icon,
  actived
}) => {
  const navigate = useNavigate();
  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    backgroundColor: `${actived ? '#EBF9FA' : null}`,
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
      // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
    />
  ))(() => ({
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  }));

  const currentUrl = window.location.pathname
  const isinboxDetail = actived && currentUrl.split('/inbox/').length === 2

  return (
    <Accordion id={idComponent} className={`${actived ? 'active' : ''}`} defaultExpanded={actived} sx={{
      margin: '0px 0px 4px 12px',
      border: 'unset',
      borderRadius: '4px',
      boxShadow: 'none',
      boxSizing: 'border-box'
    }}>
      <AccordionSummary
        className={`${actived && (title.href === currentUrl || isinboxDetail) ? 'active' : ''}`}
        expandIcon={<ExpandMoreIcon color={'primary'}/>}
        aria-controls="panel1a-content"
        sx={{padding: '10px'}}
      >
        <Typography sx={{fontSize: '14px', fontWeight: '500', color: '#7C8989'}} onClick={() => navigate(title.href)}>
          {icon ? icon : null}
          {title.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={'menu-child'}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
