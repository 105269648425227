import React from 'react'
import {MiddleTreeMapCardProps} from './MiddleTreeMapCard.props'
import './MiddleTreeMapCard.styles.css';

import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {TreeMapChartCard} from "../TreeMapChartCard";
import {TopTreeMap} from "../TopTreeMap";
import {LegendChart} from "../LegendChart";

import Skeleton from "@mui/material/Skeleton";
import {NoDataCard} from "../NoDataCard";


export const MiddleTreeMapCard: React.FC<MiddleTreeMapCardProps> = ({
                                                                      isLoading,
                                                                      treeMapData
                                                                    }) => {

  const currentMonth = treeMapData && treeMapData.currentMonth
  const priorityArr = [
    {
      bgColor: '#FF3D44',
      name: 'High',
      percent: treeMapData && treeMapData.highPriorityPercent,
      count: treeMapData && treeMapData.highPriority
    },
    {
      bgColor: '#FFD84C',
      name: 'Medium',
      percent: treeMapData && treeMapData.mediumPriorityPercent,
      count: treeMapData && treeMapData.mediumPriority
    },
    {
      bgColor: '#6BD0FF',
      name: 'Low',
      percent: treeMapData &&  treeMapData.lowPriorityPercent,
      count: treeMapData && treeMapData.lowPriority
    },
      {
          bgColor: '#909090',
          name: 'Unknown',
          percent: treeMapData &&  treeMapData.unknownPriorityPercent,
          count: treeMapData && treeMapData.unknownPriority
      }
  ]
  let countData = 0
  /* istanbul ignore next */
  priorityArr?.map((item:any) => { if(item.count > 0){countData += 1}})
  const hasData = countData > 0 ? true : false
  return (
    <Grid sx={{
      width: '100%',
      height: 585,
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      padding: '16px',
      boxSizing: 'border-box',
      margin: '20px 0',
    }}
          container
    >
      <Grid item xs={12} sx={{width: '100%'}}>
        <Stack spacing={2}
               direction='row'
               justifyContent="space-between"
               alignItems="center"
        >
          <Box>
            <Typography
              align={'left'}
              sx={{
                fontFamily: 'Montserrat',
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#808888',
                minWidth: 270
              }}
            >
              {isLoading? <Skeleton variant="text" sx={{ fontSize: '20px' }} /> : " Reported By Priority"}

            </Typography>

            <Typography
              align={'left'}
              sx={{
                fontFamily: 'Montserrat',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '15px',
                color: '#4D5858',
              }}
            >
              {isLoading? <Skeleton variant="text" sx={{ fontSize: '20px' }} /> : "Reported Priority Over Status | " + currentMonth}
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid container item xs={12} sx={{margin: '12px 0'}}>

        {isLoading ? <Skeleton variant="rectangular" width={'100%'} height={60} /> : (hasData ? priorityArr.map((ele)=> {
          return (
            (ele.percent === 0) ?  "" : <Grid item key={ele.name} xs={(ele.percent / 100 * 12)}>
            <TopTreeMap bgColor={ele.bgColor}
                        name={ele.name}
                        count={ele.count}
                        percent={ele.percent}
            />
          </Grid>
          )
        }) : priorityArr.map((ele)=> {
            return (
                <Grid item key={ele.name} xs={(3)}>
                    <NoDataCard treeMapValue={ele.name} height={'79px'} color={'#B3B8B8'} textAlign={'left'} titleFontSize={'28px'} padding={'7px'} border={'1px solid #808888'} fontWeight={'700'}></NoDataCard>
                </Grid>
            )
        }))}
      </Grid>
      <Grid item xs={12} minHeight={360}>

        {isLoading ?<Skeleton variant="rectangular" width={'100%'} height={'100%'} /> : treeMapData && <TreeMapChartCard isLoading={isLoading} data={treeMapData}/>}
      </Grid>
      {isLoading ?<Skeleton variant="rectangular" width={'100%'} height={16} /> : ( hasData ? <LegendChart number={2}/> : '' )}
    </Grid>
  );
}
