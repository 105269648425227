import { SETTING_TYPE_AUTOMATION_RULE, SETTING_TYPE_REPORTED_EMAILS_ACTIONS, SETTING_BLOCK_LIST } from '../constants/contants';

const { createContext, useState, useContext } = require("react");

export const RoleContext = createContext()

export const defaultSettings = {
  [SETTING_TYPE_AUTOMATION_RULE]: false,
  [SETTING_TYPE_REPORTED_EMAILS_ACTIONS]: false,
  [SETTING_BLOCK_LIST]: false,
};

export const RoleProvider:any = ({children} : {children:any}) => {
  const [role, setRole] = useState()
  const [setting, setSetting] = useState(defaultSettings)
  return <RoleContext.Provider value={{role, setRole, setting, setSetting} }>{children}</RoleContext.Provider>
}

export const useRoleContext = () => {
  const ctx = useContext(RoleContext)
  if (!ctx) {
    throw new Error("need provider");
  }
  return ctx
}
