import React ,{useState} from 'react'
import { SlickSliderProps } from './SlickSlider.props';
import './SlickSlider.styles.css';
import Slider from "react-slick";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const SlickSlider: React.FC<SlickSliderProps> = ({
  sliderItems,
  handleChangeSlide,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <IconButton size={'medium'}>
      <ArrowForwardIosIcon fontSize={'small'}/>
    </IconButton>,
    prevArrow: <IconButton size={'medium'}>
      <ArrowBackIosNewIcon fontSize={'small'}/>
    </IconButton>
  };

  return (
      <div className='slick-slider'>
        <Slider {...settings}
                beforeChange={(currentSlide, nextSlide) => {
                  handleChangeSlide([6,3,1][nextSlide]);
                }}
        >
          {
            sliderItems?.map((slideItem:any, index:any) => {
              return <div key={index} className='slick-slide'>{slideItem}</div>
            })
          }
        </Slider>
      </div>
  )
}
