import React, {useContext, useEffect, useState} from 'react';

import {ZoneSwitcherProps} from "./ZoneSwitcher.props";
import './ZoneSwitcher.style.css';
import {GlobalContext} from "../../../context/GlobalState";

import { TreeDropDown} from "../../molecules/TreeDropDown";

function findById(array:any, id:any) {
  for (const item of array) {
    if (item.id.toString() === id.toString()) return item;
    /* istanbul ignore next */
    if (item.children?.length) {
      const innerResult:any = findById(item.children, id);
      if (innerResult) return innerResult;
    }
  }
}

export  const ZoneSwitcher: React.FC<ZoneSwitcherProps> = ({/* istanbul ignore next */dataSource=[], ...props}) => {
    const [dataList, setDataList] = useState<any[]>([])
    const [masterNode, setMasterNode] = useState<any>({})
    const rawDataSource = dataSource;
    let currentZone  = sessionStorage.getItem('currentZone') || localStorage.getItem('currentZone') || ''
    let { globalDispatch, reloadDispatcher } = useContext(GlobalContext);

    // const getNestedChild = (result:any, node:any) => {
    //     result.push(node)
    //     if (node.children.length === 0) {
    //       return
    //     }
    //     node.children.forEach((item:any, index:any) => {
    //       getNestedChild(result, item)
    //     })
    // }

    // const serializeTree = (tree:any, node:any) => {
    //     const result:any = []
    //     let tempTree:any = []
    //     if (!node) { return []}
    //     const pathArr = node.path.trim().split(' ')
    //     pathArr.forEach((item:any, index:number) => {
    //         item = parseInt(item)
    //         if (tempTree.length === 0) {
    //             tempTree = tree[0]
    //             let _child:any = []
    //             getNestedChild(_child, tree[0])
    //           let childList: any = [];
    //           _child.forEach((element:object)=>{
    //             childList.push(JSON.stringify(element["id"]))
    //           })
    //           // console.log(childList)
    //
    //             // _child.shift()
    //             result.push({
    //                 master: {id: tempTree.id, name: tempTree.name, path: tempTree.path},
    //                 child: _child
    //             })
    //         } else {
    //             tempTree = tempTree.children[item]
    //              let _child:any = []
    //             getNestedChild(_child, tempTree)
    //             let childList: any = [];
    //             _child.forEach((element:object)=>{
    //               childList.push(element["id"])
    //             })
    //           // console.log(childList)
    //             // _child.shift()
    //             result.push({
    //                 master: {id: tempTree.id, name: tempTree.name, path: tempTree.path},
    //                 child: _child
    //             })
    //         }
    //     })
    //     return result
    // }

    useEffect(() => {
      setDataList(rawDataSource)
      let currentMaster = findById(rawDataSource, parseInt(currentZone))
      if (currentMaster) {
        setMasterNode({id: currentMaster.id, name: currentMaster.name, path: currentMaster.path, ms365: currentMaster.ms365})
      } else {
        /* istanbul ignore next */
        setMasterNode({id: rawDataSource[0]?.id, name: rawDataSource[0]?.name, path: rawDataSource[0]?.path, ms365: rawDataSource[0]?.ms365})
      }
    }, [dataSource]);

    const onItemClick = (node:any):void => {
      setMasterNode({id: node.id, name: node.name, path: node.path, ca: node.ca, ms365: node.ms365})
          globalDispatch({
            type: 'UPDATE_SELECTED_ZONE_ID',
            payload: node.id
          })
            globalDispatch({
                type: 'UPDATE_SELECTED_ZONE_CA',
                payload: node.id
            })
            globalDispatch({
                type: 'UPDATE_MS365_CURRENT_ZONE',
                payload: node.ms365
            })
          localStorage.setItem('currentZone', node.id)
          sessionStorage.setItem('currentZone', node.id)
          localStorage.setItem('isMS365', node.ms365) // hardcode ms365
          sessionStorage.setItem('isMS365', node.ms365) // hardcode ms365
          localStorage.setItem('startZoneDate', node.ca)
           sessionStorage.setItem('startZoneDate', node.ca)
          localStorage.setItem('fromChangeNode', "1")
          sessionStorage.setItem('fromChangeNode', "1")
          reloadDispatcher(node.id)
    }
    return (
      <div className="zone-tree-wrapper">
        {/*<CustomDropDown index={1} masterNode={masterNode} dataSource={dataList} onItemClick={onItemClick} />*/}
     <TreeDropDown masterNode={masterNode} dataSource={dataList} onItemClick={onItemClick}/>
      </div>
    );
};
