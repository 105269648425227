import React from 'react';
import {SyncedStatusProps} from './SyncedStatus.props';
import './SyncedStatus.styles.css';
import Box from "@mui/material/Box";

export const SyncedStatus: React.FC<SyncedStatusProps> = ({value, icon, valueColor}) => {

  return (
      <Box className={'synced-status-card'}>
        {icon}
        <span style={{
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: 700,
            marginLeft: '8px',
            position: 'relative',
            top: '2px',
            color: valueColor
        }}>
            {value}
        </span>
      </Box>
  );
};
