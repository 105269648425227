import React, {useState} from 'react'
import {BottomCategoryCardProps} from './BottomCategoryCard.props'
import './BottomCategoryCard.styles.css';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {DynamicColumnChart} from "../DynamicColumnChart";
import IconButton from '@mui/material/IconButton';
import {LegendChart} from '../LegendChart'
import {SlickSlider} from '../../organisms/SlickSlider';
import Skeleton from "@mui/material/Skeleton";


export const BottomCategoryCard: React.FC<BottomCategoryCardProps> = ({
  isLoading,
  bottomCard
                                                                      }
) => {

  const [currrentSlide, setCurrrentSlide] = useState(6)

  const changeMonth = (index : any) => {
    setCurrrentSlide(index)
  }
    // check loading first times
    let isLoadingCategoryCard;
    if(bottomCard !== null){
        isLoadingCategoryCard = false;
    } else {
        isLoadingCategoryCard = true;
    }
  const dataSixMonth = bottomCard && bottomCard.resultCategorySixMonth
  const dataThreeMonth = bottomCard && bottomCard.resultCategoryThreeMonth
  const dataCurrentMonth = bottomCard && bottomCard.resultCategoryCurrentMonth
  let hasData = bottomCard && ( Math.max(...bottomCard.resultCategorySixMonth) > 0 ||
      Math.max(...bottomCard.resultCategoryThreeMonth) > 0 ||
      Math.max(...bottomCard.resultCategoryCurrentMonth) > 0) ? true : false

  const sliderCharts =  [
    <Box sx={{
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      display: 'flex',
    }}
    >
      <Box
        height={200}
        maxWidth={260}
        sx={{
          margin: '0 auto'
        }}
      >
        {bottomCard && <DynamicColumnChart data={dataSixMonth}/>}
      </Box>
    </Box>,
    <Box sx={{
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      display: 'flex',
    }}
    >
      <Box
        height={200}
        maxWidth={260}
        sx={{
          margin: '0 auto'
        }}
      >
        {bottomCard && <DynamicColumnChart data={dataThreeMonth} />}
      </Box>
    </Box>,
    <Box sx={{
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      display: 'flex',
    }}
    >

      <Box
        height={200}
        maxWidth={260}
        sx={{
          margin: '0 auto'
        }}
      >
        {bottomCard && <DynamicColumnChart data={dataCurrentMonth}/>}
      </Box>
    </Box>
  ]

  return (
    <Box sx={{
      width: '100%',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '16px 24px',
      boxSizing: 'border-box',
      height: 315
    }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'top',
          justifyContent: 'left'
        }}
      >
        <Box
        >
          <Typography
            align={'left'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '20px',
              lineHeight: '24px',
              minWidth: 270,
              color: '#808888',
            }}
          >
            {(isLoading || isLoadingCategoryCard)? <Skeleton variant="text" sx={{ fontSize: '20px' }} /> : "Reported Messages"}
          </Typography>

          <Typography
            align={'left'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#4D5858',
            }}
          >
            {(isLoading || isLoadingCategoryCard)? <Skeleton variant="text" sx={{ fontSize: '12px' }} /> : "Total messages | " + currrentSlide + "-Month Period"}
          </Typography>
        </Box>
      </Box>
      <Box
        height={300}
      >
        {(isLoading || isLoadingCategoryCard)? <Skeleton sx={{ marginTop: "12px" }} variant="rounded" height={220} width={'100%'} /> : bottomCard && <SlickSlider handleChangeSlide={(e:any) => changeMonth(e)} sliderItems={sliderCharts}/>}
      </Box>
      {(isLoading || isLoadingCategoryCard) ? '' : ( hasData ? <LegendChart/> : '' )}
    </Box>
  );
}
