import React, {useEffect, useState} from 'react';
import {FromInputMailProps} from "./FromInputMail.props";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {SEND_EMAIL_MODAL_TOOLTIP} from "../../../constants/contants";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import './FromInputMail.styles.css';
import {FormHelperText} from "@mui/material";
import {getListBaseSender} from "../../../api/emailTriage";
import {LoadingPage} from "../../organisms/LoadingPage";

export const FromInputMail: React.FC<FromInputMailProps> = ({
    validateFromName,
    paramSendMail,
    fromNameError,
    setParamSendMail,
    setValidateFromName,
    validateUserName,
    setValidateUserName,
    validateBaseSender,
    setValidateBaseSender,
     }) => {
    const [baseSenderList, setBaseSenderList]=useState<any>([]);
    const [isDisabled, setIsDisabled]=useState<boolean>(false);
    const [loadingPage, setLoadingPage] = useState(false)
    const [userNameValue, setUserNameValue] = useState('');

    const isValidUserName = (username: string) => {
        const test = (regex: RegExp) => username.replace(regex, '');
        return Boolean(test(/^[-_\.].+/)) && Boolean(test(/.+[-_\.]$/));
    };

    const handleOnChange = (e: any, type: string) => {
        let item: any = e.target.value;

        if (type === 'fromName' && !item.trim() && setValidateFromName) {
            setValidateFromName(true);
        }

        if(type === 'baseSender' && !item.mail_sender_id.trim() && setValidateBaseSender) {
            setValidateBaseSender(true);
        }

        setParamSendMail(() => {
            return ({
                ...paramSendMail,
                [type]: item,
            });
        });
    }

    useEffect(() => {
        setLoadingPage(true)
        getListBaseSender().then((res: any)=> {
            if(res && res.data && res.data.base_domains.length >0){
                setBaseSenderList(res.data.base_domains);
                const existUsedEmail = res.data.base_domains.filter((val:any) => val.value === paramSendMail['baseSender'].value)
                if(res.data.base_domains.length === 1) {
                    setIsDisabled(true);
                    if (!paramSendMail['baseSender']) {
                        setParamSendMail(() => {
                            return ({
                                ...paramSendMail,
                                baseSender: res.data.base_domains[0],
                            })
                        });
                    }
                }
                if(existUsedEmail.length === 0) {
                    setParamSendMail(() => {
                        return ({
                            ...paramSendMail,
                            baseSender: res.data.base_domains[0],
                        })
                    });
                } else {
                    setParamSendMail(() => {
                        return ({
                            ...paramSendMail,
                            baseSender: existUsedEmail[0],
                        })
                    });
                }
            }
            setLoadingPage(false)
        }).catch(err =>{
            setLoadingPage(false)
        });
        setBaseSenderList([])
    }, []);

    return (
        <>
        <Grid item xs={12} md={12} className={'from-input-mail'}>
            <Stack direction="row" alignItems="center"  sx={{marginBottom: 0.5}}>
                <InputLabel htmlFor="from" className='label-input-no-bottom'>
                    From
                </InputLabel>
                <Tooltip title={SEND_EMAIL_MODAL_TOOLTIP} placement="top-start">
                    <HelpIcon color="primary" sx={{
                        fontSize: '14px',
                        marginLeft: 1
                    }}/>
                </Tooltip>
            </Stack>
            <Grid container xs={12} md={12} sx={{
                border:  '1px solid #E0E0E0',
                borderRadius: 0.5,
                padding: '16px 16px 7px 16px',
            }}>
                <Grid item xs={12} md={12}>
                    <Stack sx={{width: '100%'}} direction="row" >
                        <Box sx={{width: '200px', minWidth: '145px'}}>
                            <InputLabel htmlFor="from-name" className='label-input'>From Name</InputLabel>
                            <OutlinedInput
                                id="from-name"
                                sx={{fontSize: '14px', fontWeight: 500}}
                                className='form-input'
                                type="text"
                                placeholder='From name'
                                defaultValue={paramSendMail[ 'fromName' ]}
                                onChange={(e: any) => handleOnChange(e, 'fromName')}
                            />
                        </Box>
                        <ChevronLeftIcon fontSize={'large'} sx={{
                            fontSize: 24,
                            height: 40,
                            marginTop: '20px',
                            color: '#7C8989'
                        }}/>
                        <Box sx={{width: "100%"}}>
                        <InputLabel htmlFor="base-domain" className='label-input'>
                            Email Address  <span style={{color:'#B00020'}}>*</span>
                        </InputLabel>
                        <Select
                            id={'base-domain'}
                            sx={{ fontSize: '14px', fontWeight: 500,}}
                            displayEmpty
                            value={paramSendMail['baseSender']}
                            name={'status'}
                            disabled={isDisabled}
                            error={(validateBaseSender && !paramSendMail[ 'baseSender' ]?.mail_sender_id) ? true : false}
                            renderValue={(selected: any) => {
                                if (!selected) {
                                    return <span style={{fontSize: '14px',fontWeight: 500,}}>Select an email address </span>;
                                }
                                return selected.value;
                            }}
                            className={'group-select-field-domain'}
                            IconComponent={(props) => (<ExpandMoreIcon {...props}/>)}
                            size={"small"}
                            onChange={(e: any) => handleOnChange(e, 'baseSender')}
                        >
                            {baseSenderList.map((status:any, index: number) => (
                                <MenuItem
                                    key={index}
                                    value={status}
                                    style={{paddingRight: '12px',
                                        fontSize: '14px',
                                        fontWeight: 500}}
                                >
                                    {status.value} <CheckIcon className={'checked-icon'}/>
                                </MenuItem>
                            ))}

                        </Select>
                            <FormHelperText id={'baseSenderError'}
                                            error
                                            style={{  margin: '0 0 -11px 16px', fontWeight: 500, fontSize: '12px'}}
                            >{(validateBaseSender && !paramSendMail[ 'baseSender' ]?.mail_sender_id) && 'This field is required.'}
                            </FormHelperText>
                        </Box>
                        <ChevronRightIcon  fontSize={'large'} sx={{
                            fontSize: 24,
                            height: 40,
                            marginTop: '20px',
                            color: '#7C8989',
                            marginRight: '-10px'
                        }}/>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
            <LoadingPage open={loadingPage}/>
        </>
    )
}
