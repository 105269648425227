import React from 'react';
import {TableDropdownProps} from './TableDropdown.props';
import './TableDropdown.styles.css';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image';
import {Images} from '../../../configs/images';
import {alpha, styled} from "@mui/material/styles";
import Menu, {MenuProps} from "@mui/material/Menu";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const TableDropdown: React.FC<TableDropdownProps> = ({
  title,
  data,
  color = '#fff',
  backgroundHover,
  background,
  borderRadius,
  variant,
  onClick,
  emailId,
  dropdownValue,
  padding,
  actionHideDropdown
}) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorHover, setAnchorHover] = React.useState<any>(null);
  const open = Boolean(anchorEl);

  const showActionsDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    dropdownValue(event.currentTarget.getAttribute('data-id'))
  };
  const hideActionsDropdown = () => {
    setAnchorEl(null);
    if(anchorHover && !anchorHover.target.classList.contains('status'))
      anchorHover.target.style.background = background
  };
  const handleMouseOver = (e:any) => {
    if(!e.target.classList.contains('status')){
      e.target.style.background = backgroundHover
    }
    setAnchorHover(e);
  }
  const handleMouseLeave = (e:any) => {
    if(!e.target.classList.contains('status'))
      e.target.style.background = background
      if(open)
        e.target.style.background = backgroundHover
  }
  return (
      <div className="table-dropdown" >
        <div className={"action-dropdown-list " + variant + ' ' + title} style={{color, background, borderRadius, padding}} onClick={data ? showActionsDropdown : () => {}} data-id={emailId} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
          <Text children={title} color={color} fontSize={12} fontWeight={700} margin='0 6px 0 0'/>
          {data ? <svg className='arrow-down' width="8" height="4" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.984375 0.984375H11.0156L6 6L0.984375 0.984375Z" fill={color}/>
          </svg> : null}
        </div>
        <StyledMenu
          id="actions-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'actions-customized-button',
          }}
          anchorEl={anchorEl}
          open={data ? open : false}
          onClose={hideActionsDropdown}
        >
          <div className='actions-rules'>
            <ul>
              {
                data ? Object.keys(data).map((item:string) => <li className={item === title ? 'active': ''} key={item} onClick={(e:any) => {
                  if(onClick) {
                    onClick(e);
                    hideActionsDropdown()
                  }
                }} value={item}>{item} {item === title ? <Image margin={"0 0 0 auto"} srcImg={Images.IconChecked} width={11} height={18}/> : ""}</li>) : null
              }
            </ul>
          </div>
        </StyledMenu>
      </div>
  );
};
