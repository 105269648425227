import React, {useEffect, useState} from 'react';
import './DropDownFilter.styles.css';
import {DropDownFilterProps} from './DropDownFilter.props';
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {Checkbox, ListItem} from "@mui/material";
import {Text} from '../../atoms/Text';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormControl from "@mui/material/FormControl";
import {Image} from "../../atoms/Image";
import {Images} from "../../../configs/images";

export const DropDownFilter: React.FC<DropDownFilterProps> = ({
    arrList,
    label,
    type,
    /* istanbul ignore next */
    width= 180,
    /* istanbul ignore next */
    height= 40,
    onSelectItem,
    /* istanbul ignore next */
    searchFieldPlaceholder= 'Search...',
    setSearchQuery,
    selectedItems,
    clearFilerSelection,
    isOpen,
    setIsOpen
}) => {
  const [initDropdown] = useState({category: null, priority: null, threat_analysis: null})

  const handleChange = (event: any, type: any) => {
    /* istanbul ignore next */
    const {target: { value, name = type }} = event;
    onSelectItem(value, name);
  };
  /* istanbul ignore next */
  const clearSelectedCheckbox = (type: string) => {
    clearFilerSelection(type);
    onSelectItem([], type);
    setIsOpen(initDropdown);
  }
  /* istanbul ignore next */
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(initDropdown);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen({
      ...initDropdown,
      [type]: event.currentTarget
    })
  };

  const checkSelected = selectedItems[type] && selectedItems[type].length;
  const colons = checkSelected > 0 ? ':' : '';
  const formWidth = checkSelected > 0 ? 'auto' : width;
  return (
    <FormControl className={checkSelected ? 'has-selected' : ''} sx={{ m: 1, width: formWidth}}>
      <span className='title-content'>{label + colons}</span>
      <Select
        data-testid={type + "-multiple-checkbox"}
        labelId={type + "-checkbox-label"}
        id={type + "-multiple-checkbox"}
        onOpen={(e:any) => handleOpen(e)}
        /* istanbul ignore next */
        onClose={/* istanbul ignore next */(e:any) => /* istanbul ignore next */ handleClose(e)}
        open={Boolean(isOpen[type])}
        autoWidth={true}
        multiple
        // @ts-ignore
        value={selectedItems[type] || []}
        onChange={(e:any) => { handleChange(e, type)} }
        renderValue={(selected) => <span>{selected[0]} {selected.length > 1 && <span className='selected-total'>+{selected.length - 1}</span>}</span>}
        MenuProps={{
          id: `${type + '-menu'}`,
          /* istanbul ignore next */
          anchorEl: isOpen[type],
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          style: {zIndex: 1},
        }}
      >
        {
          arrList.length > 7 && <ListItem className='search-input-item'>
            <TextField
              data-testid={'search-input-item'}
              fullWidth
              placeholder={searchFieldPlaceholder || "Search"}
              onChange={e => {
                if (setSearchQuery) {
                  setSearchQuery(e.target.value, arrList);
                }
              }}
              onKeyDown={e => {
                e.stopPropagation();
              }}
            />
            <Image srcImg={Images.SearchInFilter}
                   cssClass={`search-icon`}
                   height={18}
                   width={18}
                   borderRadius={"0"}
                   margin={'0'}
            />
          </ListItem>
        }
        {arrList.map((item: any) => {
          return <MenuItem key={item.id} value={item.content} className='filter-item'>
            <FormControlLabel control={
              <Checkbox
                // @ts-ignore
                className={'checkbox_' + type}
                id={type+'-'+item.id}
                checked={selectedItems[type] && selectedItems[type].includes(item.content)} />
              } label={item.content}
            />
          </MenuItem>
        }
        )}
        {
          /* istanbul ignore next */
          checkSelected > 0 && <ListItem data-testid='clear-selected' className='clear-selected' onClick={() => clearSelectedCheckbox(type)}>
            <button style={{ border: 'none', background: 'none' }} onClick={() => clearSelectedCheckbox(type)}>
                <Text color='#467599' children='Clear selection' fontWeight={600} fontSize={14} />
            </button>
          </ListItem>
        }
      </Select>
    </FormControl>

  );
};
