import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import './ConfirmMessage.styles.css';
import {DialogBoxProps} from './ConfirmMessage.props';

const ConfirmMessage: React.FC<DialogBoxProps> = ({
     showMessage,
     onCancel,
     onConfirm,
 }) => {
    return (
        <div style={{maxWidth: '560px'}}>
            <Dialog
                open={showMessage}
                className={'leave-page-dialog'}
            >
                <DialogTitle id="leave-page-title" sx={{padding:'24px 24px 16px 24px'}}>
                    <WarningIcon sx={{ margin: ' 0 16px -10px 0', fontSize: '35px', color: '#F29100'}} />
                    <span style={{fontSize: '20px', fontWeight: 700, color:'#212121'}}>Restore Default Content</span>
                    <IconButton
                        aria-label="close"
                        onClick={onCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{padding: '0 24px 24px 24px', textAlign: 'center'}}>
                    <DialogContentText id="leave-page-description" sx={{fontSize:'16px', fontWeight: 500, color:'#212121'}}>
                        This action will restore the Email Content to its default value <br/>
                        and will overwrite any modifications you have made.<br/><br/>
                        Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{padding: '0 24px 24px 24px'}}>
                    <Button onClick={onCancel} variant="outlined" size={"medium"} style={{color:'#345C8B'}}>Cancel</Button>
                    <Button onClick={onConfirm} variant="contained" size={"medium"} style={{backgroundColor:'#345C8B'}}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default ConfirmMessage;