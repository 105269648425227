import React, {useRef, useEffect} from 'react';
import { GridColDef } from '@mui/x-data-grid';
import {IconButton, Stack, Tooltip, Typography} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import Box from "@mui/material/Box";
import { DataTable } from '../DataTable';
import { ActivityLogProps } from './ActivityLog.props';
import './ActivityLog.styles.css'
import moment from "moment/moment";

export const ActivityLog: React.FC<ActivityLogProps>= ({
                                                            content,
                                                            isShowLoading,
                                                            downloadHandler,
                                                            onSort,
                                                            emailId,
                                                            downloadActivityLogsLink = '',
                                                            paginationComponent,
                                                            setDownloadActivityLogsLink
}) => {
  const downloadActivityLogsRef:any = useRef(null);

  useEffect(() => {
    if (downloadActivityLogsLink !== ''){
        downloadActivityLogsRef.current.href = downloadActivityLogsLink
        setDownloadActivityLogsLink('')
        downloadActivityLogsRef.current.click()
    }
  }, [downloadActivityLogsLink]);

  let tz_name = 'Etc/UTC';
  const localStorageUserData = localStorage.getItem('userData');
  if(localStorageUserData) {
      const parseUserData = JSON.parse(localStorageUserData)
      tz_name = parseUserData["tz_name"]
  }

  const columns: GridColDef[] = [
    {
      field: 'date_modified',
      headerName: 'Date modified',
      sortable: true,
      width: 211,
      renderCell: (row) => {
          return (<div>
              <span>{moment.utc(row.formattedValue, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('DD/MM/YYYY')}&nbsp;&nbsp;</span>
              <span>{moment.utc(row.formattedValue, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('hh:mm A')}</span>
          </div>)
      }
    },
    {
      field: 'action_details',
      headerName: 'Action Details',
      sortable: true,
      width: 467,
    },
    {
      field: 'done_by',
      headerName: 'Done By',
      sortable: true,
      width: 370,
      renderCell: (rowData: any) => (
        <div>
          <strong>{rowData.formattedValue.name}</strong><br/>
          <Typography>{rowData.formattedValue.created_by}</Typography>
        </div>
      ),
    },
    {
      field: 'ip_address',
      headerName: 'IP Address',
      sortable: true,
      width: 210,
    },
  ];

  const totalRows = content.length;
  let toolbar = null
  if (totalRows > 0){
    toolbar = () => (
        <Stack direction={'row-reverse'}>
            <IconButton onClick={() => downloadHandler(emailId)} data-testid={'download-activity-log-icon'}>
                <Tooltip placement={"top"} title={'Download Activity Logs'}
                     componentsProps={{
                         tooltip: {
                             sx: {
                                position: "relative",
                                top: "10px"
                             },
                         },
                     }}
                >
                    <DownloadIcon sx={{ color: '#345C8B' }}/>
                </Tooltip>
            </IconButton>
        </Stack>
    );
  }

  return (
    <Box className={"inbox-detail-activity-wrapper"}>
      <DataTable
        columns={columns}
        data={content}
        CustomPagination = {paginationComponent}
        pagination={true}
        initialSortCol={'date_modified'}
        loadingTable={isShowLoading}
        rowCount={totalRows}
        rowHeight={60}
        toolbar={toolbar}
        onSortModelChange={(model:any) => onSort(model)}
      />
      <a ref={downloadActivityLogsRef} style={{ display: 'none' }} rel="download activity logs">
        Hidden Link
      </a>
    </Box>
  );
};
