import React, {useState, useRef, useEffect} from 'react';
import {MailHeaderViewerProps} from "./MailHeaderViewer.props";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import moment from "moment";
import "./MailHeaderViewer.styles.css"
import {LabelIcon} from "../../atoms/LabelIcon";
import {Modal} from "../../atoms/Modal";
import {Images} from "../../../configs/images";
import {SearchFilter} from "../SearchFilter";
import Highlighter from "react-highlight-words";
import {HeaderDeliveryInformationModal} from "../../organisms/HeaderDeliveryInformationModal";

export const MailHeaderViewer: React.FC<MailHeaderViewerProps> = ({
    inspectionData,
    content
}) => {
    const [openHeaderViewDetail, setOpenHeaderViewDetail] = useState(false)
    const [headerDetailIndex, setHeaderDetailIndex] = useState(0)
    const [searchKeyWord, setSearchKeyWord] = useState("")
    const [inspectionDetails, setInspectionDetails] = useState({})
    const  headerContentRef= useRef(null);
    let tz_name = 'Etc/UTC';
    const localStorageUserData = localStorage.getItem('userData');

    if(localStorageUserData) {
        const parseUserData = JSON.parse(localStorageUserData)
        tz_name = parseUserData["tz_name"]
    }

    const nameStandardized = {
        dmarc:{
            text: "DMARC",
            tabDetailIndex: 0
        },
        spf: {
            text: "SPF",
            tabDetailIndex: 1
        },
        dkim: {
            text: "DKIM",
            tabDetailIndex: 2
        }
    }
    useEffect(() => {
        const details = generateInspectionDetails(inspectionData)
        setInspectionDetails(details)
    }, [inspectionData]);

    const generateInspectionDetails = (data:any) => {
        let result:any = {}
        if(Object.keys(data).length === 0) {
            return result
        }
        result = {
            dmarc_compliant: data["dmarc_compliant"],
            details: {
                spf: [
                    {
                        label: "SPF Alignment",
                        status: data["spf_alignment"]
                    },
                    {
                        label: "SPF Authenticated",
                        status: data["spf_authenticated"]
                    }
                ],
                dkim: [
                    {
                        label: "DKIM Alignment",
                        status: data["dkim_alignment"]
                    },
                    {
                        label: "DKIM Authenticated",
                        status: data["dkim_authenticated"]
                    }
                ]
            }
        }

        return result
    }


  const headersList = content;
  const toDateTime = (header: any) => {
    try {
      let headersItem = header.split(';');
      let lastItem = headersItem[headersItem.length - 1];
      let converted = moment(lastItem).utc()
      if(converted.isValid()) {
        headersItem[headersItem.length - 1] = converted.tz(tz_name).format('DD/MM/YYYY hh:mm A')
      }
      return headersItem.join('; ')
    } catch (e) {
      return header
    }
  }

  const handleOpenDetailPopup = (index:number) => {
      setHeaderDetailIndex(index)
      setOpenHeaderViewDetail(true)
  }

    const handleSanitizeForSearch = (value: string) => {
        value = value.replace(/[\r\n]+/g,"")
        return value.replace(/\s\s+/g, ' ')
    }

  return (
      <Box className={'header-viewer-wrapper'}>
          {
              Object.keys(inspectionDetails).length > 0 && <Box>
                  <span className={'title'}> Delivery Information </span>
                  <Box className={'delivery-information-wrapper'}>
                      <Box sx={{display: "flex", alignItems: "center", padding: "16px"}}>
                          <span className={'sub-title'}> DMARC compliant: </span>
                          <LabelIcon text={inspectionDetails["dmarc_compliant"] ? "Pass" : "Fail"} icon={inspectionDetails["dmarc_compliant"] ? "success" : "error"}/>
                          <span style={{marginLeft: "16px", fontWeight: 600, color: "#345C8B", cursor: "pointer"}}
                                onClick={() => handleOpenDetailPopup(nameStandardized.dmarc.tabDetailIndex)}>View Detail</span>
                      </Box>
                      <Box className={'delivery-information-content'}>
                          {
                              Object.keys(inspectionDetails['details']).map((key: string, index: any) => {
                                  return <Box className={'information-table'} key={index}>
                                      <Box className={'table-header sub-title'}>{nameStandardized[key].text}</Box>
                                      {
                                          inspectionDetails['details'][key].map((item: any, idx: any) => {
                                              return <Box className={'table-row'} key={'row_' + idx}>
                                                  <Box sx={{
                                                      fontSize: "16px",
                                                      fontWeight: 500,
                                                      color: "#345C8B",
                                                      minWidth: "195px"
                                                  }}>
                                                      <span
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => handleOpenDetailPopup(nameStandardized[key].tabDetailIndex)}>
                                                            {item.label}
                                                      </span>
                                                      <span style={{color: "#011111"}}><strong> : </strong></span>
                                                  </Box>
                                                  <Box>{<LabelIcon text={item.status ? "Pass" : "Fail"}
                                                                   icon={item.status ? "success" : "error"}/>}</Box>
                                              </Box>
                                          })
                                      }
                                  </Box>
                              })
                          }
                      </Box>
                  </Box>
            </Box>
          }
          <span className={'title'}> Header Content </span>
          <Stack spacing={2} className={'email-header-viewer-content'} ref={headerContentRef}>
              <SearchFilter
                  titleInput={'Search by keywords'}
                  iconSrc={Images.SearchIcon}
                  borderRadius={'4px'}
                  setInputValue={setSearchKeyWord}
              />
              {content.length === 0 ? <p className={"nodata-content"}> No header were contained in this
                  email</p> : headersList.map((item: any, index: any) => {
                  return (<Box key={index}>
                      <Typography className={"email-header-content-attribute"} sx={{fontWeight: '700'}} variant={'body2'} display="inline">
                          <Highlighter
                              highlightClassName="header-content-highlighted"
                              searchWords={[searchKeyWord]}
                              autoEscape={true}
                              highlightStyle={{backgroundColor: "#FFD54F"}}
                              textToHighlight={handleSanitizeForSearch(`${item.key}:`)}
                          />
                      </Typography>
                      <Typography className={"email-header-content-attribute"} sx={{wordWrap: 'break-word', wordBreak: 'break-all'}} variant={'body2'} display="inline">
                          <Highlighter
                              highlightClassName="header-content-highlighted"
                              searchWords={[searchKeyWord]}
                              autoEscape={true}
                              highlightStyle={{backgroundColor: "#FFD54F"}}
                              textToHighlight={handleSanitizeForSearch(` ${toDateTime(item.value)}`)}
                          />
                      </Typography>
                  </Box>)
              })}
          </Stack>

          <Modal idModal='modal-header-view-detail'
                 openDialog={openHeaderViewDetail}
                 variant={'header_view_detail'}
                 title={'Delivery Information'}
                 children={<HeaderDeliveryInformationModal payLoad={inspectionData} tabIndex={headerDetailIndex}/>}
                 onClose={() => setOpenHeaderViewDetail(false)}
          />
      </Box>
  );
};
