import React, {useEffect, useState} from 'react';
import {DataTableProps} from './DataTable.props';
import './DataTable.styles.css';
import {Badge, Stack, Tooltip} from "@mui/material";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import {updateLatestFilter} from '../../../api/emailTriage';
import {isMobileOnly} from "react-device-detect";
import {ReactComponent as DynamicColumn} from "../../../assets/images/dynamic_column.svg";
import {useLocation} from "react-router-dom";

interface GridPinnedColumns {
  left?: string[]; // Optional field names to pin to the left
  right?: string[]; // Optional field names to pin to the right
}

export const DataTable: React.FC<DataTableProps> = ({
  filterData,
  toolbar,
  onChangeRowsPerPage ,
  CustomPagination,
  rowCount,
  columns,
  actionsColumn,
  data,
  onSelectionModelChange,
  onRowDoubleClick,
  selectionModel,
  onSortModelChange,
  /* istanbul ignore next */
  loadingTable= false,
  checkboxSelection,
  pagination,
  initialSortCol,
  rowHeight,
}) => {
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData'))["userId"] : '';
  const userId = JSON.stringify(userData);
  const dynamicColumn = sessionStorage.getItem("dynamicColumn");
  if (dynamicColumn !== null) {
    filterData = JSON.parse(dynamicColumn);
  }
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(filterData)
  const [visibilityColumn, setVisibilityColumn] = useState(true)
  const [isDot, setDot] = useState(false)
  const location = useLocation()
  const checkShowColumn = (presentCol:any) => {
    let visibleCol:any
    if (presentCol !== null) {
      visibleCol = Object.values(presentCol)
    }
    // if(location?.pathname.includes('/inbox')){
    //   visibleCol.shift()
    // }
    if(visibleCol.length === columns.length){
      let checker = visibleCol.includes(true)
      if(!checker){
        setVisibilityColumn(false)
      } else {
        /* istanbul ignore next */
        setVisibilityColumn(true)
      }
    } else {
      setVisibilityColumn(true)
    }
  }

  useEffect(() => {
    let visibleCol:any
    if (dynamicColumn !== null) {
      visibleCol = Object.values(JSON.parse(dynamicColumn))
    }
    // if(location.pathname.includes('/inbox')){
    //   visibleCol.shift()
    // }
    if (dynamicColumn !== null) {
      checkShowColumn(JSON.parse(dynamicColumn))
      if(dynamicColumn !== '{}' && visibleCol.length === columns.length){
        setDot(() => dynamicColumn.includes("true"))
      } else {
        setDot(true)
      }
    }
  },[dynamicColumn])

  return(
    <div className='datatable-inbox' style={{ width: '100%' }}>
      <DataGrid
        columnBuffer={columns.length}
        getRowHeight={isMobileOnly ? /* istanbul ignore next */() => 'auto' : () => rowHeight}
        headerHeight={visibilityColumn && !isMobileOnly ? 56 : 0}
        localeText={{ toolbarColumns:
            <Tooltip placement={"top"} title="Column selector">
              {isDot ? <DynamicColumn/> : <Badge color="error" variant="dot">
                <DynamicColumn/>
              </Badge> }
            </Tooltip> }}
        onSelectionModelChange={onSelectionModelChange}
        disableColumnMenu
        autoHeight
        disableSelectionOnClick
        rows={visibilityColumn ? data : [] }
        columns={columns}
        components={{
          Pagination: visibilityColumn && pagination ? /* istanbul ignore next */CustomPagination : null,
          Toolbar: toolbar,
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No data available in table
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <span>All columns are hidden. Click on the <strong>column icon</strong></span>
              <span>and select the data you want to view.</span>
            </Stack>
          )
        }
       }
        componentsProps={{
          panel: {
            placement: "bottom-end"
          },
        }}
        rowCount={rowCount}
        rowsPerPageOptions={[5, 10, 15]}
        onPageSizeChange={onChangeRowsPerPage}
        selectionModel={selectionModel}
        checkboxSelection={checkboxSelection || null}
        getRowId={(row: any) => row.email_id || row.rule_id || row.id}
        onCellClick={onRowDoubleClick}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        loading = {loadingTable}
        columnVisibilityModel={isMobileOnly ? {} : columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel:any) =>
          {
            checkShowColumn(newModel)
            setColumnVisibilityModel(newModel)
            const data = {
              "userId": userId,
              "dynamicColumn":JSON.stringify(newModel),
              "filter": ""
            }
            let visibleCol:any
            if (newModel !== null) {
              visibleCol = Object.values(newModel)
            }
            // if(location.pathname.includes('/inbox')){
            //   visibleCol.shift()
            // }
            if(visibleCol.length === columns.length){
              setDot(() => data.dynamicColumn.includes("true"))
            }
            sessionStorage.setItem("dynamicColumn", JSON.stringify(newModel));
            return updateLatestFilter(data).then((result) => {
              if(result.status === 200) {
                // console.log("done")
              }
            });

          }
        }
        initialState={{
          sorting: {
            sortModel: [
              {
                field: initialSortCol,
                sort: 'desc',
              },
            ],
          },
        }}
      />
      {
        visibilityColumn ? actionsColumn : null
      }
      {
        loadingTable && <div style={{
            backgroundColor: "transparent",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        }}>
        </div>
      }


    </div>
  );
};
