import React, {useRef, useEffect, Fragment} from 'react';
import { LabelIconProps } from './LabelIcon.props';
import './LabelIcon.styles.css'
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Typography} from "@mui/material";
export const LabelIcon: React.FC<LabelIconProps>= ({text, icon, variant}) => {
    const theme = useTheme();
    let displayIcon:any = '';
    let displayColor:any = '';
    switch (icon) {
        case 'error': {
            displayIcon = <CancelIcon />;
            displayColor = theme.palette.error.main;
        } break;
        case 'success': {
            displayIcon = <CheckCircleIcon />;
            displayColor = theme.palette.success.main;
        } break;
        default: {
        }
    }

    return <Box sx={{py:0, px: 0}} className={'app-label-icon'}>
        <Typography sx={
            {
                'color': displayColor,
                'display': 'flex',
                'alignItems': 'center',
                'gap': '8px',
                'fontWeight': 600
            }
        } variant={'body2'} color={displayColor}>{displayIcon} {text}</Typography>
    </Box>
};
