import React from 'react'
import {DashLineChartCardProps} from './DashLineChartCard.props'
import './DashLineChartCard.styles.css';

import {Typography} from '@mui/material';
import Box from '@mui/material/Box';


import {DashedLineChart} from "../DashedLineChart";
import Skeleton from "@mui/material/Skeleton";


export const DashLineChartCard: React.FC<DashLineChartCardProps> = ({
  data,
  isLoading
}) => {
    // check loading first times
    let isLoadingDashLineChartCard;
    if(data !== null){
        isLoadingDashLineChartCard = false;
    } else {
        isLoadingDashLineChartCard = true;
    }
  return (
    <Box sx={{
      width: '100%',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '16px 24px',
      boxSizing: 'border-box',
      height: 315
    }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'top',
          justifyContent: 'left'
        }}
      >
        <Box
        >
          <Typography
            align={'left'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '20px',
              lineHeight: '24px',
              minWidth: 270,
              color: '#808888',
            }}
          >
            {(isLoading || isLoadingDashLineChartCard)? <Skeleton variant="text" sx={{ fontSize: '20px' }} /> : "Priority Trend"}
          </Typography>

          <Typography
            align={'left'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#4D5858',
            }}
          >
            {(isLoading || isLoadingDashLineChartCard) ? <Skeleton variant="text" sx={{ fontSize: '12px' }} /> : "Total messages | 6-Month Period"}
          </Typography>
        </Box>
      </Box>
      <Box
        height={300}

      >
        {(isLoading || isLoadingDashLineChartCard) ? <Skeleton sx={{ marginTop: "12px" }} variant="rounded" height={220} width={'100%'} /> : data && <DashedLineChart data={data}/>}
      </Box>
    </Box>
  );
}
