/* eslint-disable @typescript-eslint/no-explicit-any */
import {HttpMethod, httpClient} from './httpClient';
let userData:any = [];

export const getZoneID = () => {
  const userLocalData = localStorage.getItem('userData');

  if(userLocalData) {
    userData = JSON.parse(userLocalData);
  }
  return parseInt(sessionStorage.getItem('currentZone') || localStorage.getItem('currentZone') || userData.zoneId)
}

export const getUserEmail = () => {
  const userLocalData = localStorage.getItem('userData');

  if(userLocalData) {
    userData = JSON.parse(userLocalData);
  }
  return userData.email
}

export const getUserData = (key?:any) => {
  const userLocalData = localStorage.getItem('userData');

  if(userLocalData) {
    userData = JSON.parse(userLocalData);
  }
  if(!key) {
    return {
      email: userData['email'],
      name: userData['fullName'],
      tz_name: userData['tz_name']
    }
  }
  return userData[key]
}


export const getStaticReportDashboard = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/dashboard/get-static-dashboard`, HttpMethod.Post, data);
};

export const getTreeMap = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/dashboard/tree-map`, HttpMethod.Post, data);
};

export const getBottomCards = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/dashboard/get-bottom-cards`, HttpMethod.Post, data);
};

export const getBottomCardByMonths = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/dashboard/get-bottom-card-by-months`, HttpMethod.Post, data);
};

export const getTriageInboxes = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/inboxes`, HttpMethod.Post, data);
};

export const updateTriageInboxes = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID().toString()
  data['adminId'] = userData.userId.toString()
  data['actionType'] = userData.role
  return httpClient(`/inboxes/update-phishfocus-inbox`, HttpMethod.Post, data);
};

export const getInboxMessage = (messageID = {}): Promise<any> => {
  const data = {
    zoneId: getZoneID().toString()
  }
  return httpClient(`/inboxes/${messageID}`, HttpMethod.Post, data);
};

export const getStatusStatistic = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/inboxes/status-statistic`, HttpMethod.Post, data);
};

export const getInboxMessageEmail = (messageID = {}): Promise<any> => {
  const data = {
    zoneId: getZoneID().toString()
  }
  return httpClient(`/inboxes/${messageID}/email`, HttpMethod.Post, data);
};

export const sendMessageEmail = (data= {}): Promise<any> => {
  const userInfo = getUserData()
  data['emailAction'] = userInfo['email']
  data['nameAction'] = userInfo['name']
  return httpClient(`/inboxes/send-email`, HttpMethod.Post, data);
};

export const updateInboxMessage = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/update-phishfocus-inbox`, HttpMethod.Post, data);
};

export const deleteEmail = (data = {}): Promise<any> => {
  return httpClient(`/delete-emails`, HttpMethod.Post, data);
};

export const archiveEmail = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/update-archive`, HttpMethod.Post, data);
};

export const importToService = (convert_email_info= {}, messageID = {}): Promise<any> => {
  const data = {
    token: localStorage.getItem('accessToken') || '',
    zoneId: getZoneID().toString(),
    adminId: userData.userId.toString(),
    messageId: messageID,
    convert_email_info: convert_email_info
  }
  return httpClient(`/inboxes/import-to-service`, HttpMethod.Post, data);
};

export const purgeEmail = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/purge-email`, HttpMethod.Post, data);
};

export const getLatestFilter = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/get-filter-inbox`, HttpMethod.Post, data);
};

export const updateLatestFilter = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/update-filter-inbox`, HttpMethod.Post, data);
};

// API Automation Rule
export const getRules = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/automation-rules/get-automation-rules`, HttpMethod.Post, data);
};

export const updateRules = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/automation-rules/update-active-automation-rules`, HttpMethod.Post, data);
};

export const getRuleDetail = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/automation-rules/get-rule-detail`, HttpMethod.Post, data );
};

export const updateRuleDetail = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/automation-rules/update-rule-detail`, HttpMethod.Post, data );
};

export const checkRuleNameDetail = (data = {}  ): Promise<any> => {
  data['zone_id'] = getZoneID();
  return httpClient(`/automation-rules/check-rule-name-detail`, HttpMethod.Post, data );
};

// API setting page
export const getSettings = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/settings/get-settings`, HttpMethod.Post, data );
};

export const updateSetting = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/settings/update-setting`, HttpMethod.Post, data );
};

export const updateAutomationRuleSettings = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/settings/enable-automation-rules`, HttpMethod.Post, data );
};

export const getBaseDomain = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/base-domain/get-mail-senders`, HttpMethod.Post, data );
};

export const getListBaseSender = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/base-domain/list-mail-sender`, HttpMethod.Post, data );
};

export const addNewBaseDomain = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/base-domain/add-mail-sender`, HttpMethod.Post, data );
};

export const updateBaseDomain = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/base-domain/update-mail-sender`, HttpMethod.Post, data );
};

export const sendTestDomain = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/base-domain/test-send-mail`, HttpMethod.Post, data );
};

export const deleteBaseDomain = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/base-domain/delete-mail-sender`, HttpMethod.Post, data );
};

export const getActivityLog = (data: object): Promise<any> => {
  return httpClient(`/activities-logs/get-activities-logs`, HttpMethod.Post, data);
};

export const downloadActivityLog = (emailId: string): Promise<any> => {
  const userInfo = getUserData()
  const data = {
    email_id: emailId,
    zone_id: getZoneID().toString(),
    tz_name: userInfo['tz_name'] || 'Etc/UTC'
  }
  return httpClient(`/activities-logs/download-file-logs`, HttpMethod.Post, data);
};

export const enablePurge = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID();
  return httpClient(`/settings/enable-purge`, HttpMethod.Post, data );
};

export const getUsedEmail = (data = {}  ): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/settings/get-used-email`, HttpMethod.Post, data );
};

export const getBLockList = (payload = {}): Promise<any> => {
  return httpClient(`/block-lists`, HttpMethod.Post, payload );
};


export const getUserLogin = (payload = {}): Promise<any> => {
  return httpClient(`/services/acquire-user-login`, HttpMethod.Post, payload );
};

export const storeUserLogin = (payload = {}): Promise<any> => {
  return httpClient(`/services/create-user-login`, HttpMethod.Post, payload );
};

export const logoutService = (payload = {}): Promise<any> => {
  payload['user'] = getUserEmail()
  return httpClient(`/services/logout-service`, HttpMethod.Post, payload );
};

export const getBlockListsSetting = (payload = {}): Promise<any> => {
  payload['user'] = getUserEmail()
  return httpClient(`/block-lists/block-lists-setting`, HttpMethod.Post, payload);
};

export const syncBlockListEntries = (payload = {}): Promise<any> => {
  payload['user'] = getUserEmail()
  return httpClient(`/block-lists/block-lists-setting`, HttpMethod.Post, payload);
};

export const getBlockListActivityLogs = (payload = {}): Promise<any> => {
  payload['zoneId'] = getZoneID()
  payload['user'] = getUserEmail()
  return httpClient(`/block-lists/block-lists-activity-logs`, HttpMethod.Post, payload );
};

export const downloadBlockListActivityLogs = (payload = {}): Promise<any> => {
  const userInfo = getUserData()
  payload['tz_name'] = userInfo['tz_name'] || 'Etc/UTC'
  payload['zoneId'] = getZoneID()
  return httpClient(`/activities-logs/download-file-logs`, HttpMethod.Post, payload);
};

/**
 * Acquire Block List Entry
 * @param payload
 * @returns script version
 */
export const acquireBlockListEntries = (payload = {}): Promise<any> => {
  const userInfo = getUserData()
  payload['user'] = userInfo['email']
  payload['user_name'] = userInfo['name']
  return httpClient(`/block-lists/block-lists-entries`, HttpMethod.Post, payload);
};
