import React from 'react';
import { DeliveryViewerProps } from './DeliveryViewer.props';
import './DeliveryViewer.styles.css'
import WarningIcon from "@mui/icons-material/Warning";
import Skeleton from "@mui/material/Skeleton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export const DeliveryViewer: React.FC<DeliveryViewerProps>= ({
    title,
    payload,
}) => {
    const key = title?.toLowerCase()
    console.log("payload > ", payload)

    const renderNodataCard = (descriptionElement:any, descriptionTitle:string) => {
        let desTitle = ""
        let description = <></>
        switch(title){
            case "SPF":
                desTitle = descriptionTitle !== '' ? descriptionTitle : "No SPF record found"
                description = descriptionElement ? descriptionElement : <></>
                break
            case "DKIM":
                desTitle = descriptionTitle !== '' ? descriptionTitle : "No DKIM-Signature Detected"
                description = descriptionElement ? descriptionElement : <>There must be at least one aligned DKIM-Signature in the message header to be evaluated.</>
                break
            default:
                desTitle = descriptionTitle !== '' ? descriptionTitle : "No DMARC record found"
                description = descriptionElement ? descriptionElement : <></>
                break
        }
        return <div className={'record-container error'}>
            <div className={`desc-content error`}>
                <div className={`record-status error`}>
                    <WarningIcon/>
                    <span>{desTitle}</span>
                </div>
                <div className={'record-desc'}>{description}</div>
            </div>
        </div>
    }

    const generateLabelIconArea = (isLabelIconOnly: boolean, status: any, displayText?: string, recordValue?: string) => {
        const statusClassname = status === true ? "pass" : "fail"
        const text = status === true ? "Pass" : "Fail"

        let resultElement = <div className={`record-status ${statusClassname}`}>
            {status ? <CheckCircleIcon/> : <CancelIcon/>}
            <span>{displayText ? displayText : text}</span>
        </div>

        return isLabelIconOnly ? resultElement : <div className={`desc-content ${statusClassname}`}>
            {resultElement}
            <div className={'record-desc'}>{recordValue || ""}</div>
        </div>
    }

    const renderModal = (data: []) => {
        return <>{
            data.map((item: any) => {
                    let verifyValue:string
                    let alignmentStatus:any
                    let authenticatedStatus: any
                    let isHiddenVerifierValue: boolean
                    switch(title){
                        case "SPF":
                            isHiddenVerifierValue = (item[`${key}_domain`] === null || item[`${key}_domain`] === "") && (item[`${key}_ip`] === null || item[`${key}_ip`] === "")
                            verifyValue = `${item[`${key}_domain`] || "N/A"}:${item[`${key}_ip`] || "N/A"}`
                            alignmentStatus = item[`${key}_alignment`]
                            authenticatedStatus = item[`${key}_authenticated`]
                            break
                        case "DKIM":
                            isHiddenVerifierValue = (item?.domain === null || item?.domain === "") && (item?.selector === null || item?.selector === "")
                            verifyValue = `${item?.domain || "N/A"}:${item?.selector || "N/A"}`
                            alignmentStatus = item["alignment"]
                            authenticatedStatus = item['authenticated']
                            break
                        default:
                            isHiddenVerifierValue = item[`${key}_domain`] === null || item[`${key}_domain`] === ""
                            verifyValue = item[`${key}_domain`] || "N/A"
                            alignmentStatus = item[`${key}_alignment`]
                            authenticatedStatus = item[`${key}_authenticated`]
                            break
                    }
                    return <div key={item} className={'delivery-container'}>
                        <div className={'verifier-group'}>
                            {
                                isHiddenVerifierValue ? <></> : <>
                                    <span className={'title'}>{title}:</span>
                                    <span className={'verifier-value'}>{verifyValue}</span>
                                    <div style={{width: "1px", height: "32px", backgroundColor: "#B3B8B8", marginLeft: "16px"}}></div>
                                </>
                            }
                            {
                                title === "DMARC" ?
                                    <div className={'delivery-group-status'} style={{marginLeft: isHiddenVerifierValue ? "0" : "16px"}}>DMARC compliant: {generateLabelIconArea(true, item["dmarc_compliant"])}</div>
                                : <div className={'delivery-group-status'} style={{marginLeft: isHiddenVerifierValue ? "0" : "16px"}}>
                                        <div className={'delivery-group-status-alignment'}>{title} Alignment: {generateLabelIconArea(true, alignmentStatus)}</div>
                                        <div className={'delivery-group-status-authenticated'}>{title} Authenticated: {generateLabelIconArea(true, authenticatedStatus)}</div>
                                </div>
                            }
                        </div>


                        {
                            // DKIM case
                            title === "DKIM" && <div className={'record-container'}>
                                {
                                    item?.signature === null ? renderNodataCard(undefined, '')
                                        : <>
                                        <span className={'title'}>DKIM signature:</span>
                                        {generateLabelIconArea(false, item["signature_valid"], item["signature_valid"] ? "Valid" : "Invalid", item?.signature)}
                                        <span className={'title'}>DKIM public record:</span>
                                        {generateLabelIconArea(false, item["record_valid"], item["record_valid"] ? "Valid" : "Invalid", item?.record)}
                                    </>
                                }
                            </div>
                        }
                        {
                            //SPF case
                            title === "SPF" && <div className={'record-container'}>
                                <span className={'title'}>SPF record:</span>
                                {
                                    item['spf_record'] === null ? renderNodataCard(undefined, '') :
                                    generateLabelIconArea(false, item["spf_record_valid"], item["spf_record_valid"] ? "Valid" : "Invalid", item["spf_record"])
                                }
                            </div>
                        }
                        {
                            //DMARC case
                            title === "DMARC" && <div className={'record-container'}>
                                <span className={'title'}>DMARC record{item["dmarc_organization_record"] === null ? <></> : <> for <span className={'dmarc-domain'}>{item["dmarc_domain"]}</span></>} :</span>
                                {
                                    item['dmarc_record'] === null ? (
                                        item["dmarc_organization_record"] === null ?
                                            renderNodataCard(undefined, '') : <>
                                                {renderNodataCard(<>Organisation domain of this sub-domain is: <strong>{item["dmarc_organization_domain"]}</strong>. Inbox receivers will apply {item["dmarc_organization_domain"]} DMARC record to mail sent from {item["dmarc_domain"]}</>, "No DMARC record found for sub-domain")}
                                                <span className={'title'}>DMARC record for <span className={'dmarc-domain'}>{item[`dmarc_organization_domain`]}</span> (organisational domain) :</span>
                                                {generateLabelIconArea(false, item["dmarc_record_valid"], item["dmarc_record_valid"] ? "Valid" : "Invalid", item["dmarc_organization_record"])}
                                            </>
                                    ) :
                                    generateLabelIconArea(false, item["dmarc_record_valid"], item["dmarc_record_valid"] ? "Valid" : "Invalid", item["dmarc_record"])
                                }

                            </div>
                        }
                    </div>
            })
        }
        </>
    }

    return (
        <>
            {
                payload.length !== 0 ? payload.map((item: any) => {
                    const renderDetailItem = title === "DKIM" ? item["dkim_details"] : [item]
                    return <>
                        {renderModal(renderDetailItem)}
                        {
                            title === "DKIM" && item["dkim_details"].length === 0 ?
                                <div className={'delivery-container'}>
                                    <div className={'verifier-group'}>
                                        <div className={'delivery-group-status'} style={{marginBottom: "32px", marginLeft: "0"}}>
                                            <div className={'delivery-group-status-alignment'}>DKIM Alignment: {generateLabelIconArea(true, item["dkim_alignment"])}</div>
                                            <div className={'delivery-group-status-authenticated'}>DKIM Authenticated: {generateLabelIconArea(true, item["dkim_authenticated"])}</div>
                                        </div>
                                    </div>
                                    {renderNodataCard(undefined, '')}
                                </div> : <></>
                        }
                    </>
                }) : <Skeleton animation="wave" sx={{width: '100%', height: '200px'}}/>
            }
        </>
    );
};
