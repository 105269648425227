import React, {useEffect, useState} from 'react'
import { BaseDomainSettingProps } from './BaseDomainSetting.props'
import './BaseDomainSetting.styles.css';
import {IconButton, Tooltip} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import {Modal} from "../../atoms/Modal";
import {ChipGroup} from "../../molecules/ChipGroup";
import { AddNewBaseDomain } from "../AddNewbaseDomain";
import {
    addNewBaseDomain,
    deleteBaseDomain,
    getListBaseSender,
    sendTestDomain,
    updateBaseDomain
} from "../../../api/emailTriage";
import {isMobileOnly} from "react-device-detect";
import {SendMailModal} from "../SendMailModal";
import {Toast} from "../../atoms/Toast";
import {BASE_DOMAIN_MODES, BASE_DOMAIN, BASE_DOMAIN_STATUS, STATUS_CODE } from "../../../constants/contants";
import {LoadingPage} from "../LoadingPage";
import {VerifyEmailAddress} from "../VerifyEmailAddress";
import Skeleton from "@mui/material/Skeleton";

export const BaseDomainSetting: React.FC<BaseDomainSettingProps> = ({
}) => {
    const defaultParams: any = {
        subject: 'Test email address',
        email_content: `<p>Hello,</p><p>This is just a test email.</p><p>Kind Regards,</p><p>Security Team</p>`,
        to: [],
    }
    const [open, setOpen] = useState(false);
    const [openPopupVerifyEmail, setOpenPopupVerifyEmail] = useState(false);
    const [modalID, setModalID] = useState(1);
    const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0});
    const [status, setStatus] = useState(BASE_DOMAIN_STATUS.NONE);
    const [openDialogTestMail, setOpenDialogTestMail] = useState(false);
    const [paramTestMail, setParamTestMail] = useState(defaultParams);
    const [titleDomain, setTitleDomain] = useState('');
    const [validateToField, setValidateToField] = useState(false);
    const [validateEmailContent, setValidateEmailContent] = useState(false);
    const [mode, setMode] = useState('');
    const [valueEdit, setValueEdit] = useState({domain: '', mail_sender_id: ''});
    const [listDomain, setListDomain] = useState([]);
    const [initDomain, setInitDomain] = useState([]);
    const [defaultDomain, setDefaultDomain] = useState([]);
    const [noneVerifiedSender, setNoneVerifiedSender] = useState([]);
    const [loadingPage, setLoadingPage] = useState(false)
    const [isLineLoading, setIsLineLoading] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openVerifyEmail, setOpenConfirmVerifyEmail] = useState(false);
    const [openVerifyEmailSuccess, setOpenConfirmVerifyEmailSuccess] = useState(false);
    const [isResendLink, setIsResendLink] = useState(false);
    const [verifyReturnValue, setVerifyReturnValue] = useState({isTriggerOnSave: false, values: {}});
    const [contentModal, setContentModal] = useState<any>({});

    const generateConfirmContentModal = (element: any, title: string, textAgree: string, variant: string, params?: any) => {
      const content:any = {
        title: title,
        children: element,
        action: params,
        textAgree: textAgree,
        variant: variant
      }
      setContentModal(content)
    }

    const confirmDelete = (params:any) => {
        const html = <><div>Deleting this email address <strong>“{params.value}”</strong> will result in all the settings using it to return to the default email address <strong>“{defaultDomain.join(', ')}”</strong>.</div>
            <br /><div>Are you sure you want to delete this email address <strong>“{params.value}”</strong> ?</div></>

        const title = 'Delete email address'
        const textAgree = 'Delete'
        const variant = 'delete'
        generateConfirmContentModal(html, title, textAgree, variant, params)
        setOpenConfirm(true)
    }

    const verifyPopupContent = (params: any) => {
        const html = <><div>A verification email has been sent to the email address <strong>{params.baseSender}</strong>
            <br />
            <br />
            Ensure to inform your contact person/s of the designated zone that they will receive an email from Amazon SES. Includes a verification link that will <strong>expires within 24 hours.</strong></div>
            <br />
            <div>Afterwards, select the 'sender email address' again and check the verification status to ensure it is registered on the platform.</div>
        </>

        const title = 'Sent verification email successfully!'
        const textAgree = 'Confirm'
        const variant = 'success'
        generateConfirmContentModal(html, title, textAgree, variant, params)
        setOpenConfirmVerifyEmail(true)
    }

    const verifyEmailSuccessPopup = (params: any) => {
        const html = <><div>You have successfully verified <strong>{params.baseSender}</strong>
            <br />
            <br />
            The current zone admins can now use this email address to send emails from the platform.
            </div>
            <br />
            <div>Do you want to send a test email?
            <span className="send-test-email-link" onClick={() => {
                setOpenConfirmVerifyEmailSuccess(false);
                handleSendTest({value: params.baseSender});
            }}> Send test email</span></div>
        </>

        const title = 'Verified email successfully!'
        const textAgree = 'OK'
        const variant = 'success'
        generateConfirmContentModal(html, title, textAgree, variant, params)
        setOpenConfirmVerifyEmailSuccess(true)
    }

    const onCancel=()=>{
        setOpen(false)
        setStatus(BASE_DOMAIN_STATUS.NONE)
    }

    const cancelVerifyEmail =()=>{
        setOpenPopupVerifyEmail(false)
        setStatus(BASE_DOMAIN_STATUS.NONE)
    }

    const onSave = (value: any, type:any)=>{
        setTitleDomain(value['baseDomain'])
        setStatus(BASE_DOMAIN_STATUS.NONE)
        if(type === BASE_DOMAIN.SAVE_DOMAIN) {
            addEditDomain(value)
        }
        // if(type === BASE_DOMAIN.SAVE_ADD_DOMAIN) {
        //     addEditDomain(value)
        //     setOpen(true)
        // }
        if(type === BASE_DOMAIN.TEST_DOMAIN) {
            setOpenDialogTestMail(true)
            setParamTestMail({...defaultParams, domain: value['baseDomain'], sender: value['baseSender']})
            setOpen(true)
        }
    }

    const addEditDomain = (val: any) =>{
        setIsLineLoading(true)
        const payload = {
            domain: val?.baseDomain,
            baseSender: val?.baseEmail,
            mail_sender_id: valueEdit?.mail_sender_id,
            isResend: isResendLink
        }
        if(mode === BASE_DOMAIN_MODES.EDIT) {
            updateBaseDomain(payload).then(res =>{
                if(res && res.status && res.data){

                  getListDomain()

                  if (isResendLink){
                        setOpenPopupVerifyEmail(false)
                        setStatus(BASE_DOMAIN_STATUS.NONE)
                        verifyPopupContent(payload)
                        setIsResendLink(false)
                        setIsLineLoading(false)
                        return
                    }

                    const isVerifiedEmail = res.data.status
                    if (isVerifiedEmail){
                        verifyEmailSuccessPopup(payload)
                        setStatus(BASE_DOMAIN_STATUS.NONE)
                        setOpenPopupVerifyEmail(false)
                    }else {
                        setStatus(BASE_DOMAIN_STATUS.FAIL)
                        setOpenPopupVerifyEmail(true)
                    }
                    setIsLineLoading(false)
                }
            }).catch(() => {
              setIsLineLoading(false)
            })
        } else {
            addNewBaseDomain(payload).then(res =>{
                if(res && res.status === STATUS_CODE.OK){
                    getListDomain()
                    verifyPopupContent(payload)
                    setIsLineLoading(false)
                    setOpen(false)
                }
            }).catch(() => {
                setIsLineLoading(false)
            })
        }

    }

    const sendDomain = (val: any) =>{
       setLoadingPage(true)
       setModalID(modalID + 1)
       sendTestDomain(val).then((res)=>{
            if(res && res.data){
                setLoadingPage(false)
                if(res.data.result){
                    setToastValue({type: 'success', message: 'The test email was sent successfully.', isOpen: true, toastId: modalID});
                } else {
                    setToastValue({type: 'error', message: 'Mail sent error. Can’t send the email!', isOpen: true, toastId: modalID});
                }
            }
        }).catch(err =>{
           setLoadingPage(false)
           setToastValue({type: 'error', message: 'Mail sent error. Can’t send the email!', isOpen: true, toastId: modalID});

       })
    }

    const getListDomain = () =>{
        setListDomain([]);
        const data = {'getAll': true}
        getListBaseSender(data).then((res)=>{
          if(res && res.data &&
            res.data.base_domains &&
            res.data.base_domains.length > 0){
            setDefaultDomain(res.data?.default_domain)
            setInitDomain(res.data.base_domains)
            setNoneVerifiedSender(res.data.none_verified)
            let listBaseDomain:any = [];
            res.data.base_domains.forEach((val:any) => {
                listBaseDomain.push(val.value)
            });
            setListDomain(listBaseDomain)
          }
        })
    }

    const validatePopupTestMail = () => {
        if(!paramTestMail['email_content'].trim() ||
            paramTestMail[ 'to' ].length === 0 ||
            !paramTestMail[ 'email_content' ]?.replace(/<\/?[^>]+(>|$)/g, "")) {
            if(paramTestMail[ 'to' ].length === 0){
                setValidateToField(true);
                setModalID(modalID + 1)
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID})
            }
            if(!paramTestMail['email_content'].trim() || !paramTestMail[ 'email_content' ]?.replace(/<\/?[^>]+(>|$)/g, "")){
                setValidateEmailContent(true);
                setModalID(modalID + 1)
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID})
            }
        } else {
            setValidateEmailContent(false);
            setOpenDialogTestMail(false)
            setValidateToField(false);
            sendDomain(paramTestMail)
        }
    }

    const handleCloseTestMail = () => {
        setOpenDialogTestMail(false);
    };

    const handleCloseDelete = () => {
      setOpenConfirm(false);
    };

    const handleEdit = (editDomain:any) => {
      getListDomain()
      setMode(BASE_DOMAIN_MODES.EDIT);
      setValueEdit(editDomain);
      setOpenPopupVerifyEmail(true)
    };

    const handleSendTest = (testSender:any) => {
      setTitleDomain(testSender['value'])
      setOpenDialogTestMail(true)
      setParamTestMail({...defaultParams, domain: testSender['value'], sender:testSender['value']})
    };

    const handleDelete = (chipToDelete:any) => {
      setLoadingPage(true)
      deleteBaseDomain(chipToDelete).then((res:any) => {
        if(res.status === STATUS_CODE.OK){
          setInitDomain((chips:any) => chips.filter((chip:any) => chip.mail_sender_id !== chipToDelete.mail_sender_id));
          setModalID(modalID + 1)
          const messageDomain ='Delete email address successfully!';
          setToastValue({type: 'success', message: messageDomain, isOpen: true, toastId: modalID});
          setLoadingPage(false)
        } else {
            const messageDomain ='Cannot delete email address!';
            setToastValue({type: 'success', message: messageDomain, isOpen: true, toastId: modalID});
            setLoadingPage(false)
        }
      })
    };

    const handleAddNew = () => {
        getListDomain()
        setMode(BASE_DOMAIN_MODES.ADD)
        setOpen(true)
    };

    useEffect(() => {
      getListDomain()
    }, [])

    useEffect(() => {
        if (verifyReturnValue.isTriggerOnSave){
            onSave(verifyReturnValue.values, BASE_DOMAIN.SAVE_DOMAIN)
        }
    }, [verifyReturnValue])
    return (
      <>
        <div id={'base-domains-container'}>
          <div className={'base-domain-header'}>
             <div className={'title-domain'}>
               <span>Sender email addresses</span>
               <Tooltip placement={"top"} title={'Adding email addresses here will allow zone admins to select them for sending emails via Phish Focus'}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        width: "400px",
                        maxWidth: "400px",
                        padding: "4px 12px"
                      },
                    },
                  }}
               >
                 <IconButton className='info-action'><HelpIcon fontSize={'small'} htmlColor={"#193051"}/></IconButton>
               </Tooltip>
             </div>
             <Button data-testid={"openNewDomain"} onClick={() => handleAddNew()} variant="text" size={"large"}
                     style={{
                       padding: "8px 24px",
                       width: 'fit-content',
                       height: '40px',
                       lineHeight: 'normal',
                       fontFamily: 'Metropolis',
                       border: '1px solid #467599',
                       fontSize: '14px',
                       fontWeight: 600,
                       marginLeft: 'auto',
                       color: '#467599',
                     }}>
               <AddIcon
                 style={{fontSize: '20px'}}/>
               <span style={{paddingLeft: '8px'}}>New sender email address</span>
             </Button>

           </div>
          <span className={'base-domain-content'}>Added sender email addresses:</span>

          {initDomain.length !== 0 ? <ChipGroup initDomainList={initDomain} noneVerifiedSender={noneVerifiedSender} defaultDomain={defaultDomain} handleEdit={handleEdit} handleSendTest={handleSendTest} confirmDelete={confirmDelete}/>
            : <Skeleton animation="wave" sx={{width: '100%', height: '80px'}}/>}
          <span className={'label-small'}>An email address with an exclamation mark icon indicates that it has not been verified or that verification failed. Refresh the page or view each email address and check status to get the latest status update.</span>
        </div>
        <AddNewBaseDomain
            isOpen={open}
            onCancel={onCancel}
            onSave={onSave}
            isLineLoading={isLineLoading}
            result={status}
            mode={mode}
            value={valueEdit}
            listDomain={listDomain}
        />

        <VerifyEmailAddress
          isOpen = {openPopupVerifyEmail}
          value={valueEdit}
          setIsResendLink={setIsResendLink}
          setVerifyReturnValue={setVerifyReturnValue}
          isLineLoading={isLineLoading}
          onCancel={cancelVerifyEmail}
          checkStatusResult={status}
        ></VerifyEmailAddress>

        <Modal idModal='modal-verify-email-successfully'
               openDialog={openVerifyEmailSuccess}
               variant={contentModal.variant}
               title={contentModal.title}
               textAgree={contentModal.textAgree}
               actionAgree={() => {setOpenConfirmVerifyEmailSuccess(false)}}
               onClose={() => {setOpenConfirmVerifyEmailSuccess(false)}}
               children={contentModal.children}
        />

        <Modal idModal='modal-verify-sender-email'
               openDialog={openVerifyEmail}
               variant={contentModal.variant}
               title={contentModal.title}
               actionAgree={() => {
                    setIsResendLink(false);
                    setOpenConfirmVerifyEmail(false)
               }}
               textAgree={contentModal.textAgree}
               onClose={() => {
                   setIsResendLink(false);
                   setOpenConfirmVerifyEmail(false)
               }}
               children={contentModal.children}
        />

        <Modal idModal='modal-send-mail'
               disableEnforceFocus={true}
               fullScreen={isMobileOnly}
               openDialog={openDialogTestMail}
               title={`Test email address "${titleDomain}"`}
               actionAgree={validatePopupTestMail}
               actionCancel={handleCloseTestMail}
               textAgree={'Send test email'}
               textCancel={'Cancel'}
               onClose={handleCloseTestMail}
               cssClass={'btn-right'}
        >
            <SendMailModal
               defaultParams={defaultParams}
               paramSendMail={paramTestMail}
               setParamSendMail={setParamTestMail}
               scanResult={{'scan-result': null}}
               editorError={'This field is required.'}
               validateEmailContent={validateEmailContent}
               setValidateEmailContent={setValidateEmailContent}
               validateToField={validateToField}
               setValidateToField={setValidateToField}
               isTestDomain={true}
               testDomainName={titleDomain}
            />
        </Modal>
        <Modal idModal='modal-delete-mail'
               openDialog={openConfirm}
               variant={contentModal.variant}
               title={contentModal.title}
               content={contentModal.content}
               actionAgree={() => {
                 handleDelete(contentModal.action)
                 handleCloseDelete()
               }}
               actionCancel={handleCloseDelete}
               textAgree={contentModal.textAgree}
               onClose={handleCloseDelete}
               children={contentModal.children}
        />
        <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message}
               toastId={toastValue.toastId} autoHideDuration={2000}/>
        <LoadingPage open={loadingPage}/>
      </>
    )
}
