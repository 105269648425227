import AWS from 'aws-sdk';

interface LoginsInterface {
  [loginUrl: string] : any;
}

// Make AWS request using Cognito
export const makeCognitoRequest = (id_token:string) => {
  const aws_login_url = process.env.REACT_APP_AWS_CONFIG_LOGIN_URL || '';
  const aws_region = process.env.REACT_APP_AWS_CONFIG_REGION || '';
  const logins: LoginsInterface = {};
  // @ts-ignore
  logins[aws_login_url] = id_token;

  // *** REPLACE pool_id and table_name WITH YOUR OWN VALUES ***
  var pool_id = process.env.REACT_APP_AWS_CONFIG_POOL_ID || '';
  // Parameters required for CognitoIdentityCredentials
  var params = {
    IdentityPoolId: pool_id,
    Logins: logins
  };

  // Amazon Cognito region
  AWS.config.region = aws_region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);

  return new Promise((resolve, reject) => {
    AWS.config.getCredentials((err: any, credential:any) => {
      if (err) {  // an error occurred
        if (!credential) {
          localStorage.clear()
          //window.location.href = '/logout';
        }
        reject(err);
      } else  /* istanbul ignore next */ {
        /* istanbul ignore next */
        localStorage.setItem('isLoggedIn', 'true');
        /* istanbul ignore next */
        localStorage.setItem('sessionToken', credential.sessionToken);
        /* istanbul ignore next */
        localStorage.setItem('accessKeyId', credential.accessKeyId);
        /* istanbul ignore next */
        localStorage.setItem('secretAccessKey', credential.secretAccessKey);
        /* istanbul ignore next */
        resolve(credential);
      }
    });
  }).catch((error) => error);
}


