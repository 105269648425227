import React, {useEffect, useState} from 'react';
import {AlertMessageProps} from './AlertMessage.props';
import './AlertMessage.styles.css';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

export const AlertMessage: React.FC<AlertMessageProps> = ({
  /* istanbul ignore next */
  variant = 'filled',
  /* istanbul ignore next */
  severity = 'error',
  content,
}) => {
  const [icon, setIcon] = useState<any>(null);
  const handleIcon = () => {
    switch (severity){
    /* istanbul ignore next */
    case 'success':
      setIcon(<TaskAltIcon/>);
      break;
    /* istanbul ignore next */
    case 'error':
      setIcon(<ErrorIcon/>);
      break;
    /* istanbul ignore next */
    case 'warning':
      setIcon(<WarningIcon/>);
      break;
    /* istanbul ignore next */
    case 'info':
      setIcon(<InfoIcon/>);
      break;
    }
  };
  useEffect(() => {
    handleIcon();
  },[content]);
  return (
    <Alert className="alert-container" variant={variant} severity={severity} icon={icon}>
      {content}
    </Alert>
  );
};
