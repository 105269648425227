import React, {Fragment, useEffect, useState} from "react";
import {InboxDetailsProps} from "./InboxDetails.props";
import "./InboxDetails.styles.css";
import {InboxDetailsAction} from "../../components/organisms/InboxDetailsActions";
import {getInboxMessage} from "../../api/emailTriage";
import {useNavigate, useParams} from "react-router-dom";
import { Grid, Paper} from "@mui/material";
import {InboxDetailsHeader} from "../../components/organisms/InboxDetailsHeader";
import {EmailDetails} from "../../components/organisms/EmailDetails";

const defaultSelectedEmail = {
    priority: undefined,
    category: undefined,
    status: undefined,
    subject: undefined,
    email: undefined,
    email_from: undefined,
    reply_to: undefined,
    to: undefined,
    cc: undefined,
    reported_at: undefined,
    reported_by: undefined,
    hasAttachment: undefined,
    is_archive: undefined,
}

export const InboxDetails: React.FC<InboxDetailsProps> = ({
  emailId,
  selectedRowEmail,
  handleDelete,
  handleArchiveMail,
  tabValue,
  handleEmailChanged,
  rowSelected,
  actionOtherData,
  handleSendMail,
  handleActionDelete,
  handlePurgeMail,
  loginAzure,
  hasAccessToken,
  triggerLoadLog
}) => {
    const { id } = useParams();
    // const location:any = useLocation();
    const navigate = useNavigate();
    let selectedEmail = selectedRowEmail || defaultSelectedEmail
    const [priorityValue, setPriorityValue] = useState(selectedEmail.priority);
    const [categoryValue, setCategoryValue] = useState(selectedEmail.category);
    const [statusValue, setStatusValue] = useState(selectedEmail.status);
    const [originalEmail, setOriginalEmail] = useState('')
    const [scanResult, setScanResult] = useState('')
    const [isShowAction, setShowAction] = useState(false)
    const [inboxDetailActiveTab, setInboxDetailActiveTab] = useState(0)
    const [emailData, setEmailData] = useState({});
    const [messageDetails, setMessageDetails] = useState({
        subject: selectedEmail.subject,
        email: selectedEmail.email || selectedEmail.email_from,
        replyTo: selectedEmail.reply_to,
        to: selectedEmail.to,
        cc: selectedEmail.cc,
        reportedAt: selectedEmail.reported_at,
        reportedBy: selectedEmail.reported_by,
        hasAttachment: selectedEmail.has_attachment || false,
        is_archive: false,
        priority: selectedEmail?.priority,
        status: selectedEmail?.status,
        category: selectedEmail?.category,
        scanResult: selectedEmail?.scan_result,
        attachments: selectedEmail?.attachments || undefined,
        urls: selectedEmail?.urls || undefined,
        parsed: {},
        analyzed_overview:{},
        analyzed_details:{},
        threat_score: selectedEmail?.threat_score || undefined
    })

    const onGetEmailSuccess = (data:any) => {
        setMessageDetails({
            subject: data.subject,
            email: data.email || data.email_from || '',
            replyTo: data.reply_to || '',
            to: data.to || '',
            cc: data.cc || '',
            reportedAt: data.reported_at || '',
            reportedBy: data.reported_by || '',
            hasAttachment: data.has_attachment || false,
            is_archive: data.is_archive,
            priority: data?.priority,
            status: data?.status,
            category: data?.category,
            scanResult: data?.scan_result,
            attachments: data?.attachments || undefined,
            urls: data?.urls || undefined,
            parsed: data?.parsed || {},
            analyzed_overview: data?.analyzed_overview || {},
            analyzed_details: data?.analyzed_details || {},
            threat_score: data?.threat_score || undefined
        })
        setCategoryValue(data['category'])
        setPriorityValue(data['priority'])
        setStatusValue(data['status'])
    }

    const setEmailInfo = (data:any) => {
        setEmailData(data)
    }

    const onGetEmailFail = (error:any) => {
        setMessageDetails({
                subject: '',
                email: '',
                replyTo: '',
                to: '',
                cc: '',
                reportedAt: '',
                reportedBy: '',
                hasAttachment: false,
                is_archive: false,
                priority: '',
                status: '',
                category: '',
                scanResult: '',
                attachments: [],
                urls: [],
                parsed: {},
                analyzed_overview:{},
                analyzed_details:{},
                threat_score: undefined
            })
            setCategoryValue('')
            setPriorityValue('')
            setStatusValue('')
    }

    useEffect(() => {
        getInboxMessage(id || emailId).then((response) => {
            onGetEmailSuccess(response.data)
        }).catch((error) => {
            onGetEmailFail(error)
            navigate('/page-not-found')
        })
    }, [id, emailId, triggerLoadLog]);

    return (
        <Fragment>
            <div className='inbox-page-details page-content-wrapper'>
                <div className='page-content-body'>
                    <Grid container spacing={{xs: 1}} sx={{ flex: 1 }}>
                        <Grid item xs sx={{ height: '100%' }}>
                            <Grid container sx={{ height: '100%'}} spacing={{md: 2, xs: 1}}>
                                <Grid item md={12}>
                                    <Paper elevation={2} className='app-paper'>
                                        <InboxDetailsHeader header={messageDetails} scanResult={scanResult} parsedData={messageDetails} setInboxDetailActiveTab={setInboxDetailActiveTab} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid id={'detail-action'} item style={{width: '300px'}}>
                            <Paper elevation={2} className={'app-paper'}>
                                <InboxDetailsAction
                                    emailID={id}
                                    priority={priorityValue}
                                    category={categoryValue}
                                    status={statusValue}
                                    detail={messageDetails}
                                    handleDelete={handleDelete}
                                    handleArchiveMail={handleArchiveMail}
                                    handleEmailChanged={handleEmailChanged}
                                    tabValue={tabValue}
                                    originalEmail={originalEmail}
                                    scanResult={scanResult}
                                    isShowAction={isShowAction}
                                    setShowAction={setShowAction}
                                    handlePurgeMail={handlePurgeMail}
                                    loginAzure={loginAzure}
                                    hasAccessToken={hasAccessToken}
                                    emailData={emailData}
                                />
                            </Paper>
                        </Grid>
                        <Grid item md={12} xs={12} sx={{ height: '100%' }}>
                            <Paper elevation={2} className='app-paper' sx={{m: 0, p: 0}}>
                                <EmailDetails
                                    emailID={id}
                                    setOriginalEmail={setOriginalEmail}
                                    setScanResult={setScanResult}
                                    triggerLoadLog={triggerLoadLog}
                                    onGetSuccess={setEmailInfo}
                                    threatAnalyzedOverview={messageDetails.analyzed_overview}
                                    threatAnalyzedDetails={messageDetails.analyzed_details}
                                    setInboxDetailActiveTab={setInboxDetailActiveTab}
                                    inboxDetailActiveTab={inboxDetailActiveTab}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
};
