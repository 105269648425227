import React, {useEffect, useState} from 'react';
import {SearchFilterProps} from './SearchFilter.props';
import { TextInput } from '../../atoms/TextInput';
import './SearchFilter.styles.css'
import {Image} from "../../atoms/Image";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchIcon.svg";

export const SearchFilter: React.FC<SearchFilterProps> = ({
  height,
  widthTextInput,
  padding ,
  /* istanbul ignore next */
  margin = 0,
  titleInput,
  borderColor,
  borderRadius,
  iconSrc,
  cssClass,
  /* istanbul ignore next */
  backgroundColor='#ffffff',
  handleKeyDownEvent,
  inputValue,
  setInputValue,
}) => {

  const handleChangeInput = (param:any) => {
    setInputValue && setInputValue(param);
  };

  return (
    <div className={'search-filter ' + cssClass} style={{padding, margin, backgroundColor}}>
        <TextInput
            width={widthTextInput}
            height={height}
            padding={'0 36px 0 16px'}
            borderRadius={borderRadius}
            borderColor={borderColor}
            placeholderInput={titleInput || 'Search'}
            onChangeInput={handleChangeInput}
            onKeyDownEvent={handleKeyDownEvent}
            value={inputValue}></TextInput>
        <SearchIcon className={'icon-search'}/>
    </div>
  );
};
