import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import './LeavePageDialog.styles.css';
import {DialogBoxProps} from './LeavePageDialog.props';

const LeavePageDialog: React.FC<DialogBoxProps> = ({
     showDialog,
     cancelNavigation,
     confirmNavigation,
 }) => {
    return (
        <div style={{maxWidth: '560px'}}>
            <Dialog
                open={showDialog}
                className={'leave-page-dialog'}
            >
                <DialogTitle id="leave-page-title" sx={{padding:'24px 24px 16px 24px'}}>
                    <WarningIcon sx={{ margin: ' 0 16px -10px 0', fontSize: '35px', color: '#F29100'}} />
                    <span style={{fontSize: '20px', fontWeight: 700}}>Leave this page?</span>
                    <IconButton
                        aria-label="close"
                        onClick={cancelNavigation}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{padding: '0 24px 24px 24px'}}>
                    <DialogContentText id="leave-page-description" sx={{fontSize:'16px', fontWeight: 500, color:'#212121'}}>
                        You have unsaved changes.<br/>
                        Are you sure you want to leave this page?
                        Unsaved changes will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{padding: '0 24px 24px 24px'}}>
                    <Button onClick={confirmNavigation} variant="outlined" size={"large"}>Discard changes and leave</Button>
                    <Button onClick={cancelNavigation} variant="contained" size={"large"}>
                        Stay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default LeavePageDialog;