import React from 'react';

export default (state:any, action:any) => {
   switch(action.type) {
     case 'UPDATE_SELECTED_EMAIL': {
           return {
               ...state,
                selectedEmail: action.payload
           }
       }
     case 'UPDATE_SELECTED_ZONE_ID': {
       return {
         ...state,
         currentZone: action.payload
       }
     }
     case 'UPDATE_SELECTED_ZONE_CA': {
        return {
            ...state,
            ca: action.payload
        }
     }

     case 'UPDATE_ZONE_LIST': {
         return {
             ...state,
             zones: action.payload
         }
     }
       case 'UPDATE_IS_CONFIRM_NAVIGATION': {
           return {
               ...state,
               isConfirmedNavigation: action.payload
           }
       }
       case 'UPDATE_MS365_CURRENT_ZONE': {
           return {
               ...state,
               ms365: action.payload
           }
       }
         default:
             return state;
     }
}
