import React, {useEffect, useState} from 'react';
import {VerifyEmailAddressProps} from "./VerifyEmailAddress.props";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import './VerifyEmailAddress.styles.css';
import { useFormik } from 'formik';
import CancelIcon from '@mui/icons-material/Cancel';
import LinearProgress from "@mui/material/LinearProgress";
import {BASE_DOMAIN_STATUS} from "../../../constants/contants";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const lineLoadingTheme = createTheme({
  palette: {
    primary: {
      main: "#345C8B",
    },
  },
});

export const VerifyEmailAddress: React.FC<VerifyEmailAddressProps> = (
    {
        isOpen=false,
        isLineLoading = false,
        checkStatusResult= '',
        onCancel,
        setIsResendLink,
        setVerifyReturnValue,
        value,
    }
) => {
    const [isCheckStatus, setIsCheckStatus] = useState(false);
    const initialValues = {
        baseDomain: '',
        baseEmail: '',
    }

    const handleOnSubmit = (values: any, isResendLink: boolean) => {
        setIsCheckStatus(!isResendLink)
        setIsResendLink(isResendLink)
        setVerifyReturnValue({isTriggerOnSave: true, values: values})
    }

    const formikVerifyEmail = useFormik({
        initialValues,
        onSubmit: (values) => {
            handleOnSubmit(values, false)
        },
    });

    useEffect(()=>{
        if(isOpen){
          formikVerifyEmail.resetForm();
            if(value) {
              formikVerifyEmail.setValues({baseDomain: value.value, baseEmail: value.value})
            }
        }
    }, [isOpen])

    return (
        <div className={'verify-email-container'} style={{maxWidth: '700px'}}>
            <Dialog
                open={isOpen}
                className={'verify-email-page-dialog'}
                fullWidth={true}
                sx={{zIndex: 100}}
            >
                <form onSubmit={formikVerifyEmail.handleSubmit}>
                <DialogTitle id="verify-email-title" sx={{padding:'16px 24px 16px 24px'}}>
                    <span className={'verify-email-title'}>View sender email address</span>
                    <IconButton
                        aria-label="close"
                        data-testid="btn-close-verify-email-address-popup"
                        onClick={() => onCancel()}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 14,
                            color: '#666666'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{padding: '24px 24px 75px 24px'}} dividers>
                    <Box>
                      <Box className={'verify-email-description'} sx={{marginBottom: '16px'}}>
                        A verification email was sent to the email address below. Check status to to verify whether your contact person/s of the designated zone clicked on the verification link.
                      </Box>
                    <InputLabel className={'verify-email-input-label'}>
                      Email address <span style={{color: 'red'}}>*</span>
                    </InputLabel>
                    <OutlinedInput
                        name={'emailAddress'}
                        id={'emailAddress'}
                        className={'input-field'}
                        disabled={true}
                        size={"small"}
                        sx={{ fontSize: '14px', fontWeight: 500}}
                        value={formikVerifyEmail.values.baseEmail}
                    />
                        <Button
                            className={'btn-verify-email-address-contained'}
                            data-testid="btn-verify-email-address-contained"
                            type="submit"
                            variant="contained"
                            size={"large"}
                            sx={{marginLeft: '8px', width: 'fit-content', marginBottom: '4px', padding: '8px 24px'}}
                            disabled={isLineLoading}
                        >
                            Check Status
                        </Button>
                    </Box>

                    {/*show fail message*/}
                    { (!isLineLoading && checkStatusResult === BASE_DOMAIN_STATUS.FAIL && isCheckStatus) && <p className={'verify-email-message'}>
                        <CancelIcon className={'fail'}/>
                        <span className={'fail'} > This email address has not been verified yet.</span>
                    </p> }
                    {isLineLoading && <p className={'verify-email-loading'}>
                        <ThemeProvider theme={lineLoadingTheme}><LinearProgress sx={{width:'400px', height: '6px'}} /></ThemeProvider>
                        <span className={'message'} >{isCheckStatus ? 'Checking the verification status' : 'Sending a verification email to this email address.'}</span>
                    </p>}
                    {!isLineLoading && <Box sx={{marginTop: '16px'}}>
                      <span className={'resend-verify-email'} >They didn’t receive email or the link is expired? </span>
                      <span className={'link-verify-email'} onClick={() => handleOnSubmit(formikVerifyEmail.values, true)}>{'Resend verification email'}</span>
                    </Box>}
                </DialogContent>
                </form>
            </Dialog>
        </div>
    )
}
