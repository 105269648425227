import React from 'react'
import {TreeMapChartProps} from './TreeMapChart.props'
import './TreeMapChart.styles.css';
import ReactApexChart from "react-apexcharts";

import Box from '@mui/material/Box';
import {NoDataCard} from "../NoDataCard";

const checkMiniumData = (object: {}) => {
  const objectReturn = {}
  let sum : number = 0
  for (const key in object) {
    sum += object[key]
  }
  for (const key in object) {
    objectReturn[key]  = object[key]/sum < 0.1 ? true : false
  }
  return objectReturn
}

export const TreeMapChart: React.FC<TreeMapChartProps> = ({type, data}) => {
  /* istanbul ignore next */
  let hasData = (data.highPriority > 0 || data.lowPriority > 0 || data.mediumPriority > 0 || data.unknownPriority > 0) ? true : false
  let topColor = '#FF3D44';
  let seriesData = data.highPriorityData
  if (type === 'Medium') {
    topColor = '#FFD84C'
    seriesData = data.mediumPriorityData
  }
  if (type === 'Low') {
    topColor = '#6BD0FF'
    seriesData = data.lowPriorityData
  }
  if (type === 'Unknown') {
    topColor = '#909090'
    seriesData = data.unknownPriorityData
  }
   type += ' Priority'
  if (hasData == false) {
    topColor = '#B3B8B8'
  }
  const hoverUI = ({ series, seriesIndex, dataPointIndex, w }: any) => {
    const text = dataPointIndex == 0 ? '| Received |' : dataPointIndex == 1 ? '| In Review |' : '| Resolved |'
    return '<div class="tooltip-marker">' +
      '<p class="tooltip-title" ">' +  type + '</p>' +
      '<p class="tooltip-title" ">' +  text + '</p>' +
      '<p class="tooltip-title" ">' +  series[seriesIndex][dataPointIndex] + '</p>' +
      '</div>';
  }

  const objectCheck = checkMiniumData(seriesData)
  const options = {
    colors: ['#0E4F83', '#3C79D0', '#BDE541'],
    fill: {
      colors: ['#0E4F83', '#3C79D0', '#BDE541'],
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      custom: hoverUI
    },
    dataLabels: {
      style: {
        fontSize: '25px',
      },
      offsetY: -5
    },
    legend: {
        show: false
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
          useFillColorAsStroke: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      chart: {
        width: '100%',
        type: 'treemap',
        toolbar: {show: false}
      },
    };

  const series = [
      {
        data: [
          {
            x: objectCheck['Received'] ? '' : JSON.stringify(seriesData['Received']),
            y: seriesData['Received']
          },
          {
            x:  objectCheck['In Review'] ? '' : JSON.stringify(seriesData['In Review']),
            y: seriesData['In Review']
          },
          {
            x:  objectCheck['Resolved'] ? '' :JSON.stringify(seriesData['Resolved']),
            y: seriesData['Resolved']
          },
        ]
      }
    ];

  let countSeriesData = 0
  series[0].data.map((item:any) => { if(item.y > 0){countSeriesData += 1}})
  const hasSeriesData = countSeriesData > 0 ? true : false
  return (
    <Box className={'treemap-chart '} style={{width: '100%'}}>
      <Box className={'priority-block'}
           sx={{
             width: 'calc(100%)',
             height: 6,
             backgroundColor: topColor,
           }}
      ></Box>
      {/*//@ts-ignores*/
      hasData ? (hasSeriesData ? <ReactApexChart
        type="treemap"
        //@ts-ignores
        options={options}
        series={series}
        height={348}
        width={'101%'}
      /> : /* istanbul ignore next */<NoDataCard type={'botTreeMap'} border={'1px solid #808888'} height={'328px'} borderTop={'0'} padding={'115px 0 0 0'} title={'0'}></NoDataCard>) :
          <NoDataCard type={'botTreeMap'} border={'1px solid #808888'} height={'328px'} borderTop={'0'} padding={'115px 0 0 0'} ></NoDataCard>}
    </Box>


  );
}
