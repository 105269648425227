import React, {useEffect, useState} from 'react'
import {SettingCardProps} from './SettingCard.props'
import './SettingCard.styles.css';
import {Box, FormControlLabel, IconButton, Switch, Tooltip} from "@mui/material";
import {enablePurge, getSettings, updateAutomationRuleSettings, updateSetting} from '../../../api/emailTriage';
import {styled} from "@mui/material/styles";
import {Toast} from "../../atoms/Toast";
import {defaultSettings, useRoleContext} from "../../../context/RoleContext";
import {
  SETTING_BLOCK_LIST,
  SETTING_TYPE_AUTOMATION_RULE,
  SETTING_TYPE_REPORTED_EMAILS_ACTIONS
} from "../../../constants/contants";
import Skeleton from '@mui/material/Skeleton';
import {LoadingPage} from "../../organisms/LoadingPage";
import HelpIcon from "@mui/icons-material/Help";
import {BlocklistConnectionCard} from "../../../components/molecules/BlocklistConnectionCard";

export const SettingCard: React.FC<SettingCardProps> = ({
    title,
    description,
    status,
    type,
    loading
}) => {
  const [settingState, changeSettingState] = useState(false)
  const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0})
  const [modalID, setModalID] = useState(1)
  const { setting, setSetting } = useRoleContext()
  const [loadingPage, setLoadingPage] = useState(false)

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#008545",
      '&:hover': {
        backgroundColor: "#B3DAC7",
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#B3DAC7",
    },
  }));


  const changeSetting = (newStatus:any) => {
    const isChecked = newStatus.target.checked;
    const params = { settingType: type, settingValue: isChecked };

    const afterUpdate = () => {
      getSettings().then((response) => {
        const newSettings = { ...defaultSettings };
        response.data.forEach((item: any) =>
          Object.assign(newSettings, { [item.setting_type]: item.setting_value })
        );
        setSetting(response.data.length > 0 ? newSettings : defaultSettings);
      });
      setLoadingPage(false);
    };

    setLoadingPage(true);
    switch(type) {
      case SETTING_TYPE_REPORTED_EMAILS_ACTIONS: {
        enablePurge(params).then((response) => {
          if (response.status === 200) {
            setModalID(modalID + 1);
            setToastValue({ type: 'success', message: 'Update setting successfully!', isOpen: true, toastId: modalID });
            changeSettingState(isChecked);
          } else {
            setModalID(modalID + 1);
            setToastValue({ type: 'error', message: 'Something went wrong!', isOpen: true, toastId: modalID });
          }
          afterUpdate();
        }).catch(e => {
          console.error(e);
          setLoadingPage(false);
        });
        break;
      }
      case SETTING_TYPE_AUTOMATION_RULE: {
        updateAutomationRuleSettings(params).then((response) => {
          if (response.status === 200) {
            setModalID(modalID + 1);
            setToastValue({ type: 'success', message: 'Update setting successfully!', isOpen: true, toastId: modalID });
            changeSettingState(isChecked);
          } else {
            setModalID(modalID + 1);
            setToastValue({ type: 'error', message: 'Something went wrong!', isOpen: true, toastId: modalID });
          }
          afterUpdate();
        }).catch(e => {
          console.error(e);
          setLoadingPage(false);
        });
        break;
      }
      case SETTING_BLOCK_LIST: {
        updateSetting(params).then((response) => {
          if (response.status === 200) {
            setModalID(modalID + 1);
            setToastValue({ type: 'success', message: 'Update setting successfully!', isOpen: true, toastId: modalID });
            changeSettingState(isChecked);
          } else {
            setModalID(modalID + 1);
            setToastValue({ type: 'error', message: 'Something went wrong!', isOpen: true, toastId: modalID });
          }
          afterUpdate();
        }).catch(e => {
          console.error(e);
          setLoadingPage(false);
        });
        break;
      }
    }
  }

  useEffect(() => {
    changeSettingState(status)
  }, [status])

  return (
      <Box className='setting-wrapper' sx={{
        background: '#fff',
        marginBottom: '20px',
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
        minHeight: '80px',
        boxSizing: 'border-box'
      }}>
        <Box className='setting-content' sx={{
          margin: '16px'
        }}>
          {loading ? <Skeleton animation="wave" sx={{width: '200px', height: '20px'}}/> : <Box className='setting-content-title' sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '16px',
              color: '#212121',
              marginBottom: '10px',
              marginTop: '25px'
            }}>{title} {
              type === SETTING_BLOCK_LIST ? <Tooltip
                  placement={"top"}
                  title={'This feature helps your mail server prevent malicious or spam emails from reaching your users’ inbox. Enable Block List and connect to your Microsoft 365 tenant to manage a unique list of blocklist entries for your organisation.'}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        width: "auto",
                        maxWidth: "700px",
                        padding: "8px",
                        position: "relative",
                        top: "10px",
                        left: "245px"
                      },
                    },
                  }}
              >
                <IconButton className='info-action'><HelpIcon fontSize={'small'} htmlColor={"#193051"}/></IconButton>
              </Tooltip> : null
            }
          </Box>}
          {loading ? <Skeleton animation="wave" sx={{width: '350px', height: '40px'}}/> : <Box className='setting-content-detail' sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-8px',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
          }}>
            <FormControlLabel className={`status-setting ${settingState ? 'enabled' : 'disabled'}`} control={
              <GreenSwitch checked={settingState} onChange={(newStatus) => changeSetting(newStatus)}/>
            } label={description} sx={{marginRight: "10px"}}/>
            {
              type === SETTING_TYPE_REPORTED_EMAILS_ACTIONS ? <Tooltip placement={"top"} title={'Allow to remove emails in users\' inbox'}>
                <IconButton className='info-action'><HelpIcon fontSize={'small'} htmlColor={"#193051"}/></IconButton>
              </Tooltip> : null
            }
          </Box>}
          {
            type === SETTING_BLOCK_LIST ? <BlocklistConnectionCard
                                              setLoadingPage={setLoadingPage}
                                              isEnabledBlocklist={settingState}
                                          /> : null
          }
        </Box>
        <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message} toastId={toastValue.toastId} autoHideDuration={2000}/>
        <LoadingPage open={loadingPage}/>
      </Box>
  );
}
