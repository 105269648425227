import React, {useEffect, useRef, useState,} from "react";
import {MonthPickerProps} from './MonthPicker.props';
import './MonthPicker.styles.css';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {StaticDatePicker} from "@mui/x-date-pickers/StaticDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, {Dayjs} from "dayjs";
import moment from "moment-timezone";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Box, Button} from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export const MonthPicker: React.FC<MonthPickerProps> = ({
        valueDate,
        timeZoneName = 'Etc/UTC',
        handleChange,
        handleCancel
}) => {
    const [date, setDate] = React.useState<Dayjs | null>(valueDate)
    const startZoneDate = localStorage.getItem('startZoneDate')
    const theme = createTheme(
        {
            components: {
                MuiPickersLayout: {
                    styleOverrides: {
                        root: {
                            fontSize: 14,
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            color: "#193051",
                            lineHeight: 17,
                        },
                    },
                }
            },
        },
    );
    
    return (
        <Box className = "month-picker-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}  dateFormats={{ monthAndYear: 'MMM YYYY' }}>
                <ThemeProvider theme={theme}>
                    <StaticDatePicker
                        views={['year', 'month']}
                        openTo="month"
                        minDate={startZoneDate ? dayjs(moment(parseInt(startZoneDate) * 1000).tz(timeZoneName).format('YYYY-MM-DD')) : null}
                        maxDate={dayjs(moment().format('YYYY-MM-DD'))}
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue)
                        }}
                    />
                </ThemeProvider>
            </LocalizationProvider>
            <Box className={"month-picker-actions-dialog"}>
                <Button variant="text" className={"month-picker-cancel"} onClick={() => handleCancel()}>Cancel</Button>
                <Button variant="text" className={"month-picker-apply"} onClick={() => handleChange(date)}>Apply</Button>
            </Box>
        </Box>
    );
};
