import React from 'react'
import {TreeMapChartCardProps} from './TreeMapChartCard.props'
import './TreeMapChartCard.styles.css';

import {TreeMapChart} from "../TreeMapChart";

import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export const TreeMapChartCard: React.FC<TreeMapChartCardProps> = ({
  isLoading,
                                     data
                                                            }) => {


  
  return (
    <Grid item xs={12} container sx={{
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      {['High' , 'Medium', 'Low', 'Unknown'].map((e:any)=> {
        return (
          <Grid className={'tree-map-item'} item key={e} xs={3} sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingRight: '15px',
          }} >
  
             <TreeMapChart type={e} data={data}/>
          </Grid>
        )
      })}
    </Grid>
  )
  
}
