import React, {Fragment, useEffect, useState} from 'react';
import {ScanResultViewerProps} from "./ScanResultViewer.props";
import Skeleton from "@mui/material/Skeleton";
import {Stack, Typography} from "@mui/material";
import moment from 'moment';
import {useTheme} from "@mui/material/styles";
import './ScanResultViewer.style.css';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {SenderAnalysis} from "../SenderAnalysis";
import {AnalysisBox} from "../AnalysisBox";
import {Pluralize} from '../../../utils/common'

import {AnalysisRiskBox} from "../AnalysisRiskBox/AnalysisRiskBox";
import {LabelIcon} from "../../atoms/LabelIcon";
import {NoThreatDetectedCard} from '../NoThreatDetectedCard'
let tz_name = 'Etc/UTC';


export const ScanResultViewer: React.FC<ScanResultViewerProps> = ({
    data,
    analysisScore = undefined,
    setLoading
}) => {
  const [analyticData, setAnalyticData] = useState<any>({})
  const [scanDate, setScanDate] = useState<any>(undefined)

  let totalAttachments = 0
  let totalUrls = 0
  const initDataForm = {
    'high': {total: 0, items: []},
    'moderate': {total: 0, items: []},
    'medium': {total: 0, items: []},
    'low': {total: 0, items: []},
  }

  const [urlList, setUrlList] = useState(initDataForm)
  const [attachmentList, setAttachmentList] = useState(initDataForm)
  const [emailAddressList, setEmailAddressList] = useState(initDataForm)
  const [dnsList, setDnsList] = useState(initDataForm)
  const [ipsList, setIpsList] = useState(initDataForm)

  const bytesToSize = function(bytes:any, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const serializeData = (data:any) => {
    let scanDateValue:any = undefined

    const serialized:any = JSON.parse(JSON.stringify(data))
    if(Object.keys(data).length === 0) { return {
      sender_analysis: undefined,
      urls_analysis: undefined,
      attachments_analysis: undefined,
      emails_analysis: undefined,
      dns_analysis: undefined,
      ips_analysis: undefined
    }}

    const urls = {
      'high': {total: 0, items: []},
      'moderate': {total: 0, items: []},
      'medium': {total: 0, items: []},
      'low': {total: 0, items: []},
    }
    serialized.urls_analysis.forEach((item:any) => {
      if(urls[item['priority']]) {
        urls[item['priority']]['total'] = urls[item['priority']]['total'] + 1
        urls[item['priority']].items.push(item)
      }
      scanDateValue = scanDateValue ? item['date'] : scanDateValue
    })

    const attachments = {
      'high': {total: 0, items: []},
      'moderate': {total: 0, items: []},
      'medium': {total: 0, items: []},
      'low': {total: 0, items: []},
    }
    serialized.attachments_analysis.forEach((item:any) => {
      if(attachments[item['priority']]) {
        attachments[item['priority']]['total'] = attachments[item['priority']]['total'] + 1

        item['value'] = item['data']['file_name'] + "(" + bytesToSize(item['data']['file_size']) + ")"
        item['content'] = <Box sx={{my:1}}>
          <Typography variant={'body2'} className={'app-label'}>Hash (SHA-256):</Typography>
          <Typography variant={'body2'} className={'app-label'}>{item['data']['hash']}</Typography>
        </Box>
        attachments[item['priority']].items.push(item)
        scanDateValue = scanDateValue ? item['date'] : scanDateValue
      }
    })

    const emails = {
      'high': {total: 0, items: []},
      'moderate': {total: 0, items: []},
      'medium': {total: 0, items: []},
      'low': {total: 0, items: []},
    }
    serialized.emails_analysis.forEach((item:any) => {
      if(emails[item['priority']]) {
        emails[item['priority']]['total'] = emails[item['priority']]['total'] + 1
        emails[item['priority']].items.push(item)
      }
      scanDateValue = scanDateValue ? item['date'] : scanDateValue
    })

    const ips = {
      'high': {total: 0, items: []},
      'moderate': {total: 0, items: []},
      'medium': {total: 0, items: []},
      'low': {total: 0, items: []},
    }
    serialized.ips_analysis.forEach((item:any) => {
      if(ips[item['priority']]) {
        ips[item['priority']]['total'] = ips[item['priority']]['total'] + 1
        ips[item['priority']].items.push(item)
      }
      scanDateValue = scanDateValue ? item['date'] : scanDateValue
    })

    const dns = {
      'high': {total: 0, items: []},
      'moderate': {total: 0, items: []},
      'medium': {total: 0, items: []},
      'low': {total: 0, items: []},
    }
    serialized.dns_analysis.forEach((item:any) => {
      if(dns[item['priority']]) {
        dns[item['priority']]['total'] = dns[item['priority']]['total'] + 1
        dns[item['priority']].items.push(item)
      }
      scanDateValue = scanDateValue ? item['date'] : scanDateValue
    })
    setScanDate(scanDateValue)
    setAttachmentList(attachments);
    setUrlList(urls);
    setEmailAddressList(emails);
    setDnsList(dns);
    setIpsList(ips);

    return serialized
  }

  useEffect(() => {
    setLoading(data === undefined)
    setAnalyticData(serializeData(data))
    if (typeof(data) === 'object' && data != null && data.scan_date) {
      setScanDate(data.scan_date)
    }

  }, [data])

  const theme = useTheme();

  const localStorageUserData = localStorage.getItem('userData');
  if(localStorageUserData) {
    const parseUserData = JSON.parse(localStorageUserData)
    tz_name = parseUserData["tz_name"]
  }


  return (
    <Fragment>
      {
        data === undefined ?
          (<Skeleton variant="rounded" width={'100%'} height={60} />)
        : analysisScore === -1 ? <NoThreatDetectedCard score={analysisScore} /> :
          (
              <>
                {
                  analyticData?.sender_analysis ? (<Box sx={{p: 3, pb: 0}} className={'app-analysis-container scan-result-sender-box'}>
                    <Box sx={{mb: 3}} className={""}>
                      <Typography variant={'h5'} className={'app-ui'}>
                        Sender analysis
                      </Typography>
                    </Box>
                    <SenderAnalysis payload={analyticData.sender_analysis}/>
                  </Box>) : null
                }

                {
                  analyticData?.ips_analysis ? (<div className={'app-analysis-container scan-result-ip-box'}>
                    <Box sx={{p: 3}} className={"ss"}>
                      <div className={'app-flex-horizontal'}>
                        <Typography variant={'h5'} className={'app-ui'}>
                          IP analysis
                        </Typography>
                        <div className={'app-vertical-line'}>|</div>
                        <div className={'app-flex-horizontal app-flex-gap-1'}>
                          <Typography variant={'h6'} className={'app-light-header app-color-brand'}> {analyticData.ips_analysis.length} </Typography>
                          <Typography variant={'h6'} className={'app-light-header'}> {analyticData.ips_analysis.length == 1 ? 'IP found' : 'IPs found'}</Typography>
                        </div>
                      </div>
                    </Box>
                    <Grid container>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'high',
                            title: 'Threat ' + Pluralize("IP", ipsList['high'].total),
                            total: ipsList['high'].items.length,
                            items: ipsList['high'].items,
                            not_found_message: 'No IP Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'moderate',
                            title: 'Undetermined ' + Pluralize("IP", ipsList['moderate'].total > 0 ? ipsList['moderate'].total : ipsList['medium'].total),
                            total: ipsList['moderate'].total > 0 ? ipsList['moderate'].total : ipsList['medium'].total,
                            items: ipsList['moderate'].items.length > 0 ? ipsList['moderate'].items : ipsList['medium'].items ,
                            not_found_message: 'No IP Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'low',
                            title: 'No threat ' + Pluralize("IP", ipsList['low'].total),
                            total: ipsList['low'].items.length,
                            items: ipsList['low'].items,
                            not_found_message: 'No IP Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>) : null
                }

                {
                  analyticData?.dns_analysis ? (<div className={'app-analysis-container scan-result-dns-box'}>
                    <Box sx={{p: 3}} className={"ss"}>
                      <div className={'app-flex-horizontal'}>
                        <Typography variant={'h5'} className={'app-ui'}>
                          DNS analysis
                        </Typography>
                        <div className={'app-vertical-line'}>|</div>
                        <div className={'app-flex-horizontal app-flex-gap-1'}>
                          <Typography variant={'h6'} className={'app-light-header app-color-brand'}> {analyticData.dns_analysis.length} </Typography>
                          <Typography variant={'h6'} className={'app-light-header'}> {analyticData.dns_analysis.length == 1 ? 'DNS found' : 'DNSs found'}</Typography>
                        </div>
                      </div>
                    </Box>
                    <Grid container>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'high',
                            title: 'Threat ' + Pluralize("DNS", dnsList['high'].total),
                            total: dnsList['high'].items.length,
                            items: dnsList['high'].items,
                            not_found_message: 'No DNS Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'moderate',
                            title: 'Undetermined ' + Pluralize("DNS", dnsList['moderate'].total > 0 ? dnsList['moderate'].total : dnsList['medium'].total),
                            total: dnsList['moderate'].total > 0 ? dnsList['moderate'].total : dnsList['medium'].total,
                            items: dnsList['moderate'].items.length > 0 ? dnsList['moderate'].items : dnsList['medium'].items ,
                            not_found_message: 'No DNS Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'low',
                            title: 'No threat ' + Pluralize("DNS", dnsList['low'].total),
                            total: dnsList['low'].items.length,
                            items: dnsList['low'].items,
                            not_found_message: 'No DNS Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>) : null
                }

                {
                  analyticData?.emails_analysis ? (<div className={'app-analysis-container scan-result-email-box'}>
                    <Box sx={{p: 3}} className={"ss"}>
                      <div className={'app-flex-horizontal'}>
                        <Typography variant={'h5'} className={'app-ui'}>
                          Email address analysis
                        </Typography>
                        <div className={'app-vertical-line'}>|</div>
                        <div className={'app-flex-horizontal app-flex-gap-1'}>
                          <Typography variant={'h6'} className={'app-light-header app-color-brand'}> {analyticData.emails_analysis.length} </Typography>
                          <Typography variant={'h6'} className={'app-light-header'}> {analyticData.emails_analysis.length == 1 ? 'Email address found' : 'Email address found'}</Typography>
                        </div>
                      </div>
                    </Box>
                    <Grid container>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'high',
                            title: emailAddressList['high'].total === 1 ? "Threat address" : "Threat addresses" ,
                            total: emailAddressList['high'].items.length,
                            items: emailAddressList['high'].items,
                            not_found_message: 'No Address Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'moderate',
                            title: emailAddressList['moderate'].total === 1 || emailAddressList['medium'].total === 1? "Undetermined address" : "Undetermined addresses" ,
                            total: emailAddressList['moderate'].total > 0 ? emailAddressList['moderate'].total : emailAddressList['medium'].total,
                            items: emailAddressList['moderate'].items.length > 0 ? emailAddressList['moderate'].items : emailAddressList['medium'].items ,
                            not_found_message: 'No Address Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'low',
                            title: emailAddressList['low'].total === 1 ? 'No threat address' : 'No threat addresses',
                            total: emailAddressList['low'].items.length,
                            items: emailAddressList['low'].items,
                            not_found_message: 'No Address Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>) : null
                }

                {
                  analyticData?.urls_analysis ? (<div className={'app-analysis-container scan-result-url-box'}>
                    <Box sx={{p: 3}} className={"ss"}>
                      <div className={'app-flex-horizontal'}>
                        <Typography variant={'h5'} className={'app-ui'}>
                          URL analysis
                        </Typography>
                        <div className={'app-vertical-line'}>|</div>
                        <div className={'app-flex-horizontal app-flex-gap-1'}>
                          <Typography variant={'h6'} className={'app-light-header app-color-brand'}> {analyticData.urls_analysis.length} </Typography>
                          <Typography variant={'h6'} className={'app-light-header'}> {analyticData.urls_analysis.length == 1 ? 'URL found' : 'URLs found'}</Typography>
                        </div>
                      </div>
                    </Box>

                    <Grid container>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'high',
                            title: 'Threat ' + Pluralize("URL", urlList['high'].total),
                            total: urlList['high'].items.length,
                            items: urlList['high'].items,
                            not_found_message: 'No URL Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'moderate',
                            title: 'Undetermined ' + Pluralize("URL", urlList['moderate'].total > 0 ? urlList['moderate'].total : urlList['medium'].total),
                            total: urlList['moderate'].total > 0 ? urlList['moderate'].total : urlList['medium'].total,
                            items: urlList['moderate'].items.length > 0 ? urlList['moderate'].items : urlList['medium'].items ,
                            not_found_message: 'No URL Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'low',
                            title: 'No threat ' + Pluralize("URL", urlList['low'].total),
                            total: urlList['low'].items.length,
                            items: urlList['low'].items,
                            not_found_message: 'No URL Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                    </Grid>

                  </div>) : null
                }

                {
                  analyticData?.attachments_analysis ? (<div className={'app-analysis-container scan-result-attachment-box'}>
                    <Box sx={{p: 3}} className={"ss"}>
                      <div className={'app-flex-horizontal'}>
                        <Typography variant={'h5'} className={'app-ui'}>
                          Attachment analysis
                        </Typography>
                        <div className={'app-vertical-line'}>|</div>
                        <div className={'app-flex-horizontal app-flex-gap-1'}>
                          <Typography variant={'h6'} className={'app-light-header app-color-brand'}> {analyticData.attachments_analysis.length} </Typography>
                          <Typography variant={'h6'} className={'app-light-header'}> {analyticData.attachments_analysis.length == 1 ? 'Attachment found' : 'Attachments found'}</Typography>
                        </div>
                      </div>
                    </Box>
                    <Grid container>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'high',
                            title: 'Threat detected ' + Pluralize("Attachment", attachmentList['high'].total),
                            total: attachmentList['high'].items.length,
                            items: attachmentList['high'].items,
                            not_found_message: 'No Attachment Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'moderate',
                            title: 'Undetermined ' + Pluralize("Attachment", attachmentList['moderate'].total > 0 ? attachmentList['moderate'].total : attachmentList['medium'].total),
                            total: attachmentList['moderate'].total > 0 ? attachmentList['moderate'].total : attachmentList['medium'].total,
                            items: attachmentList['moderate'].items.length > 0 ? attachmentList['moderate'].items : attachmentList['medium'].items ,
                            not_found_message: 'No Attachment Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} >
                        <Box sx={{px: 3}} className={"ss"}>
                          <AnalysisBox payload={{
                            priority:'low',
                            title: 'No threat ' + Pluralize("Attachment", attachmentList['low'].total),
                            total: attachmentList['low'].items.length,
                            items: attachmentList['low'].items,
                            not_found_message: 'No Attachment Found',
                            analysed_date: moment.unix(scanDate).format("DD/MM/YYYY")
                          }} ></AnalysisBox>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>) : null
                }
              </>
          )
      }
    </Fragment>
  );
};
