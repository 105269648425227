import React, {useEffect, Fragment, useState} from 'react';
import Box from "@mui/material/Box";
import { AnalysisSummaryViewerProps } from './AnalysisSummaryViewer.props';
import './AnalysisSummaryViewer.styles.css'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorIcon from "@mui/icons-material/Error";
import LaunchIcon from '@mui/icons-material/Launch';
import PersonIcon from '@mui/icons-material/Person';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PlaceIcon from '@mui/icons-material/Place';
import DnsIcon from '@mui/icons-material/Dns';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {Image} from "../../atoms/Image";
import {Images} from '../../../configs/images';
import {PieChart, pieArcLabelClasses, pieArcClasses} from '@mui/x-charts/PieChart';
import WarningIcon from "@mui/icons-material/Warning";
import {NoThreatDetectedCard} from '../NoThreatDetectedCard'
import {ThreatScoreTooltip} from "../../atoms/ThreatScoreTooltip";
import {styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";

export const AnalysisSummaryViewer: React.FC<AnalysisSummaryViewerProps>= ({
    payLoad = {},
    handleViewScanResultDetail,
    setLoadingPage
}) => {
    const threatScoreData = payLoad
    const isNoData = Object.keys(threatScoreData).length === 0

    const [timer, setTimer] = useState<any>(null)

    useEffect(() => {
        setLoadingPage(isNoData)
    }, [threatScoreData])

    const threatCases = [
        {color: "#DB071E", score: 100, description: "THREAT DETECTED", explain: "Threat detected in scanned email."},
        {color: "#DB071E", score: 75, description: "THREAT DETECTED", explain: "Threat detected in scanned email."},
        {color: "#FFA000", score: 50, description: "UNDETERMINED", explain: "Unable to analyse all components to determine possible threats."},
        {color: "#03A9F5", score: 0, description: "NO THREAT", explain: "No threat detected in scanned email."},
        {color: "#909090", score: -1, description: "ANALYSING...", explain: "This email is in the process of being analysed to determine possible threats."}
    ]

    const priorityStyleDataForm:any = {
        "high": {
            style: {
                color: "#fff",
                backgroundColor: "#ED1C24"
            },
            text: "High",
            suggestionContent: "Emails with a high priority contain a potential threat and should be investigated and addressed immediately.",
        },
        "medium":  {
            style: {
                color: "#fff",
                backgroundColor: "#FCAB10"
            },
            text: "Medium",
            suggestionContent: "Emails with medium priority contain undetermined Threat Analysis results and should be investigated further."
        },
        "moderate":  {
            style: {
                color: "#fff",
                backgroundColor: "#FCAB10"
            },
            text: "Moderate",
            suggestionContent: "Emails with medium priority contain undetermined Threat Analysis results and should be investigated further."
        },
        "low": {
            style: {
                color: "#fff",
                backgroundColor: "#03A9F5"
            },
            text: "Low",
            suggestionContent: "Emails with low priority do not appear to contain any threat."
        },
        "unknown": {
            style: {
                color: "#fff",
                backgroundColor: "#909090"
            },
            text: "Unknown",
            suggestionContent: "Priority is yet to be determined, check back at a later time when the email has been analysed."
        }
    }

    const senderObjectNames:object = {
        "sender_email": "Sender email address",
        "sender_ip": "IP location",
        "sender_ip_v6": "IP location",
        "sender_ip_v4": "IP location",
    }

    const deliveryInformationKeys:string[] = ["header_spf", "header_dmarc", "header_dkim"]

    const pieChartStartPoint = -90
    let getArcLabel:any
    let pieChartEndPoint:number = 0

    let pieChartData:any = []
    let priorityStyleData:any = []
    let explainText:string = ""
    let messageIcon:React.ReactElement = <></>
    let summary_analysis_category = threatScoreData?.category
    let priorityKey = threatScoreData?.priority?.toLowerCase()

    if (!isNoData){
        const totalThreatScore = threatScoreData.score
        // -1 is analysing state
        if (totalThreatScore === -1){
            getArcLabel = () => ("N/A")
        }else {
            getArcLabel = () => (totalThreatScore.toString())
            pieChartEndPoint = pieChartStartPoint + (1.8 * totalThreatScore)
            // handle temporarily when delivery information is not calculated to total score
            if(totalThreatScore === 50){
                let sendersTempArr:any = []
                threatScoreData.senders.map((item: any) => {
                    if(!deliveryInformationKeys.includes(item.entity_type)){
                        sendersTempArr.push(item)
                    }
                })
                threatScoreData.senders = sendersTempArr
            }
        }

        threatCases.map((item: any) => {
            if (totalThreatScore === item.score){
                pieChartData.push(
                    {
                        label: item.description,
                        value: totalThreatScore,
                        color: item.color
                    }
                )
                explainText = item.explain
                return
            }
        })

        summary_analysis_category = threatScoreData?.category
        threatScoreData.suggestions.map((item:any) => {
            if (item.message){
                priorityStyleDataForm[priorityKey].suggestionContent = item.message
                return
            }
        })
        priorityStyleData.push(priorityStyleDataForm[priorityKey])
        messageIcon = totalThreatScore === 50 ? <RemoveCircleIcon style={{marginRight: '8px'}} htmlColor={'#FCAB10'}/> : <WarningIcon style={{marginRight: '8px'}} htmlColor={'#FCAB10'}/>
    }

    const ThreatAnalysisTooltipStyle = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip className={'custom-tooltip'} {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffffff',
            color: '#212121',
            alignItems: "center",
            maxWidth: "480px",
            borderRadius: "4px",
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
            fontSize: '14px',
            padding: '16px',
        },
    }));

    const bytesToSize = function(bytes:any, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const handleChangeEmailDetailActiveTab = (type:string) => {
        // 1 is index number of Scan Result tab in EmailDetails
        handleViewScanResultDetail(1)
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        return new Promise(() => {
            const timers = setTimeout(async () => {
                const classNameElement = 'scan-result-'+type+'-box'
                const emailDetailElement = document.getElementById('email-detail')
                const scanResultBox = emailDetailElement?.getElementsByClassName(classNameElement)[0]

                if (scanResultBox) {
                    scanResultBox.scrollIntoView({ behavior: 'smooth', block: "center"})
                }
            }, 100)
            setTimer(timers)
        });
    }

    const handleEntitiesContentComponent = (type:string, dataList:any) => {
        const pluralizeForm = {
            ip: "IP is",
            ips: "IPs are",
            dns: "DNS is",
            dnss: "DNSs are",
            email: "Email address is",
            emails: "Email addresses are",
            url: "URL is",
            urls: "URLs are",
            attachment: "Attachment is",
            attachments: "Attachments are"
        }

        let threatList:any = []
        let undeterminedList:any = []
        const threatTag = "threat"
        const undeterminedTag = "undetermined"
        if (type === "url" || type === "attachment"){
            dataList.map((item:any) => {
                let content = ""
                if (type === "attachment"){
                    content = item.file_name + ' (' + bytesToSize(item.file_size) + ')'
                }else {
                    content = item.data
                }

                if (item.threat_category === "malicious"){
                    threatList.push(content)
                }else {
                    undeterminedList.push(content)
                }
            })
        }else {
            dataList.map((item:any) => {
                if (item.priority === "high"){
                    threatList.push(item.data)
                }else {
                    undeterminedList.push(item.data)
                }
            })
        }

        const threatMessage = <>{pluralizeForm[threatList.length === 1 ? type : type+"s"]} classified as {threatList.length === 1 ? "a " : ""}<strong>{threatTag}</strong>:</>
        const undeterminedMessage =  <>{pluralizeForm[undeterminedList.length === 1 ? type : type+"s"]} classified as {undeterminedList.length === 1 ? "a " : ""}<strong>{undeterminedTag}</strong>:</>

        return  <Box className={"entities-content"}>
            {
                threatList.length > 0 ? <Box className={"entities-attribute"} >
                    <WarningIcon style={{marginRight: '8px'}} htmlColor={'#ED1C24'}/>
                    {
                        <Box className={'entities-attribute-message'}>
                            {threatMessage}
                            {
                                threatList.map((item:string, idx:any) =>
                                    <Box sx={{marginLeft: "10px", wordBreak: "break-word", textOverflow: "ellipsis"}} key={idx}>. &nbsp; {item}</Box>
                                )
                            }
                        </Box>
                    }
                </Box> : <></>
            }
            {
                undeterminedList.length > 0 ? <Box className={"entities-attribute"} >
                    <RemoveCircleIcon style={{marginRight: '8px'}} htmlColor={'#FCAB10'}/>
                    {
                        <Box className={'entities-attribute-message'}>
                            {undeterminedMessage}
                            {
                                undeterminedList.map((item:string, idx:any) =>
                                    <Box sx={{marginLeft: "10px", wordBreak: "break-word", textOverflow: "ellipsis"}} key={idx}>. &nbsp; {item}</Box>
                                )
                            }
                        </Box>
                    }
                </Box> : <></>
            }
        </Box>
    }

    const handleReasonNote = () => {
        let reasonNote = "reason"
        let count = 0
        if(!isNoData){
            const keyArr = ["senders", "ips", "dns", "emails", "urls", "attachments", "content_overall"]
            keyArr.map((item: string) => {
                if (threatScoreData[item].length > 0) {
                    count += 1
                }
            })
        }

        if (count != 1){
            reasonNote = "reasons"
        }
        return <Box sx={{marginTop: "24px"}}>
            Phish Focus has detected this as a <strong>high priority</strong> for the following {reasonNote}:
        </Box>
    }

  return (
    isNoData ? <Skeleton variant="rectangular" sx={{height: '100px', borderRadius: '4px'}} /> :
    <Box className={"summary-analysis-viewer-wrapper"}>
        <Box className={"left-partial"}>
            <Box className={"left-partial-header"}>
                <span className={"title"}>Overview</span>
                <ThreatAnalysisTooltipStyle placement={"top"} className={"custom-tooltip"} title={<ThreatScoreTooltip />} >
                    <InfoOutlinedIcon sx={{position: "relative", top: "4px", cursor: "pointer"}} fontSize={"medium"} htmlColor={"#193051"}/>
                </ThreatAnalysisTooltipStyle>
            </Box>
            {
                <Box className={"left-partial-threat-score"}>
                    <Box className={"chart"}>
                        <PieChart
                            series={[
                                {
                                    startAngle: pieChartStartPoint,
                                    endAngle: 90,
                                    paddingAngle: 0,
                                    innerRadius: 100,
                                    outerRadius: 145,
                                    data: [{value: 100, color: '#F2F3F3'}]
                                },
                                {
                                    startAngle: pieChartStartPoint,
                                    endAngle: pieChartEndPoint,
                                    innerRadius: 105,
                                    outerRadius: 140,
                                    arcLabel: getArcLabel,
                                    data: pieChartData,
                                },
                            ]}
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: pieChartData[0]?.color,
                                    textShadow: "0 0 2px rgba(0, 0, 0, 0.25)",
                                    fontFamily: "Montserrat",
                                    fontSize: "45px",
                                    fontWeight: 700,
                                    transform: "translate(0, -20%)!important"
                                },
                                [`& .${pieArcClasses.root}`]: {
                                    stroke: '#E0E0E0'
                                }
                            }}
                            skipAnimation
                            tooltip={{ trigger: 'none' }}
                            slotProps={{legend: { hidden: true },}}
                            margin={{right: 0, left: 0, top: 145}}
                        />
                    </Box>
                    <Box className={"chart-info"}>
                        <span >0</span>
                        <span >Threat Score</span>
                        <span >100</span>
                    </Box>
                    <Box className={"result-score"}>
                        <span style={{backgroundColor: pieChartData[0]?.color}}>{pieChartData[0]?.label}</span>
                    </Box>
                    <Box className={"result-score-explain"}>
                        {explainText}
                    </Box>
                </Box>
            }
            <Box className={"left-partial-result"}>
                <Box className={"priority-info"} >
                    <Box>
                        <span style={{fontWeight: 500}}>Priority: </span>
                    </Box>
                    <Box className={"priority-value"} style={priorityStyleData[0]?.style}>{priorityStyleData[0]?.text}</Box>
                </Box>
                <Box className={"category-info"}>
                    <Box>
                        <span style={{fontWeight: 500}}>Category: </span>
                    </Box>
                    <Box style={{fontWeight: 600}}>{summary_analysis_category}</Box>
                </Box>
            </Box>
            <Box className={"left-partial-description"}>
                <span style={{color: "#00000099", fontSize: "16px", fontWeight: 600}}>What should we do?</span>
                <Box className={'description-box'} sx={{backgroundColor: "#EDF1F5"}} >
                    <Box sx={{width: "4px", backgroundColor: "#467599", borderRadius: "4px 0 0 4px"}} />
                    <Box sx={{margin: "8px", display: "flex", alignItems: "center"}}>
                        <Box className={'info-icon'}>
                            <ErrorIcon fontSize={"small"} sx={{display: "flex", height: "auto"}}/>
                        </Box>
                        <Box className={'description-content'} sx={{color: "#467599"}}>
                            {priorityStyleData[0]?.suggestionContent}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

        <Box className={"right-partial"}>
            <span className={"title"}>Key indicator</span>
            {
                pieChartData[0]?.value <= 50 ? <NoThreatDetectedCard score={pieChartData[0]?.value}/> :
                <Fragment>
                    {
                        pieChartData[0]?.value >= 50 ? handleReasonNote() : <></>
                    }
                    {
                        threatScoreData?.senders?.length > 0 ?
                            <Box className={"senders-analysis-content"}>
                                <Box className={"entities-header"}>
                                    <Box className={"left-entities-header"}>
                                        <Box className={"left-icon"}>
                                            <PersonIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Box>
                                        <span className={"entities-title"}>Sender Analysis</span>
                                    </Box>
                                    <Tooltip placement={"top"} title={'More Details'}>
                                        <Button className={"view-scan-result-detail"} onClick={() => handleChangeEmailDetailActiveTab('sender')}>
                                            <LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Button>
                                    </Tooltip>
                                </Box>
                                <Box className={"entities-content"}>
                                    {
                                        threatScoreData.senders.map((item:any, index:any) =>
                                            {
                                                let senderMessageIcon = <WarningIcon style={{marginRight: '8px'}} htmlColor={'#ED1C24'}/>
                                                let senderMessageBox:any;
                                                if(Object.keys(senderObjectNames).includes(item.entity_type)){
                                                    const isHighThreat = item.priority.toLowerCase() === "high"
                                                    senderMessageIcon = isHighThreat ? <WarningIcon style={{marginRight: '8px'}} htmlColor={'#ED1C24'}/> : <RemoveCircleIcon style={{marginRight: '8px'}} htmlColor={'#FCAB10'}/>
                                                    senderMessageBox = <Box>
                                                        The <strong>{senderObjectNames[item.entity_type]}</strong> {item.entity_type === "sender_email" ? `(${item.value})` : ''} is classified as a <strong>{isHighThreat ? "threat" : "undetermined"}</strong>:
                                                        <Box>Common possibilities (not limited to):</Box>
                                                        {
                                                            isHighThreat && item.reasons.length > 0 ? item.reasons.map((reason:string, idx:any) =>
                                                                <Box sx={{marginLeft: "10px", wordBreak: "break-word", textOverflow: "ellipsis"}} key={"reason_"+idx}>. &nbsp; {reason}</Box>
                                                            ) : <>
                                                                <Box sx={{marginLeft: "10px", wordBreak: "break-word", textOverflow: "ellipsis"}} >. &nbsp;
                                                                    Incomplete or inaccurate geolocation databases
                                                                </Box>
                                                                <Box sx={{marginLeft: "10px", wordBreak: "break-word", textOverflow: "ellipsis"}} >. &nbsp;
                                                                    Privacy protection measures
                                                                </Box>
                                                            </>
                                                        }
                                                    </Box>
                                                }else {
                                                    senderMessageBox = <Box>{item.message}</Box>
                                                }

                                                return <Box className={"entities-attribute"} key={index}>
                                                    {senderMessageIcon}
                                                    <Box className={'entities-attribute-message'}>
                                                        {senderMessageBox}
                                                    </Box>
                                                </Box>
                                            }
                                        )
                                    }
                                </Box>
                            </Box>
                        : <></>
                    }
                    {
                        threatScoreData?.ips?.length > 0 ?
                            <Box className={"ips-analysis-content"}>
                                <Box className={"entities-header"}>
                                    <Box className={"left-entities-header"}>
                                        <Box className={"left-icon"}>
                                            <PlaceIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Box>
                                        <span className={"entities-title"}>IP Analysis</span>
                                    </Box>
                                    <Tooltip placement={"top"} title={'More Details'}>
                                        <Button className={"view-scan-result-detail"} onClick={() => handleChangeEmailDetailActiveTab('ip')}>
                                            <LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Button>
                                    </Tooltip>
                                </Box>
                                {handleEntitiesContentComponent("ip", threatScoreData.ips)}
                            </Box>
                            : <></>
                    }
                    {
                        threatScoreData?.dns?.length > 0 ?
                            <Box className={"dns-analysis-content"}>
                                <Box className={"entities-header"}>
                                    <Box className={"left-entities-header"}>
                                        <Box className={"left-icon"}>
                                            <DnsIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Box>
                                        <span className={"entities-title"}>DNS Analysis</span>
                                    </Box>
                                    <Tooltip placement={"top"} title={'More Details'}>
                                        <Button className={"view-scan-result-detail"} onClick={() => handleChangeEmailDetailActiveTab('dns')}>
                                            <LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Button>
                                    </Tooltip>
                                </Box>
                                {handleEntitiesContentComponent("dns", threatScoreData.dns)}
                            </Box>
                            : <></>
                    }
                    {
                        threatScoreData?.emails?.length > 0 ?
                            <Box className={"emails-analysis-content"}>
                                <Box className={"entities-header"}>
                                    <Box className={"left-entities-header"}>
                                        <Box className={"left-icon"}>
                                            <AlternateEmailIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Box>
                                        <span className={"entities-title"}>Email Address Analysis </span>
                                    </Box>
                                    <Tooltip placement={"top"} title={'More Details'}>
                                        <Button className={"view-scan-result-detail"} onClick={() => handleChangeEmailDetailActiveTab('email')}>
                                            <LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Button>
                                    </Tooltip>
                                </Box>
                                {handleEntitiesContentComponent("email", threatScoreData.emails)}
                            </Box>
                            : <></>
                    }
                    {
                        threatScoreData?.urls?.length > 0 ?
                            <Box className={"urls-analysis-content"}>
                                <Box className={"entities-header"}>
                                    <Box className={"left-entities-header"}>
                                        <Box className={"left-icon"}>
                                            <Image srcImg={Images.AddLink} width={"24px"} height={"24px"}
                                                   cssClass={"add-link-icon"}/>
                                        </Box>
                                        <span className={"entities-title"}>URL Analysis</span>
                                    </Box>
                                    <Tooltip placement={"top"} title={'More Details'}>
                                        <Button className={"view-scan-result-detail"} onClick={() => handleChangeEmailDetailActiveTab('url')}>
                                            <LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Button>
                                    </Tooltip>
                                </Box>
                                {handleEntitiesContentComponent("url", threatScoreData.urls)}
                            </Box>
                        : <></>
                    }
                    {
                        threatScoreData?.attachments?.length > 0 ?
                            <Box className={"attachments-analysis-content"}>
                                <Box className={"entities-header"}>
                                    <Box className={"left-entities-header"}>
                                        <Box className={"left-icon"}>
                                            <InsertDriveFileIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Box>
                                        <span className={"entities-title"}>Attachment Analysis</span>
                                    </Box>
                                    <Tooltip placement={"top"} title={'More Details'}>
                                        <Button className={"view-scan-result-detail"} onClick={() => handleChangeEmailDetailActiveTab('attachment')}>
                                            <LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Button>
                                    </Tooltip>
                                </Box>
                                {handleEntitiesContentComponent("attachment", threatScoreData.attachments)}
                            </Box>
                        : <></>
                    }
                    {
                        threatScoreData?.content_overall?.length > 0 ?
                            <Box className={"text-content-analysis-content"}>
                                <Box className={"entities-header"}>
                                    <Box className={"left-entities-header"}>
                                        <Box className={"left-icon"}>
                                            <TextFieldsIcon fontSize={"medium"} htmlColor={"#193051"}/>
                                        </Box>
                                        <span className={"entities-title"}>Text Content</span>
                                    </Box>
                                    {/*<Button className={"view-scan-result-detail"} onClick={() => handleChangeEmailDetailActiveTab('text')}>*/}
                                    {/*    <LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>*/}
                                    {/*</Button>*/}
                                </Box>
                                <Box className={"entities-content"}>
                                    {
                                        threatScoreData.content_overall.map((item:any, index:any) => {
                                            const textContentMessage:string = `${item.message}`
                                            return <Box className={"entities-attribute"} key={index}>
                                                {messageIcon}
                                                <Box className={'entities-attribute-message'}>{textContentMessage}</Box>
                                            </Box>
                                            }
                                        )
                                    }
                                </Box>
                            </Box>
                        : <></>
                    }
                </Fragment>
            }
        </Box>
    </Box>
  );
};
