/* istanbul ignore file */
import PH2Logo from '../assets/images/ph2-logo.svg';
import DashboardIcon from '../assets/images/dashboard.svg';
import ActionIcon from '../assets/images/action.svg';
import InboxIcon from '../assets/images/inbox.svg';
import ReportIcon from '../assets/images/pie_chart.svg';
import SearchIcon from '../assets/images/search.svg';
import Avatar from '../assets/images/avatar.svg';
import Logout from '../assets/images/logout.svg';
import Calendar from '../assets/images/calendar.svg';
import SearchFilter from '../assets/images/searchIcon.svg';
import SearchInFilter from '../assets/images/search-in-filter.svg';
import Edit from '../assets/images/edit.svg';
import Spinner from '../assets/images/spinner.gif';
import Wave from '../assets/images/wave.png';
import NotFoundIcon from '../assets/images/NotFoundIcon.png';
import DarkLogo from '../assets/images/dark-logo.svg';
import Logo from '../assets/images/logo.svg';
import UpdateIcon from '../assets/images/updateIcon.svg';
import Arrowleft from '../assets/images/keyboard_arrow_left.svg';
import ArrowRight from '../assets/images/keyboard_arrow_right.svg';
import GrayImage from '../assets/images/graybackground.svg';
import BuildIcon from '../assets/images/build.svg';
import MailIcon from '../assets/images/mail.svg';
import SettingIcon from '../assets/images/settings.svg';
import MenuIcon from '../assets/images/drag_handle.svg';
import SortArrowDown from '../assets/images/keyboard-arrow-down.svg';
import SortArrowUp from '../assets/images/keyboard-arrow-up.svg';
import AvatarLogo from '../assets/images/avatarLogo.svg';
import LogoOnly from '../assets/images/Vector.svg';
import HamburgerMenu from '../assets/images/hamburgerMenu.svg';
import MoreVertical from '../assets/images/more-vertical.svg';
import SendMessage from '../assets/images/send-message.svg';
import ArrowDropDownGrey from '../assets/images/arrow_dropdown-grey.svg';
import IconChecked from '../assets/images/ico_checked.svg';
import SummaryChecked from '../assets/images/summary-check.svg';
import SummaryView from '../assets/images/summary-view.svg';
import SummaryReceived from '../assets/images/summary-received.svg';
import SuccessToast from '../assets/images/check-toast.svg';
import CloseIcon from '../assets/images/close.svg';
import ArrowBack from '../assets/images/arrow_back.svg';
import FilterIcon from '../assets/images/filter_list.svg';
import CloseGray from '../assets/images/close-gray.svg';
import DynamicColumn from '../assets/images/dynamic_column.svg';
import AttachEmail from '../assets/images/attach_email.svg';
import BreadscrumRight from '../assets/images/breadscrum_right.svg';
import LogoutIcon  from "../assets/images/entypo_log-out.svg";
import LockIcon  from "../assets/images/lock.svg";
import AddLink from "../assets/images/add-link.svg";
import RemoveIcon  from "../assets/images/remove_icon.svg";


export const Images = {
  LogoutIcon,
  LockIcon,
  CloseIcon,
  PH2Logo,
  DashboardIcon,
  ActionIcon,
  InboxIcon,
  ReportIcon,
  SearchIcon,
  SearchInFilter,
  Avatar,
  Logout,
  Edit,
  Calendar,
  SearchFilter,
  Spinner,
  Wave,
  NotFoundIcon,
  DarkLogo,
  UpdateIcon,
  Arrowleft,
  GrayImage,
  MailIcon,
  BuildIcon,
  SettingIcon,
  MenuIcon,
  ArrowRight,
  SortArrowDown,
  SortArrowUp,
  AvatarLogo,
  LogoOnly,
  HamburgerMenu,
  MoreVertical,
  SendMessage,
  ArrowDropDownGrey,
  IconChecked,
  SummaryChecked,
  SummaryView,
  SummaryReceived,
  SuccessToast,
  ArrowBack,
  Logo,
  FilterIcon,
  CloseGray,
  DynamicColumn,
  AttachEmail,
  BreadscrumRight,
  AddLink,
  RemoveIcon
}
