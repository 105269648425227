import React from 'react'
import {ColumnChartProps} from './ColumnChart.props'
import './ColumnChart.styles.css';
import ReactApexChart from "react-apexcharts";
import {NoDataCard} from "../NoDataCard";
export const ColumnChart: React.FC<ColumnChartProps> = ({data}) => {
  
  const seriesData = data &&  Object.values(data[0].months).reverse()
  const categories = data &&  Object.keys(data[0].months).reverse()
  const options = {
    colors: ['#0E4F83'],
    fill: {
      colors: ['#0E4F83'],
      opacity: 1,
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      },
      background: "transparent",
    },
    yaxis: {
      show: false,
      tickAmount: 5,
      min: 0,
      max: Math.max(...seriesData)*1.1
    },
    grid: {
      show: false
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        distributed: true,
        borderRadius: 4,
        dataLabels: {
          position: 'top'
        }
      },
      
    },
    tooltip: {
      enabled: false,
      enabledOnSeries: undefined,
      marker: {
        show: false,
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#193051']
      },
      offsetY: -20
    },
    legend: {
      show: false,
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      categories: categories,
      labels: {
        show: true,
        style: {
          colors: '#808888',
          fontSize: '10px',
          fontFamily: 'Montserrat',
          fontWeight: '400'
        }
        
      },
      axisBorder: {
        show: false,
      },
    }
  };
  
  const series = [{
    data:  seriesData?.map((item:any) => {
      return item === 0 ? null : item
    })
  }];
  
  return (
    //@ts-ignores
      (Math.max(...seriesData) > 0) ? <ReactApexChart
      type="bar"
      //@ts-ignores
      options={options}
      series={series }
      height={'100%'}
      width={'100%'}/> : <NoDataCard type={'column'} padding={'40px 0 0 0'} ></NoDataCard>
  );
}
