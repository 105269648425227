import React, {useEffect, useRef, useState} from 'react';
import {BlockListProps} from './BlockList.props';
import './BlockList.styles.css';
import {PageHeader} from "../../components/organisms/PageHeader";

import {
    BLOCK_LIST, BLOCK_LIST_ATTRIBUTE_TYPE,
    BLOCK_LIST_SYNC_STATUS,
    DELETE, EDIT, STATUS_CODE,
    BLOCK_LIST_API_ACTIONS, SETTING_BLOCK_LIST,
} from "../../constants/contants";
import {DataTable} from "../../components/molecules/DataTable";
import {GridColDef} from "@mui/x-data-grid";
import {Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Toast} from "../../components/atoms/Toast";
import ChangeWithZone from "../../hoc/ChangeWithZone";
import {Images} from "../../configs/images";
import {SearchFilter} from "../../components/molecules/SearchFilter";
import {DropDownFilter} from "../../components/molecules/DropDownFilter";
import {Pagination} from "../../components/atoms/Pagination";
import {SyncedStatus} from "../../components/atoms/SyncedStatus";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import {ActionsColumn} from "../../components/molecules/StyledMenu";
import {noUserDataRedirect} from "../../utils/common";
import {acquireBlockListEntries, getBLockList, getBlockListsSetting, syncBlockListEntries} from "../../api/emailTriage";
import {Modal} from "../../components/atoms/Modal";
import moment from 'moment-timezone';
import {Ms365BlockListAuth} from "../../components/services/Ms365BlockListAuth";
import {LoadingPage} from "../../components/organisms/LoadingPage";
import {useRoleContext} from "../../context/RoleContext";
import {EditBlockListModal} from "../../components/organisms/EditBLockListModal";
import jwt_decode from "jwt-decode";

const BlockListFull: React.FC<BlockListProps> = () => {
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [startPoint, setStartPoint] = useState(0);
    const [endPoint, setEndPoint] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0)
    const [isOpenAttribute, setIsOpenAttribute] = useState<any>({attr_type: null, status: null})
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openActions = Boolean(anchorEl);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [sortBy, setSortBy] = useState<any>('created_at');
    const [sortDirection, setSortDirection] = useState<any>('desc');
    const [blockListData, setBlockListData] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState<any>(null);
    const [contentModal, setContentModal] = useState<any>({});
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [openEditConfirm, setOpenEditConfirm] = useState(false);
    const [openNewBlockList, setOpenNewBlockList] = useState(false);
    const [selectedItemsDropDown, setSelectedItemsDropDown] = useState<any>({});
    const initialFilter = {filterData: { search_text: '', attr_type: [], status: []}}
    const [blockListFilter, setBlockListFilter] = useState<any>(initialFilter)
    const [filterSearchKeyword, setFilterSearchKeyword] = useState('');
    const [toastId, setToastID] = useState(1);
    const [timer, setTimer] = useState<any>(null);
    const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0});
    const [loadingPage, setLoadingPage] = useState(false)
    const url = window.location.pathname

    let tz_name = 'Etc/UTC';
    let tz_offset = 0;
    const localStorageUserData = localStorage.getItem('userData');
    const userData = localStorage.getItem('userData');
    const parseUserData = userData ? JSON.parse(userData) : {}

    const blockListAuthRef = useRef<any>();
    const [onBlockListAccessGained, setOnBlockListAccessGained] = useState<any>(null)
    const [blocklistTenantId, setBlocklistTenantId] = useState<any>(null)
    const { setting } = useRoleContext()
    const [editBLockListPayload, setEditBLockListPayload] = useState<any>({})
    const blockListEditRef = useRef<any>();
    const blockListTokenKey = 'ms365_bl_tk_' + sessionStorage.getItem('currentZone');

    if(localStorageUserData) {
        const parseUserData = JSON.parse(localStorageUserData)
        tz_name = parseUserData["tz_name"]
        tz_offset = moment.tz(tz_name).utcOffset()
    }

    useEffect(() => {
        setIsOpenAttribute({attr_type: null, status: null})
    }, [url])

    const generateConfirmContentModal = (element: any, title: string, textAgree: string, variant: string, params?: any) => {
        const content:any = {
            title: title,
            content: element,
            action: params,
            textAgree: textAgree,
            variant: variant
        }
        setContentModal(content)
    }

    const confirmDeleteContent = (params:any) => {
        const html = <><div>Are you sure you want to delete this block list entry <strong className={"value-block-list"}>“{params.value}”</strong> ?</div></>

        const title = 'Delete Block List Entry'
        const textAgree = 'Delete'
        const variant = 'delete'
        generateConfirmContentModal(html, title, textAgree, variant, params)
        setOpenDeleteConfirm(true)
    }

    const attributeTypeData:any = [
        {id: 1, content: 'Sender'},
        {id: 2, content: 'URL'},
        {id: 3, content: 'File Hash'}
    ]

    const syncedStatusData:any = [
        {id: 1, content: 'Pending'},
        {id: 2, content: 'Active'},
        {id: 3, content: 'Failed'},
        {id: 4, content: 'Expired'}
    ]

    const dropDownFilterArr:any = [
        {
            label: 'Attribute Type',
            type: 'attr_type',
            listArr: attributeTypeData
        },
        {
            label: 'Synced Status',
            type: 'status',
            listArr: syncedStatusData
        }
    ]

    const handleDropDownSelected = (item: any, type: any) => {
        setSelectedItemsDropDown((prev: any) => {
            return ({
                ...prev,
                [type]: item,
            });
        });
    };

    const handleClearDropDownSelected = (type: any) => {
        setSelectedItemsDropDown((prev:any) => {
            return ({
                ...prev,
                [type]: [],
            })
        });
    };

    const onSort = (model: any) => {
        model.length > 0 ? setSortDirection(model[0].sort) : setSortDirection('desc')
        model.length > 0 ? setSortBy(model[0].field) : setSortBy('created_at')
    };

    const getBlockListRecords = (params: any) => {
        if(!blocklistTenantId) {
            return
        }
        setIsTableLoading(true);

        params["page"] = page;
        params["sort_dir"] = sortDirection;
        params["per_page"] = perPage;
        params["sort_by"] = sortBy;
        params["tenant_id"] = blocklistTenantId || "0";

        getBLockList(params).then(res =>{
            if(res && res.status === STATUS_CODE.OK){
                setBlockListData(res.data.list);
                setTotalRecords(res.data.totalItems);
                setStartPoint(res.data.startItemsPerPage);
                setEndPoint(res.data.endItemsPerPage)
                setMaxPage(res.data.maxPage);
                hideActionsDropdown();
                setIsTableLoading(false);
            }
        }).catch((e) => {
            console.log(e)
        })
    };

    const loadDataTable = () => {
        const userData = localStorage.getItem('userData');
        if (userData === null) {
            noUserDataRedirect()
        } else {
            getBlockListRecords(blockListFilter);
        }
    }

    useEffect(() => {
        handleSetFilterDropDown()
    }, [selectedItemsDropDown])

    useEffect(() => {
        loadDataTable()
    }, [blockListFilter, page, perPage, sortDirection, blocklistTenantId])

    useEffect(() => {
        if (!setting[SETTING_BLOCK_LIST]) {
            return
        }
        getBlockListsSetting({
            service: 'BLOCK_LIST',
            user: 'pre',
        }).then((response)=>{
            setBlocklistTenantId(response.data.tenant)
            if(!response.data.tenant) {
                setIsTableLoading(false)
                return
            }
            if(blockListAuthRef && blockListAuthRef.current) {
                blockListAuthRef.current.requestAccessToken().then((accessToken: string) => {
                    console.log('Request access token successfully')
                    handleSyncBlockListEntries()
                }).catch((error: any) => {
                    console.log('Cannot request access token. No user logged in')
                });
            }
        }).catch((error) => {
        })
    }, [])

    const handleSyncBlockListEntries = () => {
        const token:any = sessionStorage.getItem(blockListTokenKey)
        const accessTokenDecoded:any = jwt_decode(token)
        const lastSync = sessionStorage.getItem('lastSync')
        const currentTime = new Date().getTime();
        if(lastSync && (currentTime - parseInt(lastSync)) < 60000){return}
        sessionStorage.setItem('lastSync', currentTime.toString())
        syncBlockListEntries({
            service: 'SYNC_BLOCK_LIST_ENTRIES',
            accessToken: sessionStorage.getItem(blockListTokenKey),
            upn: accessTokenDecoded?.upn,
            tzOffset: tz_offset
        }).then((response)=>{
            console.log('Done')
        }).catch((error) => {
            console.log('Sync failed')
        })
    }

    const handlePerRowsChange = (e: any) => {
        const value = parseInt(e.target.value)
        setPage(1)
        setPerPage(value)
    }

    const handlePageChange = (pageChanged: number) => {
        setPage(pageChanged)
    }

    const paginationComponent = () => {
        return <Pagination
            itemsPerPage = {perPage}
            currentPage = {page}
            startPoint = {startPoint}
            endPoint = {endPoint}
            maxPage = {maxPage}
            totalItems = {totalRecords}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
        />
    }

    const handleCloseDelete = () => {
        setOpenDeleteConfirm(false)
    };

    const handleCloseEdit = () => {
        setOpenEditConfirm(false)
    };

    const handleDeleteBlockList = (id: any) => {
        setOnBlockListAccessGained(() => {
            return (accountInfo:any) => {
                const deleteParams = blockListData.filter((item: any) => item.id === id)
                confirmDeleteContent(deleteParams[0])
                setOpenDeleteConfirm(true)
            }
        })
        if(blockListAuthRef && blockListAuthRef.current) {
            blockListAuthRef.current.requestAccessToken().then((accessToken: string) => {
                const deleteParams = blockListData.filter((item: any) => item.id === id)
                confirmDeleteContent(deleteParams[0])
                setOpenDeleteConfirm(true)
            }).catch((error: any) => {
                blockListAuthRef.current.openConfirmModal()
            });
        }
    };

    const handleEditBlockList = (id: any) => {
        setOnBlockListAccessGained(() => {
            return (accountInfo:any) => {
                setBlocklistTenantId(accountInfo?.account?.tenantId)
                const editParams = blockListData.filter((item: any) => item.id === id)
                setEditBLockListPayload(editParams[0])
                setOpenEditConfirm(true)
            }
        })
        if(blockListAuthRef && blockListAuthRef.current) {
            blockListAuthRef.current.requestAccessToken().then((accessToken: string) => {
                const editParams = blockListData.filter((item: any) => item.id === id)
                setEditBLockListPayload(editParams[0])
                setOpenEditConfirm(true)
            }).catch((error: any) => {
                blockListAuthRef.current.openConfirmModal()
            });
        }
    };

    const handleConfirmDeleteBlockList = (params:any) => {
        setToastID(toastId + 1)
        setIsTableLoading(true)
        const token:any = sessionStorage.getItem(blockListTokenKey)
        const accessTokenDecoded:any = jwt_decode(token)
        acquireBlockListEntries({
            entries: [params],
            action: BLOCK_LIST_API_ACTIONS.REMOVE_BLOCK_LIST_ENTRY,
            accessToken: sessionStorage.getItem(blockListTokenKey),
            upn: accessTokenDecoded?.upn,
            tzOffset: tz_offset
        }).then(res =>{
            if(res && res.status === STATUS_CODE.OK){
                loadDataTable()
                setToastValue({type: 'success', message: `Delete block list entry successfully`, isOpen: true, toastId: toastId});
            }else {
                setToastValue({type: 'error', message: 'Cannot delete block list entry', isOpen: true, toastId: toastId});
            }
            setPage(1)
            setIsTableLoading(false)
        }).catch((e) => {
            setPage(1)
            setIsTableLoading(false)
        })
        setAnchorEl(null);
    };

    const handleConfirmEditBlockList = (params:any) => {
        setToastID(toastId + 1)
        setIsTableLoading(true)
        const token:any = sessionStorage.getItem(blockListTokenKey)
        const accessTokenDecoded:any = jwt_decode(token)
        acquireBlockListEntries({
            entries: [params],
            action: BLOCK_LIST_API_ACTIONS.UPDATE_BLOCK_LIST_ENTRY,
            accessToken: sessionStorage.getItem(blockListTokenKey),
            upn: accessTokenDecoded?.upn,
            tzOffset: tz_offset
        }).then(res =>{
            if(res && res.status === STATUS_CODE.OK){
                loadDataTable()
                const currentTime = new Date().getTime();
                sessionStorage.setItem('lastSync', currentTime.toString())
                setToastValue({type: 'success', message: `Edit entry successfully!`, isOpen: true, toastId: toastId});
            }else {
                setToastValue({type: 'error', message: 'Edit entry unsuccessfully!', isOpen: true, toastId: toastId});
            }
            setPage(1)
            setIsTableLoading(false)
        }).catch((e) => {
            setPage(1)
            setIsTableLoading(false)
        })
        setAnchorEl(null);
    };

    const showActionsDropdown = (event: React.MouseEvent<HTMLElement>) => {
        const blockListId:any = event.currentTarget.getAttribute('data-rowid')
        setSelectedRowId(blockListId)
        setAnchorEl(event.currentTarget)
        const blockListItem:any = blockListData.filter((item: any) => item.id === blockListId)

        if (blockListItem[0].status === 'active' || blockListItem[0].status === 'expired') {
            setActionListData({
                "Edit": EDIT,
                "Delete": DELETE
            })
        } else {
            setActionListData({
                "Delete": DELETE
            })
        }
    };

    const handleBlockListAction  = (type:string, id: string) => {
        switch (type) {
            case 'Delete':
                handleDeleteBlockList(id)
                break
            case 'Edit':
                handleEditBlockList(id)
                break
        }
    }

    const hideActionsDropdown = () => {
        setAnchorEl(null);
    };

    const [actionListData, setActionListData] = useState<any>({
        "Delete": DELETE,
    })

    const createDateFromTimeStamp = (timeStamp: number) => {
        return new Date(timeStamp*1000)
    }


    const aoColumns = <ActionsColumn
        menuData={blockListData}
        actionListData={actionListData}
        rowSelected={[selectedRowId]}
        open={openActions}
        anchorEl={anchorEl}
        showActionsDropdown={showActionsDropdown}
        hideActionsDropdown={hideActionsDropdown}
        handleActionList={handleBlockListAction}
    />

    const columns: GridColDef[] = [
        {field: 'value', headerName: 'Value', minWidth: 600, sortable: true,
            renderCell: (row) => {
                return <Tooltip placement={"top"}
                                title={row.formattedValue}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            maxWidth: "450px",
                                        },
                                    },
                                }}
                >
                    <span className={'MuiDataGrid-cellContent RowStatus-' + row.row?.status }>{row.formattedValue}</span>
                </Tooltip>
            }
        },
        {field: 'attr_type', headerName: 'Attribute Type', minWidth: 200, sortable: true,
            renderCell: (row:any) => {
                switch (row.value) {
                    case BLOCK_LIST_ATTRIBUTE_TYPE.URL:
                        return <span className={'RowStatus-' + row.row?.status }>URL</span>
                    case BLOCK_LIST_ATTRIBUTE_TYPE.SENDER:
                        return <span className={'RowStatus-' + row.row?.status }>Sender</span>
                    case BLOCK_LIST_ATTRIBUTE_TYPE.FILE_HASH:
                        return <span className={'RowStatus-' + row.row?.status }>File Hash</span>
                }
            }
        },
        {field: 'created_at', headerName: 'Created Date', width: 230, sortable: true,
            renderCell: (row:any) => {
                const createdDate = createDateFromTimeStamp(parseInt(row.formattedValue))
                return (<div className={'RowStatus-' + row.row?.status }>
                    <span>{moment.utc(createdDate, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('DD/MM/YYYY')} </span>
                    <span>{moment.utc(createdDate, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('hh:mm A')}</span>
                </div>)
            }
        },
        {field: 'expire_date', headerName: 'Expiration Date', width: 230, sortable: false,
            renderCell: (row:any) => {
                const expiredDate = createDateFromTimeStamp(parseInt(row.formattedValue))
                if (parseInt(row.formattedValue) === 0) {
                    return (
                        <div className={'RowStatus-' + row.row?.status }>Never expire</div>
                    )
                }
                return (<div className={'RowStatus-' + row.row?.status }>
                    <span>{moment.utc(expiredDate, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('DD/MM/YYYY')} </span>
                    <span>{moment.utc(expiredDate, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('hh:mm A')}</span>
                </div>)
            }
        },
        {field: 'status', headerName: 'Synced Status', width: 200, sortable: false,
            renderCell: (row:any) => {
                switch (row.value) {
                    case BLOCK_LIST_SYNC_STATUS.PENDING:
                        return <div className={'RowStatus2-' + row.row?.status }><SyncedStatus value={'Pending'} icon={<PendingActionsIcon htmlColor={'#467599'}/>} valueColor={'#467599'} /></div>
                    case BLOCK_LIST_SYNC_STATUS.ACTIVE:
                        return <div className={'RowStatus2-' + row.row?.status }><SyncedStatus value={'Active'} icon={<CheckCircleIcon htmlColor={'#008545'}/>} valueColor={'#008545'} /></div>
                    case BLOCK_LIST_SYNC_STATUS.FAILED:
                        return <div className={'RowStatus2-' + row.row?.status }><SyncedStatus value={'Failed'} icon={<CancelIcon htmlColor={'#C80000'}/>} valueColor={'#C80000'} /></div>
                    case BLOCK_LIST_SYNC_STATUS.EXPIRED:
                        return <div className={'RowStatus2-' + row.row?.status }><SyncedStatus value={'Expired'} icon={<TimerOffOutlinedIcon htmlColor={'#434F4F'}/>} valueColor={'#434F4F'} /></div>
                }
            }
        },
    ];

    const handleMakeKeyDropDownFilter = (value: string) => {
        const valueArr = value.split(' ')
        if (valueArr.length > 1) {
            const valueArr2 = valueArr.map((item: string) => {
                return item.toLowerCase().trim()
            })
            return valueArr2.join('_')
        }
        return value.toLowerCase().trim()
    }

    const handleSetFilter = (event: any = null) => {
        setPage(1)
        setBlockListFilter((prev:any) => {
            // the event is null when acting in dropdown filter
            // The event is not null when the Enter key is pressed in the search input.
            let search_text = prev.filterData.search_text
            let attribute_type_arr = prev.filterData.attr_type
            let status_arr = prev.filterData.status

            if (event) {
                search_text = event.target.value.trim()
            }else {
                attribute_type_arr = selectedItemsDropDown.attr_type ? selectedItemsDropDown.attr_type.map((value: string) => handleMakeKeyDropDownFilter(value)) : attribute_type_arr
                status_arr = selectedItemsDropDown.status ? selectedItemsDropDown.status.map((value: string) => handleMakeKeyDropDownFilter(value)) : status_arr
            }

            if (search_text !== '' || attribute_type_arr.length > 0 || status_arr.length > 0) {
                return ({
                    ...prev,
                    filterData: {
                        attr_type: attribute_type_arr,
                        status: status_arr,
                        search_text: search_text,
                    }
                })
            } else {
                return initialFilter
            }
        });
    }

    const handleSetFilterDropDown = () => {
        // Handle when changing selected item in filter dropdowns
        handleSetFilter()
    }

    const handleEnterSearchInput = (event:any) => {
        // Handle when Enter on the search input
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        return new Promise((resolve) => {
            const timers = setTimeout(async () => {
                if (event.keyCode === 13) {
                    handleSetFilter(event)
                }
            }, 300);
            setTimer(timers);
        });
    }

    const handleResetFilter = () => {
        setPage(1)
        setBlockListFilter(initialFilter)
        setFilterSearchKeyword('')
        setSelectedItemsDropDown({})
    }

    const pageHeaderTooltipStyle = {
        width: "auto",
        maxWidth: "700px",
        padding: "8px",
        position: "relative",
        top: "10px",
        left: "245px"
    }

  return(
    <Box className={'block-list-page page-content-wrapper'}>
        <PageHeader pageTitle={BLOCK_LIST}
                    infoPage={'This feature helps your mail server prevent malicious or spam emails from reaching your users’ inbox. All entries here will be synced to the unique list of blocklist entries of your connected Microsoft 365 organisation.'}
                    tooltipStyle={pageHeaderTooltipStyle}
        />
        <Box className={'block-list-content'}>
            <Box className={'block-list-search-bar'}>
                <SearchFilter
                    height={38}
                    margin={'0'}
                    widthTextInput={180}
                    titleInput={'Search'}
                    iconSrc={Images.SearchIcon}
                    borderRadius={'4px'}
                    handleKeyDownEvent={handleEnterSearchInput}
                    inputValue={filterSearchKeyword}
                    setInputValue={setFilterSearchKeyword}
                />
                <Box className={'block-list-search-dropdown'}>
                    {
                        dropDownFilterArr ? dropDownFilterArr.map((item: any, index:number) =>
                            <DropDownFilter isOpen={isOpenAttribute}
                                            setIsOpen={setIsOpenAttribute}
                                            clearFilerSelection={handleClearDropDownSelected}
                                            onSelectItem={handleDropDownSelected}
                                            selectedItems={selectedItemsDropDown}
                                            label={item.label}
                                            type={item.type}
                                            arrList={item.listArr}
                                            width={143}
                                            key={index}
                            />
                        ) : null
                    }
                    <Button
                        data-testid={"resetSearchFilterBtn"} className={"block-list-reset-search-btn"} onClick={() => handleResetFilter()} variant="text" size={"large"}
                        sx={{
                            padding: "8px",
                            width: 'fit-content',
                            height: '40px',
                            lineHeight: '16px',
                            fontFamily: 'Metropolis',
                            fontSize: '16px',
                            fontWeight: 600,
                            backgroundColor: 'transparent',
                            color: '#345C8B'
                        }}
                    >Reset</Button>
                </Box>
            </Box>

            <Box className={'block-list-table'}>
                <DataTable columns={columns}
                           data={blockListData}
                           pagination={true}
                           toolbar={null}
                           CustomPagination = {paginationComponent}
                           loadingTable={isTableLoading}
                           actionsColumn={aoColumns}
                           onSortModelChange={onSort}
                           initialSortCol={'created_at'}
                />
            </Box>
        </Box>
        <Modal idModal='modal-delete-block-list'
               openDialog={openDeleteConfirm}
               variant={contentModal.variant}
               title={contentModal.title}
               content={contentModal.content}
               actionAgree={() => {
                   handleConfirmDeleteBlockList(contentModal.action)
                   handleCloseDelete()
               }}
               actionCancel={handleCloseDelete}
               textAgree={contentModal.textAgree}
               onClose={handleCloseDelete}
               children={contentModal.children}
        />
        <Modal idModal='modal-edit-block-list'
               openDialog={openEditConfirm}
               variant={'edit_block_list'}
               title={'Edit Entry'}
               content={<EditBlockListModal payload={editBLockListPayload} ref={blockListEditRef}/>}
               actionAgree={() => {
                   const entry = blockListEditRef.current.getValue()
                   handleConfirmEditBlockList(entry)
                   handleCloseEdit()
               }}
               actionCancel={handleCloseEdit}
               textAgree={'Save'}
               onClose={handleCloseEdit}
        />
        {/*<Modal idModal='modal-new-block-list'*/}
        {/*       openDialog={openNewBlockList}*/}
        {/*       variant={contentModal.variant}*/}
        {/*       title={contentModal.title}*/}
        {/*       content={contentModal.content}*/}
        {/*       actionAgree={() => {*/}
        {/*           handleConfirmDeleteBlockList(contentModal.action)*/}
        {/*           handleCloseDelete()*/}
        {/*       }}*/}
        {/*       actionCancel={() => {setOpenNewBlockList(false)}}*/}
        {/*       textAgree={contentModal.textAgree}*/}
        {/*       onClose={() => {setOpenNewBlockList(false)}}*/}
        {/*       children={contentModal.children}*/}
        {/*/>*/}
        <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message} toastId={toastValue.toastId} autoHideDuration={2000}/>
        <Ms365BlockListAuth
            ref={blockListAuthRef}
            accessGained={(accountInfo:any)=> {setBlocklistTenantId(accountInfo.account.tenantId)}}
            tenant={blocklistTenantId}
            onBeforeGetUser={setLoadingPage}
            onAfterGetUser={setLoadingPage}
            userData={parseUserData}
            accessGainedSuccess={onBlockListAccessGained}
        />
        <LoadingPage open={loadingPage}/>
    </Box>
  );
};

export const BlockList = ChangeWithZone(BlockListFull);
