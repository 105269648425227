import React from 'react'
import {BottomDashboardProps} from './BottomDashboard.props'
import './BottomDashboard.styles.css';

import Grid from '@mui/material/Grid';

import {DashLineChartCard} from "../../molecules/DashLineChartCard";
import {BottomCategoryCard} from "../../molecules/BottomCategoryCard";
import {BottomReportedMessagesCard} from "../../molecules/BottomReportedMessagesCard";

export const BottomDashboard: React.FC<BottomDashboardProps> = ({
  isLoading,
  bottomCard,
  bottomCardByMonths
                                                                }
) => {
  const bottomReportedMessagesCardData = bottomCardByMonths && bottomCardByMonths.Category
  const dashLineChartCardData = bottomCardByMonths && bottomCardByMonths.Priority
  return (
    <Grid
      container
      spacing={2.5}
    >
      <Grid
        item
        xs={4.2}
      >
        <BottomReportedMessagesCard
          isLoading={isLoading}
          data={bottomReportedMessagesCardData}
        />
      </Grid>
      <Grid
        item
        xs={3.6}
      >
        <BottomCategoryCard
          isLoading={isLoading}
          bottomCard={bottomCard}
        />
      </Grid>
      <Grid
        item
        xs={4.2}
      >
        <DashLineChartCard
          isLoading={isLoading}
          data={dashLineChartCardData}
        />
      </Grid>
    </Grid>
  )
  
}
